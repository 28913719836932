

export class Test {
    id : string;
    _id:string;
    nom: string;
    titre :string;
    dateFin :Date;
    temps :Number;
    questions :[{question:string,choix: [{numero:Number,text:String, selected : Boolean}],}]
   
    verif :Boolean; 
    
  dateDebut: Date;
  
  publie:Boolean;
  score :String ;
  dateEndCertficat :Date;
  training :string;
}
