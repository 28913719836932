<br />
<br />

<div *ngIf="!pass" style="float: left; padding-left: 3.3%">
  <button
    routerLink="/installateur/formations/details/{{ idBackInListVideos }}"
    class="btn btn-primary"
  >
    <i class="bi bi-arrow-bar-left"></i>
    {{ "questionaire_return_to_training" | translate }}
  </button>
</div>

<div *ngIf="score < scoreTest" style="float: left; padding-left: 3.3%">
  <button
    routerLink="/installateur/formations/details/{{ idBackInListVideos }}"
    class="btn btn-primary"
  >
    <i class="bi bi-arrow-bar-left"></i>
    {{ "questionaire_return_to_training" | translate }}
  </button>
</div>
<br>

<div *ngIf="!result" class="row">
  <div class="container test-left-menu bg-white py-5 col-3 mr-1">
    <h3 style="color: #e73458">
      {{ "questionaire_pass_test_card1_subtitle1" | translate }}
    </h3>
    <p class="left-menu-element">{{ titleTraining }}</p>
    <h3 style="color: #e73458" class="test-left-menu">
      {{ "questionaire_quiz" | translate }}
    </h3>
    <p *ngIf="sizeDescription <= 240" class="left-menu-element">
      {{ description }}
    </p>
    <p
      *ngIf="sizeDescription > 240"
      [ngClass]="{ limitTextHeight: isReadMore }"
      class="left-menu-element"
    >
      {{ description }}
    </p>
    <a
      *ngIf="sizeDescription > 240"
      type="button"
      (click)="showText()"
      style="text-decoration: underline; font-weight: bold"
    >
    {{ (isReadMore ? "questionaire_description_voir_plus" : "questionaire_description_voir_moins" )| translate }}
    </a>
    <h3 *ngIf="!pass" style="color: #e73458" class="test-left-menu">
      {{ "questionaire_pass_test_card1_subtitle3" | translate }}
    </h3>
    <h3 *ngIf="pass" style="color: #e73458" class="test-left-menu">
      {{ "questionaire_temps_passe" | translate }}
    </h3>
    <div class="timer">
      <h5 *ngIf="!pass" class="left-menu-element">
        ⏱ {{ counter }}:00 /{{ this.temps }}:00
      </h5>
      <h5 *ngIf="pass" class="left-menu-element">
        ⏱ {{ display }} /{{ this.temps }}:00
      </h5>
    </div>
    <div *ngIf="pass">
      <ejs-progressbar
        type="Linear"
        height="20"
        width="100%"
        trackThickness="7"
        progressThickness="7"
        id="percentage"
        value="100"
        progressColor="#3C4D5D"
        [animation]="animation"
      >
      </ejs-progressbar>
    </div>
    <div *ngIf="!pass">
      <ejs-progressbar
        type="Linear"
        height="20"
        width="100%"
        trackThickness="7"
        progressThickness="7"
        id="percentage"
        progressColor="#3C4D5D"
        [animation]="animation"
      >
      </ejs-progressbar>
    </div>

    <h3 style="color: #e73458" class="question-menu">
      {{ "questionaire_one_question" | translate }}
    </h3>
    <h5 class="left-menu-element" *ngIf="!pass">
      <i class="bi bi-card-checklist"></i> 0 / {{ taille }}
    </h5>
    <h5 class="left-menu-element" *ngIf="pass">
      <i class="bi bi-card-checklist"></i> {{ currentQuestion + 1 }} /
      {{ taille }}
    </h5>
    <ejs-progressbar
      #linear
      type="Linear"
      height="30"
      width="100%"
      trackThickness="7"
      progressThickness="7"
      id="percentage0"
      progressColor="#3C4D5D"
      [animation]="animation"
      [value]="progress"
    ></ejs-progressbar>
  </div>

  <div *ngIf="!pass" class="container bg-light py-5 col-8 ml-0">
    <h2 class="text-center">
      {{ "test_passer_title" | translate }} {{ titleTraining }}
    </h2>
    <br />

    <div class="card text-center">
      <h4 style="margin-top: 1%">{{ "test_passer_subtitle" | translate }}</h4>
      <p class="p">{{ "test_passer_parag_1" | translate }}</p>
      <p class="p">{{ "test_passer_parag_2" | translate }}</p>
      <p class="p">
        {{ "test_passer_parag_3" | translate }}
      </p>
      <!-- <p class="p">
        {{ "test_passer_parag_4" | translate }}
        <b style="color: red">{{ dateFin | date : "dd-MM-yyyy" }} 23:59</b>
      </p> -->
    </div>
    <br />
    <div class="text-sm-end">
      <button (click)="open2(content2)" class="btn btn-primary"  style="position: absolute; right: 14px; bottom: 14px">
        {{ "btn_hover_7" | translate }}
        <ng-template #content2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              {{ "questionaire_start_test" | translate }}
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label
                >{{ "questionaire_pass_test_popup_content" | translate }}
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="passTest()"
            >
              {{ "btn_hover_7" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-dark1"
              (click)="modal.close('Save click')"
            >
              {{ "btn_2" | translate }}
            </button>
          </div>
        </ng-template>
      </button>
    </div>
  </div>

  <div *ngIf="pass" class="container bg-light py-5 col-8 ml-0">
    <div class="container mt-5">
      <ng-container>
        <div class="question">
          <div>
            <h3 class="questionTitle">
              {{ currentQuestion + 1 }} .
              {{ questions[currentQuestion]?.question }}
            </h3>
          </div>
        </div>
        <br />
        <div style="text-align: center !important;">
        <img *ngIf="questions[currentQuestion]?.originalname" [src]= "questions[currentQuestion]?.filename"  width="315" height="200">
        </div>
        <br />
        <br />
        <div class="options">
          <ol *ngFor="let option of questions[currentQuestion]?.choix">
            <li>
              <input
                id="cb1"
                class="form-check-input"
                type="checkbox"
                (change)="option.selected = !option.selected"
                [checked]="option.selected"
              />
              <label class="form-check-label choice"> {{ option.text }} </label>
            </li>
          </ol>
        </div>

        <div>
          <button
            *ngIf="currentQuestion > 0"
            class="btn btn-primary"
            (click)="previousQuestion()"
            aria-hidden="true"
            style="position: absolute; left: 14px; bottom: 14px"
          >
            {{ "shared_5_pagination" | translate }}
          </button>
          <button
            *ngIf="currentQuestion === 0 || currentQuestion < taille - 1"
            class="btn btn-primary"
            style="position: absolute; right: 13px; bottom: 12px"
            (click)="nextQuestion()"
          >
            {{ "shared_6_pagination" | translate }}
          </button>
          <button
            *ngIf="currentQuestion === taille - 1"
            class="btn btn-primary"
            style="position: absolute; right: 13px; bottom: 12px"
            (click)="open(content)"
          >
            {{ "btn_8" | translate }}

            <ng-template #content let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                  {{ "test_finish" | translate }}
                </h4>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>{{ "test_finish_confirm" | translate }} </label>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-dark"
                  (click)="submitTest()"
                >
                  {{ "btn_9" | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-dark1"
                  (click)="modal.close('Save click')"
                >
                  {{ "btn_2" | translate }}
                </button>
              </div>
            </ng-template>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="result" class="row">
  <div class="container test-left-menu bg-white py-5 col-3 mr-1">
    <h3 style="color: #e73458">
      {{ "questionaire_pass_test_card1_subtitle1" | translate }}
    </h3>
    <p class="left-menu-element">{{ titleTraining }}</p>
    <h3 style="color: #e73458" class="test-left-menu">
      {{ "questionaire_temps_passe" | translate }}
    </h3>
    <div class="timer">
      <h5 class="left-menu-element">⏱ {{ breakTime }} /{{ temps }}:00</h5>
    </div>

    <h3 style="color: #e73458" class="question-menu">
      {{ "questionaire_one_question" | translate }}
    </h3>
    <h5 class="left-menu-element" *ngIf="!pass">
      <i class="bi bi-card-checklist"></i> 0 / {{ taille }}
    </h5>
    <h5 class="left-menu-element" *ngIf="pass">
      <i class="bi bi-card-checklist"></i> {{ currentQuestion + 1 }} /
      {{ taille }}
    </h5>
  </div>

  <div class="container bg-light py-5 col-8 ml-0">
    <h2 *ngIf="showAnswer == false" class="text-center">{{ "result_test" | translate }}</h2>
    <h2 *ngIf="showAnswer == true" class="text-center">{{ "title_display_erors" | translate }}</h2>
    <br />

    <div *ngIf="score >= scoreTest" class="card text-center">
      <h4 class="p">
        <b>{{ "test_success_message_1" | translate }}!🎉</b>
      </h4>
      <p class="p">{{ "test_success_message_2" | translate }}</p>
      <p class="p">{{ "test_success_message_3" | translate }} {{ score }}%.</p>
      <p class="p">
        {{ "test_success_message_4" | translate }}
        <a target="_blank" [routerLink]="['/certificat', test._id]">{{
          "test_success_message_5" | translate
        }}</a>
      </p>
    </div>
    <div *ngIf="score < scoreTest">
      <div *ngIf="showAnswer == false"  class="card text-center">
      <h4 class="p"><b>Oops! 🤔</b></h4>
      <p class="p">{{ "test_fail_message_1" | translate }}</p>
      <p class="p">{{ "test_success_message_3" | translate }} {{ score }}%.</p>
      <p class="p">
        {{ "test_fail_message_3" | translate }}
      </p>
     
      <!-- <p class="p">
        {{ "test_fail_message_5" | translate }}
        <b style="color: red">{{ dateFin | date : "dd-MM-yyyy" }} 23:59</b>
      </p> -->
      <p class="p"> {{ "display_errors_test_1" | translate }} <a style="color: #e73458; text-decoration: underline;cursor: pointer;" (click)="shwoReponses()" >
        {{ "display_errors_test_2" | translate }}.</a></p>
      </div>
      <div *ngIf="showAnswer == true"  class="card text-left">
        <div *ngIf="finalFalseAnswers.length > 0" class="option" style="float: left !important;" >
          <div *ngFor="let question of finalFalseAnswers | paginate
          : {
              itemsPerPage: tableSize,
              currentPage: page,
              totalItems: count
            };
      let i = index" >
            <div>
              <p class="form-check-label choice"> {{ question.question }} </p>
              <br>
              
    
              <div *ngFor="let answer of question.answer ;let index = index;"  >
               
              
                  <!-- <input *ngIf=" answer.length > 0" id="cb1" class="form-check-input" type="checkbox" checked  /> -->
                  <p *ngIf=" answer.length > 0" class="form-check-label choice" style="color:#e73458 ;text-decoration: line-through; padding-left: 10%;">   
                    <i  *ngIf=" answer.length > 0"style="padding-right: 3%;" class="form-check-input" class="bi bi-check-square-fill"></i>{{ answer }} </p>
                  <p *ngIf=" answer.length == 0" class="form-check-label choice" style="color:#e73458 ;padding-left: 10%;"> {{ "no_answer_insered" | translate }} </p>
                  
              </div>
            
            </div>
           <hr *ngIf="i+1 != finalFalseAnswers.length" style="width: 100%;">
          </div>
          <br>
        <div class="d-flex justify-content-end" >
          <pagination-controls style="float: right"
            class="my-pagination"
            previousLabel="{{ 'shared_5_pagination' | translate }}"
            nextLabel="{{ 'shared_6_pagination' | translate }}"
            (pageChange)="onTableDataChange($event)"
          >
          </pagination-controls>
        </div>
        </div>
        <div *ngIf="finalFalseAnswers.length == 0" class="text-center">
          <h4 style="color:#e73458;padding-bottom: 10%;padding-top: 8%;">{{ "message_missed_correctAnswer" | translate }}</h4>
        </div>  
        
      </div>
      
    <br />
  </div>
  </div>
  </div>

