import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.css']
})
export class MyModalComponent implements OnInit {

  title: string;
  content: [];
  sizeContent

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
  this.sizeContent=this.content.length
  }

}
