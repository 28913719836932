<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "dashboard_title" | translate }}</h1>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <!-- Card stats -->
    <div class="row g-6 mb-6">
      <div class="custom-width col-xl-3 col-sm-6 col-12">
        <div class="card shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span
                  class="h6 font-semibold text-muted text-sm d-block mb-2"
                  >{{ "dashboard_card_1" | translate }}</span
                >
                <span class="h3 font-bold mb-0">{{
                  numberTotalTrainings
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-tertiary text-white text-lg rounded-circle"
                >
                  <i class="bi bi-book"></i>
                </div>
              </div>
            </div>
            <div class="mt-2 mb-0 text-sm">
              <span class="text-nowrap text-xs text-muted">{{
                "dashboard_subcard_1" | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-width col-xl-3 col-sm-6 col-12">
        <div class="card shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span
                  class="h6 font-semibold text-muted text-sm d-block mb-2"
                  >{{ "dashboard_card_2" | translate }}</span
                >
                <span class="h3 font-bold mb-0">{{
                  numberTotalTrainingsArchives
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-archive text-white text-lg rounded-circle"
                >
                  <i class="bi bi-archive"></i>
                </div>
              </div>
            </div>
            <div class="mt-2 mb-0 text-sm">
              <span class="text-nowrap text-xs text-muted">{{
                "dashboard_subcard_2" | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-width col-xl-3 col-sm-6 col-12">
        <div class="card shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span
                  class="h6 font-semibold text-muted text-sm d-block mb-2"
                  >{{ "dashboard_card_3" | translate }}</span
                >
                <span class="h3 font-bold mb-0">{{
                  numberTotalInstallers
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-primary text-white text-lg rounded-circle"
                >
                  <i class="bi bi-people"></i>
                </div>
              </div>
            </div>
            <div class="mt-2 mb-0 text-sm">
              <span class="text-nowrap text-xs text-muted">{{
                "dashboard_subcard_3" | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-width col-xl-3 col-sm-6 col-12">
        <div class="card shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span
                  class="h6 font-semibold text-muted text-sm d-block mb-2"
                  >{{ "dashboard_card_4" | translate }}</span
                >
                <span class="h3 font-bold mb-0">{{
                  numberTotalDistributers
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-info text-white text-lg rounded-circle"
                >
                  <i class="bi bi-people"></i>
                </div>
              </div>
            </div>
            <div class="mt-2 mb-0 text-sm">
              <span class="text-nowrap text-xs text-muted">{{
                "dashboard_subcard_4" | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="padding-top: 2%">
    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="container-fluid">
        <div class="card shadow border-0 mb-7">
          <div class="card-header"  style="background: linear-gradient(to bottom, #3c4d5d, #29435c)">
            <h5 style="color: #fff !important;" class="mb-0">{{ "formation_card_title_1" | translate }}</h5>
          </div>
          <div  [ngClass]="sizeListTrainings > 8 ? 'scroller' : 'scroller2'">
            <table class="table table-hover table-nowrap">
              <!-- <thead
                style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
              >
                <tr>
                  <th style="color: #fff" class="th" scope="col">
                    {{ "table_column_12" | translate }}
                  </th>
                </tr>
              </thead> -->

              <tbody>
                <tr *ngFor="let l of list">
                 
                  <td 
                     
                   
                    style="cursor: pointer"
                    [ngStyle]="{'font-weight':l._id === idTraining ? 'bold' : '100' }"
                    class="th"
                    scope="col"
                    (click)="onChange(l)"
                  >
                    {{ l.title }}
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="chart-container card shadow border-0 mb-7 mr-4">
        <canvas style="height: 500px !important;" id="myChart">{{ chart }}</canvas>
      </div>
    </div>
  </div>
  <div class="wrapper"></div>
</main>


