<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div  [ngClass]="sizeContent > 4 ? 'scroller' : 'scroller2'">
      <table class="table table-hover table-nowrap ">
        
          <thead
            style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
          >
            <tr>
              <th style="color: #fff" class="th" scope="col">
                {{ "table_column_1" | translate }} {{ "table_column_2" | translate }}
              </th>
            </tr>
          </thead>
          
          <tbody>
           
            <tr *ngFor="let c of content">
              
              <td
                
                class="th"
                scope="col"
              >
                {{ c }}
              </td>
              </tr>
            
   
          </tbody>
        
        </table>
      </div>
  </div>
  