export class User {
    id : string;
    _id:string;
    firstName :string; //firstName
    lastName :string;
    email: string;
    role :String;
    password;
    newpassword;
    confirmpassword;
    type :string; // by installateur
    distrubter :User // by installateur
    company 
    companyEmailDistributeur

    

}
