import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InstallerService {
  //private _installerURL="http://localhost:3200/api/fitters" 
  private _installerURL=`${environment.baseUrl}/fitters`
  constructor(private http: HttpClient) { }

     getInstallers() :Observable <any> {
      //return this.http.get(`${this._installerURL}`);
      return this.http.post(`${this._installerURL}/get/all/resellers`,{"bearertoken":localStorage.getItem("token_auth")});
      }
 
      createInstaller(user: any):Observable<Object> {
        return this.http.post(`${this._installerURL}`,user);
      }
      
      deleteInstaller(id : string)
      {
        return this.http.delete(`${this._installerURL}/${id}`);
      } 
 
     getInstallerById(id : string):Observable<User>
      {
        return this.http.post<User>(`${this._installerURL}/${id}`,{"bearertoken":localStorage.getItem("token_auth")});
    
      } 
      
      EditInstaller(id:string,user:User):Observable<Object> {
        return this.http.put(`${this._installerURL}/${id}`,user);
      }
      // by admin
      getInstallersWithTypePremnuim() :Observable <any> {
          return this.http.get(`${this._installerURL}/Premium`);
          }  
   
           // by distrubuter curent
           getInstallersByDistrubutersSecond() :Observable <any> {
            return this.http.post(`${this._installerURL}/get/all/installteurs/byDis`,{"bearertoken":localStorage.getItem("token_auth")});
            }      
   
           // by distrubuter curent
      getInstallersByDistrubuters() :Observable <any> {
        //return this.http.get(`${this._installerURL}/byDistrubter`);
        return this.http.post(`${this._installerURL}/get/all/installteurs/byDis`,{"bearertoken":localStorage.getItem("token_auth")});  
      }
      
         // by distrubuter curent --> list installaters premnuim 
      getInstallersByDistrubutersWithTypePremnuim() :Observable <any> {
        return this.http.get(`${this._installerURL}/byDistrubterWithTypePremnuim`);
        }

         // statistiques pour admin
      numberInstallers():Observable <any> {
        return this.http.post(`${this._installerURL}/admin/numberInstallers`,{"bearertoken":localStorage.getItem("token_auth")});
            }

            // update id_distributor
 editIdDistriubutorForInstaller(installers){
  return this.http.put(`${this._installerURL}/update/iddistributor`, installers);
 }
 
}
