<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "formation_title" | translate }}</h1>
        </div>
        <!-- Actions -->
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ "formation_card_title_1" | translate }}</h5>
      </div>
      <div
        style="
          padding-top: 2%;
          display: flex;
          justify-content: space-around;
          padding-left: 7%;
        "
      >
        <div class="col-xl-12 col-lg-12 col-md-12 pl-0 row">
          <div class="col-12 col-md-6 col-xl-4 col-lg-6 mb-3">
            <label>{{ "formation_filter_title" | translate }} :</label>
            <input
              id="search-input"
              type="search"
              #myInput
              class="form-control"
              [(ngModel)]="title"
              placeholder="Titre"
              (input)="search()"
            />
          </div>
          <div class="col-12 col-md-6 col-xl-4 col-lg-6 mb-3">
            <label
              >{{ "formation_filter_date_expiration" | translate }} :</label
            >

            <input
              id="search-input"
              type="date"
              class="form-control"
              [(ngModel)]="date"
            />
          </div>

          <div class="col-12 col-md-6 col-xl-3 col-lg-6 mb-3">
            <label>{{ "formation_filter_status" | translate }} :</label>

            <select
              style="padding: 0.35rem 3.75rem 0.75rem 0.75rem"
              class="form-select"
              [(ngModel)]="statut"
            >
              <option
                class="placeHolderOption"
                [ngValue]="null"
                [disabled]="true"
              >
                {{ "formation_filter_status" | translate }}
              </option>
              <option value="À commencer">
                {{ "formation_status_content_11" | translate }}
              </option>
              <option value="En cours">
                {{ "formation_status_content_22" | translate }}
              </option>
              <option value="Terminée">
                {{ "formation_status_content_33" | translate }}
              </option>
            </select>
          </div>
          <!--<div class="col-12 col-md-6 col-xl-4 col-lg-6 mb-3">
            <label
              >{{
                "formation_status_content_expired_date_test" | translate
              }}
              :</label
            >

            <input
              id="search-input"
              type="date"
              class="form-control"
              [(ngModel)]="dateExipred"
            />
          </div>-->
          <div class="col-3 col-sm-2 col-md-3 col-xl-1 col-lg-2 mr-4 mb-3">
            <div class="mx-n1">
              <a
                (click)="searchAll()"
                class="btn d-inline-flex btn-sm btn-primary mx-1"
                style="padding-top: 11px"
              >
                <span class="pe-2">
                  <i class="bi bi-search"></i>
                </span>
                <span style="color: #fff">{{ "btn_hover_9" | translate }}</span>
              </a>
            </div>
          </div>
          <div class="col-3 col-sm-2 col-xl-1 col-lg-3 col-md-3">
            <div class="mx-n1">
              <a
                (click)="cancelFilter()"
                class="btn d-inline-flex btn-sm mx-1"
                style="padding-top: 11px; text-decoration: underline"
              >
                <span>{{ "btn_hover_10" | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="lenghtRecentResultsFiltred > 0" class="table-responsive">
        <table *ngIf="trainings" class="table table-hover table-nowrap">
          <thead
            style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
          >
            <tr>
              <th class="th" scope="col">
                {{ "formation_card_title_1" | translate }}
              </th>

              <th class="th" scope="col">
                {{ "formation_filter_date_expiration" | translate }}
              </th>
              <th class="th" scope="col">
                {{ "formation_filter_status" | translate }}
              </th>
              <!--<th class="th" scope="col">
                {{ "formation_status_content_expired_date_test" | translate }}
              </th>-->
              <th class="th" scope="col">{{ "shared_1" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let i of trainings.reverse()
                  | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };
                let k = index
              "
            >
              <td>
                {{ i.training.title }}
              </td>

              <td>
                {{ i.training.dateEnd | date : "dd/MM/yyyy HH:MM" }}
              </td>

              <td
                *ngIf="i.statut == 'À commencer'"
                [ngStyle]="{ color: 'red' }"
              >
                <span class="badge badge-pill badge-dark">{{
                  "formation_status_content_11" | translate
                }}</span>
              </td>

              <td
                *ngIf="i.statut == 'En cours'"
                [ngStyle]="{ color: 'yellow' }"
              >
                <span class="badge badge-pill badge-info">{{
                  "formation_status_content_22" | translate
                }}</span>
              </td>

              <td *ngIf="i.statut == 'Terminée'" [ngStyle]="{ color: 'green' }">
                <span class="badge badge-pill badge-success">{{
                  "formation_status_content_33" | translate
                }}</span>
              </td>

              <!--<td>
                {{ i.dateTestExipration | date : "dd/MM/yyyy HH:MM" }}
              </td>-->

              <td>
                <a
                  (click)="detailsTraining(i._id)"
                  class="btn btn-sm btn-neutral"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_1' | translate }}"
                  ><i class="bi bi-eye"></i
                ></a>
                <a (click)="shareTraining(i._id)" class="btn btn-sm btn-neutral"
                  ><i
                    class="bi bi-arrow-right-square-fill"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="{{ 'btn_hover_2' | translate }}"
                  ></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="lenghtRecentResultsFiltred > 10" class="card-footer border-0 py-5">
          <div class="d-flex justify-content-center">
            <pagination-controls
              class="my-pagination"
              previousLabel="{{ 'shared_5_pagination' | translate }}"
              nextLabel="{{ 'shared_6_pagination' | translate }}"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
      <div *ngIf="lenghtRecentResultsFiltred == 0" class="table-responsive" style="text-align: center;">
        <p style="padding-bottom: 40px; padding-top: 15px">
          {{'formation_error_pagination' | translate}}
        </p>
    </div>
  </div>
  </div>
</main>
