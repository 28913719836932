import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CertificateService } from "src/app/_services/certificate.service";
import { DistributerService } from "src/app/_services/distributer.service";

@Component({
  selector: "app-list-certificates-distributeurs",
  templateUrl: "./list-certificates-distributeurs.component.html",
  styleUrls: ["./list-certificates-distributeurs.component.css"],
})
export class ListCertificatesDistributeursComponent implements OnInit {
  id: any;
  listeCertificates;
  date = new Date();
  dateFin;
  listAllCertificates = false;
  listExipredCertificates = false;
  toutesCertificates: any;
  firstName: string;
  lastName: string;
  sizeListToutesCertficates;
  constructor(
    private route: ActivatedRoute,
    private certificateService: CertificateService,
    private distributerService: DistributerService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.allCertficates();
    this.distributerService
      .getDistributerById(this.id)
      .subscribe(async (data) => {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
      });
  }
  allCertficates() {
    this.listAllCertificates = true;
    this.listExipredCertificates = false;
    this.certificateService
      .listeCertificateByInstallateurInAdminEspace(this.id)
      .subscribe(async (data) => {
        this.toutesCertificates = data;
        this.listeCertificates = data;
        let results = [];
        for (let c of this.listeCertificates) {
          this.dateFin = c.dateEndExipred;
          if (
            this.compareDate(
              this.date.toISOString().substring(0, 10),
              this.dateFin.toString().substring(0, 10)
            ) == false && c.test != null
          ) {
            results.push(c);
          }
        }
        this.listeCertificates = results;
        this.sizeListToutesCertficates = results.length;
      });
  }
  certficatsExipreds() {
    this.listAllCertificates = false;
    this.listExipredCertificates = true;
    let results = [];
    for (let c of this.toutesCertificates) {
      this.dateFin = c.dateEndExipred;
      if (
        this.compareDate(
          this.date.toISOString().substring(0, 10),
          this.dateFin.toString().substring(0, 10)
        ) == true && c.test != null
      ) {
        results.push(c);
      }
    }
    this.listeCertificates = results;
    this.sizeListToutesCertficates = results.length;
  }

  compareDate(d1, d2) {
    if (d1 > d2) return true;
    else return false;
  }
}
