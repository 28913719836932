import { Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {TranslateService} from '@ngx-translate/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentLanguage: string;


  constructor(private recaptchaV3Service: ReCaptchaV3Service,  public translate: TranslateService,) {
    translate.addLangs(['fr', 'en','nl','de']);
    translate.setDefaultLang('fr');
    if(localStorage.getItem('currentLang')){
      translate.use(localStorage.getItem('currentLang'));  
    }else{
      localStorage.setItem('currentLang','fr');
      translate.use('fr');
    }

  }

  switchLang(lang: string) {
    if(lang){
      this.translate.use(lang);
    const currentLang = this.translate.currentLang;
    localStorage.setItem("currentLang", currentLang);
    this.getLangue();
    }else{
      lang ='fr'
      this.translate.use(lang);
      const currentLang = this.translate.currentLang;
      localStorage.setItem("currentLang", currentLang);
      this.getLangue();
    }
    
  }
  getLangue(){
    return this.currentLanguage
  }


  title = 'fineo';
}
