import { Component, OnInit } from "@angular/core";
import { Ng2IzitoastService } from "ng2-izitoast";
import { CertificateService } from "src/app/_services/certificate.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-certificates-distributer",
  templateUrl: "./certificates-distributer.component.html",
  styleUrls: ["./certificates-distributer.component.css"],
})
export class CertificatesDistributerComponent implements OnInit {
  certficates;
  date = new Date();
  dateFin;
  listAllCertificates = false;
  listExipredCertificates = false;
  toutesCertificates: any;
  sizeListToutesCertficates;
  constructor(
    private certficateService: CertificateService,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getCertficates();
  }

  getCertficates() {
    this.certficateService.getCertificatesByUser().subscribe((data) => {
      this.listAllCertificates = true;
      this.listExipredCertificates = false;
      this.toutesCertificates = data;
      this.certficates = data;
      let results = [];
      for (let c of this.certficates) {
        this.dateFin = c.dateEndExipred;
        if (
          this.compareDate(
            this.date.toISOString().substring(0, 10),
            this.dateFin.toString().substring(0, 10)
          ) == false && c.test != null
        ) {
          results.push(c);
        }
      }
      this.certficates = results;
      this.sizeListToutesCertficates = results.length;
    });
  }
  certficatsExipreds() {
    this.listAllCertificates = false;
    this.listExipredCertificates = true;
    let results = [];
    for (let c of this.toutesCertificates) {
      this.dateFin = c.dateEndExipred;
      if (
        this.compareDate(
          this.date.toISOString().substring(0, 10),
          this.dateFin.toString().substring(0, 10)
        ) == true && c.test != null
      ) {
        results.push(c);
      }
    }
    this.certficates = results;
    this.sizeListToutesCertficates = results.length;
  }

  compareDate(d1, d2) {
    if (d1 > d2) return true;
    else return false;
  }
  toastExpiredCertificate() {
    this.iziToast.show({
      message: this.translate.instant("certficat_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }
}
