import { Component, OnInit } from '@angular/core';
import { CertificateService } from 'src/app/_services/certificate.service';
import { InstallerTrainingsService } from 'src/app/_services/installer-trainings.service';
import { TrainingService } from 'src/app/_services/training.service';

@Component({
  selector: 'app-dashbord-installateur',
  templateUrl: './dashbord-installateur.component.html',
  styleUrls: ['./dashbord-installateur.component.css']
})
export class DashbordInstallateurComponent implements OnInit {
  trainings 
  nbrTrainings
  nbrValidCertificates;
  nbrExpiredCertificates
  ValidCertificates: any;
  Expiredcertficates
  dateFin
  date=new Date();
  constructor(
    private trainingService :TrainingService,
    private certficateService :CertificateService) { }

  ngOnInit(): void {
    this.getTrainings()
    this.getCertficates()
  }

  getTrainings(){ 
    this.trainingService.getTrainingsByInstaller().subscribe(e=>{
     this.trainings=e
     this.nbrTrainings=this.trainings.length
  
     })
}

getCertficates()
{
   this.certficateService.getCertificatesByUser().subscribe(data =>{

   
    this.ValidCertificates=data
     this.nbrValidCertificates=this.ValidCertificates.length
     let results =[]
     for(let c of this.ValidCertificates)
     {
       this.dateFin=c.dateEndExipred
       if(this.compareDate(this.date.toISOString().substring(0,10), this.dateFin.toString().substring(0,10)) == true)
       {
         results.push(c)
       }
     }
     this.Expiredcertficates=results
     this.nbrExpiredCertificates=this.Expiredcertficates.length
   }
   )
}

compareDate(d1,d2)
{
  if(d1 > d2)
  return true
  else
  return false;
}


}
