<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{'instalatteur_title' | translate}}</h1>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1"></div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{'instalatteur_card_add_title_2' | translate}}</h5>
      </div>

      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label>{{'instalateur_card_add_content_1' | translate}}</label>
              <input
                type="text"
                formControlName="firstName"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
              />
              <div
                *ngIf="submitted && f.firstName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.firstName.errors.required">
                  {{'shared_2_error' | translate}}
                </div>
                <div *ngIf="f.firstName.errors.minlength">
                  {{'error_confirmation_length' | translate}}.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{'profil_card_2' | translate}}</label>
              <input
                type="text"
                formControlName="lastName"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
              />
              <div
                *ngIf="submitted && f.lastName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.lastName.errors.required">{{'shared_2_error' | translate}}</div>
                <div *ngIf="f.lastName.errors.minlength">
                  {{'error_confirmation_length' | translate}}.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{'profil_card_3' | translate}}</label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{'shared_2_error' | translate}}</div>
                <div *ngIf="f.email.errors.email">
                  {{'error_confirmation_email' | translate}}.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{'instalateur_card_add_content_4' | translate}}</label>
              <!-- <select
                class="form-select"
                aria-label="Default select example"
                formControlName="type"
                [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
              >
                <option>Premium</option>
                <option>Simple</option>
              </select> -->
              <ng-select [items]="types"
                   bindLabel="name"
                   formControlName="type"
                   [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
                   class="form-control"
                   [closeOnSelect]="true"
                   >
              </ng-select>
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">{{'shared_2_error' | translate}}</div>
              </div>
            </div>
            <div class="form-group">
              <label>{{'instalateur_card_add_content_5' | translate}}</label>
              <ng-select 
                
                [items]="distrubters"
                formControlName="distrubter"
                [ngClass]="{ 'is-invalid': submitted && f.distrubter.errors }"
                bindLabel="companyEmailDistributeur"
                
                class="form-control"
                bindValue="_id"
                [multiple]="false"
                [searchable]="true"
                [closeOnSelect]="true"
                [clearable]="true"
                [searchable]="true"
                
                placeholder="{{'instalateur_card_add_content_5_1' | translate}}"
              >
             
              </ng-select>
              <div
                *ngIf="submitted && f.distrubter.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.distrubter.errors.required">
                  {{'shared_2_error' | translate}}
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary mr-1">{{'btn_1' | translate}}</button>
              <button
                class="btn btn-secondary"
                type="reset"
                (click)="onReset()"
              >
              {{'btn_2' | translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
