import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  admin:any;
  constructor(private autenticationService :AuthenticationService, private router: Router,private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.autenticationService.getaccessauthroisation().subscribe((result) => {
      if(result && result[0]){
        this.activatedRoute.queryParams.subscribe(params => {
          let code = params['code'];
          this.autenticationService.callbacFunction(result[0],code).subscribe((resultt) => {
         	if(resultt && resultt["access_token"]){
          this.autenticationService.apimeFunction(resultt["access_token"]).subscribe((result) => {
            if(result && result["data"]){
              //this.admin = result["data"].firstName + "  "+result["data"].lastName
              if(!result["data"].has_access_to_elearning || (result["data"].has_access_to_elearning && result["data"].has_access_to_elearning === 0 )){
                localStorage.clear();
                document.location.href = 'https://fineo.clevermint-dev.be'
                //console.log("hello1")
                return false;
              }
               localStorage.setItem('token_auth',resultt["access_token"])
               localStorage.setItem('token',result["token_el"])
               if(result["data"].role_slug == "admin"){
                this.router.navigate(['/admin'])
                return true;
              }
                if(result["data"].role_slug == "distributor")
              {
                this.router.navigate(['/distributeur'])
                return true;

              }
              if(result["data"].role_slug == "installer")
              {
                this.router.navigate(['/installateur'])
                return true;
              }          
  
              }
        }, (err) => {
          localStorage.clear();
          document.location.href = 'https://fineo.clevermint-dev.be'
        //console.log("hello12")  
	return false;
        });
        }
        }, (err) => {
          localStorage.clear();
          document.location.href = 'https://fineo.clevermint-dev.be'
//console.log("hello133")
          return false;
        });
      });





        }
  }, (err) => {
   return false;
  });

  }

}
