import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { AnimationModel } from "@syncfusion/ej2-progressbar";
import { ResultTestService } from "src/app/_services/result-test.service";
import { Ng2IzitoastService } from "ng2-izitoast";
import { CertificateService } from "src/app/_services/certificate.service";
import { InstallerTrainingsService } from "src/app/_services/installer-trainings.service";
import { TrainingService } from "src/app/_services/training.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-tests-installer",
  templateUrl: "./tests-installer.component.html",
  styleUrls: ["./tests-installer.component.css"],
})
export class TestsInstallerComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  tableSize: number = 2;
  tableSizes: any = [3, 6, 9, 12];
  finalFalseAnswers =[]
  falseAnswer =[]
  showAnswer =false
  private upload=`${environment.uploadUrl}`
  isReadMore = true;

  date = new Date();
  public animation: AnimationModel;
  description;
  progressValue;
  progress = 0;
  display: any;
  test;
  id;
  d;
  numberQuestions;
  temps;
  pass = false;
  questions;
  taille;
  public currentQuestion: number = 0;
  score;
  scoreTest: number;
  rightAnswer: any;
  totalAnswered: number;
  interval$: any;
  counter;
  closeResult = "";
  result = false;
  exemple = "exemple";
  breakTime: any;
  dateFin;
  titleTraining;
  statuts: boolean;
  idBackInListVideos;
  resultat: any;
  resultatTest: any;
  verifRepassageTest;
  sizeDescription;
  certificateList:any;
  hasExpiredCertifcated = false
  constructor(
    private examenService: ExamenServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private resultService: ResultTestService,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService,
    private certificateService: CertificateService,
    private installerTrainingsService: InstallerTrainingsService,
    private trainingService: TrainingService,
    private sanitizer:DomSanitizer

  ) {}

  ngOnInit(): void {
    this.animation = { enable: false, delay: 0 };
    this.id = this.route.snapshot.params["id"];
    this.getTrainingById();
    //this.recuperateTraning();
    this.installerTrainingsService
    .getTrainingFromInsatllerTrainings(this.id)
    .subscribe((e) => {

      this.idBackInListVideos = e[0]._id;

     
    
    this.d = this.examenService
      .getTestByTraining(this.id)
      .subscribe(async (data) => {
        this.test = data;
        this.numberQuestions = this.test.questions.length;
        this.temps = this.test.temps;
        this.questions = this.test.questions;
        this.taille = this.questions.length;
        this.description = this.test.titre;
        this.sizeDescription = this.description.length;
        this.dateFin = this.test.dateFin;
        this.titleTraining = this.test.training.title;
        this.counter = 0;
        this.scoreTest = Number(data.score);
        //this.scoreTest = Number(100);
        this.updateTest();
        this.getResultTest();
        this.RepassageTest();
        for (let q of this.questions) {
          q.filename =  this.sanitizer.bypassSecurityTrustUrl(this.upload+q.filename);
          for (let c of q.choix) {
            if (c.selected == true) {
              c.selected = false;
            }
          }
        }
      });
    });
  }
  passTest() {
    if(this.resultatTest.length ==0 || this.resultatTest[0].success == false){
    localStorage.setItem("clickTest", "1");
    }
    this.startTest();

    // if (
    //   this.compareDate(
    //     this.date.toISOString().substring(0, 10),
    //     this.dateFin.toString().substring(0, 10)
    //   ) == false
    // ) {
      if (this.statuts == true) {
        this.progress = (1 / this.questions.length) * 100;
        this.timer(this.counter);
        this.animation.enable = true;
        this.animation.duration = this.temps * 1000 * 60;
        this.pass = true;
        this.modalService.dismissAll();
      } else {
        if (this.resultatTest[0].success == true && !this.hasExpiredCertifcated) {
          this.modalService.dismissAll();
          this.iziToast.show({
            message: this.translate.instant("test_message_1"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        } else {
            this.progress = (1 / this.questions.length) * 100;
            this.timer(this.counter);
            this.animation.enable = true;
            this.animation.duration = this.temps * 1000 * 60;
            this.pass = true;
            this.modalService.dismissAll();
          
        }
      }
    // } else {
    //   this.modalService.dismissAll();
    //   this.iziToast.show({
    //     message: this.translate.instant("test_message_3"),
    //     messageColor: "#800f2f",
    //     titleColor: "#800f2f",
    //     progressBarColor: "#c9184a",
    //     icon: "bi-exclamation-diamond",
    //     imageWidth: 45,
    //     position: "topRight",
    //     timeout: 3000,
    //     backgroundColor: "#ff8fa3",
    //     transitionIn: "flipInX",
    //     transitionOut: "flipOutX",
    //     overlay: true,
    //     overlayClose: true,
    //   });
    //   this.modalService.dismissAll();
    //   setTimeout(function(){
    //     window.location.reload()
    //   },3000);
    // }
  }

  nextQuestion() {
    this.currentQuestion++;
    this.progress += (1 / this.questions.length) * 100;
  }
  previousQuestion() {
    this.currentQuestion--;
    this.progress -= (1 / this.questions.length) * 100;
  }

  submitTest() {
    this.animation.enable = false;
    this.result = true;
    this.rightAnswer = 0;
    this.totalAnswered = 0;
    let s = 0;
    let scores = [];

    for (let ex of this.questions) {
      s = 0;
    let answredQuestionFlag = false

      for (let c of ex.choix) {
        if (c.selected == true) {
          answredQuestionFlag = true
          if (ex.answer.includes(c.text)) {
            this.rightAnswer++;
            s = s + 1;
            for (let i = ex.choix.indexOf(c) + 1; i < ex.choix.length; i++) {
              if (ex.choix[i].selected == true) {
                if (!ex.answer.includes(ex.choix[i].text)) {
                  s = s - 1;
                  break;
                }
              }
            }
          }
          else
          {
            //answredQuestionFlag = true
            s=0
            console.log("ex.choix.indexOf(c)",ex.choix)
            //  if(ex.choix.indexOf(c) == 0){
              for(let i = 0; i < ex.choix.length; i++){
                if(ex.choix[i].selected == true && !ex.answer.includes(ex.choix[i].text))
                this.falseAnswer.push({"question":ex.question,"answer":ex.choix[i].text})
              }
              
               //
               
               
               break
              
            //  }
            
            //  else{
            //  s=s
            // this.falseAnswer.push({"question":ex.question,"answer":c.text})}
            //break
          }
          // if (!ex.answer.includes(c.text)) {
          //   s = s;
          //   this.falseAnswer.push({"question":ex.question,"answer":c.text})
          //   //break;
          // }


        }
       
       
      }
      if(answredQuestionFlag ==  false){
        this.falseAnswer.push({"question":ex.question,"answer":[]})
      }
      s = (s / ex.answer.length) * 100;
      scores.push(s);
    }
    let filtredfalseAnswer =[]
   for(let i=0; i< this.falseAnswer.length ;i++)
   {
     if (filtredfalseAnswer.find(item => item.question === this.falseAnswer[i].question)){
       let indice = filtredfalseAnswer.indexOf(filtredfalseAnswer.find(item => item.question === this.falseAnswer[i].question))
       filtredfalseAnswer[indice].answer.push(this.falseAnswer[i].answer)       
     }
     else{
      filtredfalseAnswer.push({"question":this.falseAnswer[i].question,"answer":[this.falseAnswer[i].answer]})
     }    
   }
   this.finalFalseAnswers=filtredfalseAnswer
    let total = 0;
    for (let sf of scores) {
      total = total + sf;
    }

    this.score = (total / scores.length).toFixed(2);
    this.score = Number(this.score);
    this.saveScore(this.score);
    if (this.score >= this.scoreTest) {
      this.createCertficate();
      this.setGetCertificate();
      localStorage.setItem("result", "success");
    } else {
      localStorage.setItem("result", "fail");
    }
    this.modalService.dismissAll();
    localStorage.setItem("clickTest", "0");
  }

  saveScore(note) {
    this.resultService.SaveScore(this.test._id, note).subscribe((data) => {});
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  open2(content2) {
    this.modalService
      .open(content2, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  timer(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 0;

    const prefix = minute < 10 ? "" : "";

    const timer = setInterval(() => {
      seconds++;
      if (statSec < 59) statSec++;
      else statSec = 0;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == this.temps * 60) {
        this.breakTime = this.display;
        clearInterval(timer);
        this.iziToast.show({
          timeout: 20000,
          close: false,
          overlay: true,
          title: this.translate.instant("test_message_4"),
          message: this.translate.instant("test_message_5"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          backgroundColor: "#ff8fa3",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "center",
          buttons: [
            [
              "<button><b>OK</b></button>",
              (instance, toast) => {
                instance = this.submitTest();
                this.iziToast.destroy();
              },
              true,
            ],
          ],
          onClosed: (instance, toast) => {
            instance = this.submitTest();
          },
        });
      }
      if (this.result == true) {
        this.breakTime = this.display;
        this.progressValue = (seconds / (this.temps * 60)) * 100;
        clearInterval(timer);
      }
    }, 1000);
    if (minute == this.display) {
      this.submitTest();
    }
  }

  updateTest() {
    this.resultService.getVerifTest(this.test._id).subscribe((e) => {
      this.statuts = e;
    });
  }

  createCertficate() {
    this.certificateService
      .createCertificate(this.test._id)
      .subscribe((e) => {});
  }

  // recuperateTraning() {
  //   this.installerTrainingsService
  //     .getTrainingFromInsatllerTrainings(this.id)
  //     .subscribe((e) => {
  //       this.idBackInListVideos = e[0]._id;
  //     });
  // }

  compareDate(d1, d2) {
    if (d1 > d2) return true;
    else return false;
  }

  getResultTest() {
    this.resultService.getResultTest(this.test._id).subscribe(async (data) => {
      this.resultatTest = data;
      if(this.resultatTest && this.resultatTest.length >0){
        this.certificateService.getCertificatesByTestLastOne(this.resultatTest[0].test).subscribe(async (data) => {
          this.certificateList = data;
          if (this.compareDate(this.date.toISOString().substring(0, 10), this.certificateList.dateEndExipred.toString().substring(0, 10)) == true) {
            this.hasExpiredCertifcated = true;
          }
        });
      }
    });
  }

  RepassageTest() {
    this.resultService
      .reVerifPassedTest(this.test._id)
      .subscribe(async (data) => {
        this.verifRepassageTest = data;
      });
  }

  startTest() {
    this.resultService
      .getStartTest(this.test._id)
      .subscribe(async (data) => {});
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }
  getTrainingById() {
    this.trainingService.getTrainingById(this.id).subscribe(async (data) => {
      if (data.etat == false)
       this.router.navigate(["installateur/formations"]);
    });
  }

  shwoReponses()
  {
    this.showAnswer =true;
  }

  setGetCertificate()
  {
    this.installerTrainingsService.setGetCertificate(this.idBackInListVideos).subscribe( data => {

    })
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.finalFalseAnswers  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.finalFalseAnswers  }
}
