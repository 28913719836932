import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Certificate } from "src/app/_models/certificate";
import { CertificateService } from "src/app/_services/certificate.service";
import jsPDF from "jspdf";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TrainingService } from "src/app/_services/training.service";
@Component({
  selector: "app-details-certificate",
  templateUrl: "./details-certificate.component.html",
  styleUrls: ["./details-certificate.component.css"],
})
export class DetailsCertificateComponent implements OnInit {
  certificat: Certificate = new Certificate();
  d: any;
  id: any;
  certificate;
  firstName;
  lastName;
  training: any;
  title: any;
  exipredDate;
  datePris: any;
  dateAuj =new Date()
  date
  @ViewChild("content", { static: false }) el!: ElementRef;
  constructor(
    private certificateService: CertificateService,
    private route: ActivatedRoute,
    private trainingService: TrainingService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.date=this.dateAuj.toLocaleDateString("fr")
    this.d = this.certificateService
     
      .getCertificatesByTest(this.id)
      .subscribe(async (data) => {
        this.certificate = data;
        this.firstName = this.certificate.user.firstName;
        this.lastName = this.certificate.user.lastName;
        this.training = this.certificate.test.training;
        this.datePris = this.certificate.firstDate;
        this.exipredDate = this.certificate.dateEndExipred;

        this.recuperateTraining();
      });
  }

  recuperateTraining() {
    this.trainingService
      .getTrainingById(this.training)
      .subscribe(async (data) => {
        this.training = data;
        this.title = this.training.title;
      });
  }
  makePDF() {
    let pdf = new jsPDF({
      orientation: "l",
      unit: "pt",
      format: "a3",
      putOnlyUsedFonts: true,
      precision : 0
    });
    pdf.html(this.el.nativeElement, {
      callback: (pdf) => {
        pdf.save(this.date+"_"+this.firstName[0]+"_"+this.lastName[0]+ "_"+this.training.title);
      },
    });
  }
}
