<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="div-position-responsive mb-2">
      <button
        style="float: left"
        routerLink="/distributeur/formations"
        class="btn d-inline-flex btn-sm btn-primary mx-1 btn-position-responsive"
      >
        <i class="bi bi-arrow-bar-left"></i>
        {{ "formation_details_return_to_formations" | translate }}
      </button>

      <button *ngIf="trainings && trainings.checkTest"
        style="float: right"
        (click)="passExamen(trainings)"
        class="btn d-inline-flex btn-sm btn-primary mx-1 btn-position-responsive"
      >
        <span style="color: #fff">{{
          "formation_test_passer_test" | translate
        }}</span>
      </button>
      <button *ngIf="statutFirstVideo==true && compteur == videos.length && cls == false"
      (click)="goConclussion()"
      style="float: right"
      class="btn d-inline-flex btn-sm btn-secondary mx-1 btn-position-responsive"
    >
      <i class="bi bi-arrow-bar-right"></i>
      {{ "formation_test_passer_conclusion" | translate }}
    </button>

    <button *ngIf="cls == true"
      (click)="gotraining()"
      style="float: right"
      class="btn d-inline-flex btn-sm btn-secondary mx-1 btn-position-responsive"
    >
      <i class="bi bi-arrow-bar-left"></i>
      {{ "formation_test_returnTraining" | translate }}
    </button>

      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
    <br />
  </div>
</header>

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ titleTraining }}</h5>
      </div>

      <div class="container py-5">
        <div *ngIf="cls == false">
          <p style="margin-top: 11px;margin-bottom: 44px;" *ngIf="cls == false" [innerHTML]="descriptionTraining"></p>
         </div>
  

        <div>
          <div class="row">
            <div class="col-10">
              <ngb-carousel #mycarousel>
                <ng-template *ngFor="let k of videos" ngbSlide>
                  <div *ngIf="cls == false"> 
                  <span *ngIf="k.statut" style="float: right"
                    ><i class="bi bi-check2"></i
                  ></span>
                  <span *ngIf="!k.statut" style="float: right"
                    ><i class="bi bi-x"></i
                  ></span>
                  <video
                    controls
                    id="video"
                    width="700"
                    on-ended="vidEnded(trainings, k._id, $event)"
                  >
                    <source [src]="k.filename" type="video/mp4" />
                  </video>
                  <p [innerHTML]="k.descriptionVideo"></p>

                  <a
                    *ngIf="compteur < videos.length"
                    (click)="NextVideo(trainings, k._id)"
                    style="float: right; cursor: pointer"
                    ><i class="bi bi-arrow-right-circle-fill"></i
                  ></a>
                  <a
                    *ngIf="compteur > 1"
                    (click)="previousVideo()"
                    style="float: left; cursor: pointer"
                    ><i class="bi bi-arrow-left-circle-fill"></i
                  ></a>
                </div>
                </ng-template>
              </ngb-carousel>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <div *ngIf="cls == true">
          <P style="margin-top: 11px;margin-bottom: 44px;" [innerHTML]="conclusionTraining"></P>
          <input [(ngModel)]="trainings.checkTest" type="checkbox" id="cb1" (change)="setGetCertificate()" > {{ "checkBox_condtions" | translate }}
        </div>

      </div>
    </div>
  </div>
</main>
