import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Ng2IzitoastService } from "ng2-izitoast";
import { TrainingService } from "src/app/_services/training.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-training",
  templateUrl: "./add-training.component.html",
  styleUrls: ["./add-training.component.css"],
})
export class AddTrainingComponent implements OnInit {
  checkVideo2 : boolean =true
  checkVideo3 : boolean =true
  checkVideo4 : boolean =true
  isLoading = false;
  registerForm: FormGroup;
  submitted = false;
  typeVideo = true;
  typeSlider = false;
  typeArticles = false;
  d1: string;
  dateAuj = new Date();
  fr = true;
  en = false;
  nl = false;
  de = false;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef = undefined;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef =
    undefined;
  @ViewChild("fileInput2", { static: false }) fileInput2: ElementRef =
    undefined;
  @ViewChild("fileInput3", { static: false }) fileInput3: ElementRef =
    undefined;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  
  eroorMessage: any;
  taille ;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private trainingService: TrainingService,
    private translate: TranslateService,
    public iziToast: Ng2IzitoastService
  ) {}

  ngOnInit() {
    if (localStorage && localStorage.getItem("currentLang")) {
      var lang = localStorage.getItem("currentLang");
      if (lang == "fr") {
        this.setLangueFrancais();
      } else if (lang == "nl") {
        this.setLangueNeurlend();
      } else if (lang == "de") {
        this.setLangueAllmend();
      } else {
        this.setLangueAnglais();
      }
    } else {
      this.setLangueAnglais();
    }
    this.registerForm = this.formBuilder.group({
      title: ["", [Validators.required, , Validators.maxLength(50)]],
      description: ["", [Validators.required]],
      dateEnd: [""],

     // video: ["", [Validators.pattern("[^\\s]+(.*?)\\.(mp4|MP4 )$")]],
      video: ["",],
      type: [""],
      language: [""],
      descriptionVideos: [],
      descriptionVideo1: ["", []],
      descriptionVideo2: ["", []],
      descriptionVideo3: ["", []],
      descriptionVideo4: ["", []],
      conclusion: [
        "",
        [
          Validators.required,
        ],
      ],

      choix: this.formBuilder.array([]),
    });

    this.dateAuj.setDate(this.dateAuj.getDate() + 1);

    this.d1 = this.dateAuj.toISOString().substr(0, 10);

    this.choix().push(
      this.formBuilder.group({
        text: "",
        description: "",
        selected: false,
      })
    );
  }
  choix(): FormArray {
    return this.registerForm.get("choix") as FormArray;
  }

  newchoix(): FormGroup {
    return this.formBuilder.group({
      text: ["", Validators.required],
      description: ["", Validators.required],
      selected: false,
    });
  }

  addQuantity() {
    this.choix().push(this.newchoix());
  }

  removeQuantity(i) {
    this.choix().removeAt(i);
  }
  get f() {
    return this.registerForm.controls;
  }
  saveTraining() {
    let dateExipred;
    dateExipred = this.registerForm.value.dateEnd;
    this.registerForm.value.type = "video";
    if (this.typeVideo == true) this.registerForm.value.type = "video";
    if (this.typeSlider == true) this.registerForm.value.type = "slider";
    if (this.typeArticles == true) this.registerForm.value.type = "articles";

    this.registerForm.value.language = "FR";
    if (this.fr == true) this.registerForm.value.language = "FR";
    if (this.en == true) this.registerForm.value.language = "EN";
    if (this.nl == true) this.registerForm.value.language = "NL";
    if (this.de == true) this.registerForm.value.language = "DE";

    if (this.registerForm.value.type == "video") {
      let deccriptions = [];
      deccriptions.push(this.registerForm.value.descriptionVideo1);
      deccriptions.push("/**/");
      deccriptions.push(this.registerForm.value.descriptionVideo2);
      deccriptions.push("/**/");
      deccriptions.push(this.registerForm.value.descriptionVideo3);
      deccriptions.push("/**/");
      deccriptions.push(this.registerForm.value.descriptionVideo4);
      this.registerForm.value.descriptionVideos = deccriptions;
      let videos: any[] = [];
      const form = new FormData();

      const imagBlob = this.fileInput.nativeElement.files[0];
      const imagBlob1 = this.fileInput1.nativeElement.files[0];
      const imagBlob2 = this.fileInput2.nativeElement.files[0];
      const imagBlob3 = this.fileInput3.nativeElement.files[0];

      videos.push(imagBlob);
      videos.push(imagBlob1);
      videos.push(imagBlob2);
      videos.push(imagBlob3);
      this.taille = 0;
      for (let i = 0; i < videos.length; i++) {
        if (videos[i] != undefined) {
          this.taille = this.taille + videos[i].size;
        }

        form.append("video", videos[i]);
      }

      form.set("title", this.registerForm.value.title);
      form.set("description", this.registerForm.value.description);
      form.set("type", this.registerForm.value.type);
      form.set("language", this.registerForm.value.language);
      form.set("dateEnd", this.registerForm.value.dateEnd);
      form.set("descriptionVideos", this.registerForm.value.descriptionVideos);
      form.set("conclusion", this.registerForm.value.conclusion);
      this.submitted = true;

      if (this.registerForm.invalid) {
        return;
      }
      if (imagBlob != undefined) {
        if (this.taille < 31457280) {
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = true;
            this.registerForm.value.dateEnd = dateExipred;
            this.trainingService.createTraining(form).subscribe(
              (data) => {
                this.iziToast.show({
                  message: this.translate.instant("shared_4_succes"),
                  messageColor: "#386641",
                  progressBarColor: "#6a994e",
                  icon: "bi-check-circle",
                  imageWidth: 45,
                  position: "topRight",
                  timeout: 5000,
                  backgroundColor: "#dde5b6",
                  transitionIn: "flipInX",
                  transitionOut: "flipOutX",
                });
                this.goToList();
                
              },
              (error) => {
                console.log(typeof error.error);
                if (error.error.indexOf("erci") > -1) {
                  this.eroorMessage =
                    this.translate.instant("shared_18_eroor_add") +
                    " " +
                    error.error[error.error.length - 1];
                } else {
                  this.eroorMessage =
                    this.translate.instant("shared_18_1_eroor_add") +
                    " " +
                    error.error[error.error.indexOf("|") + 1] +
                    " " +
                    this.translate.instant("shared_18_2_eroor_add");
                }
                this.isLoading = false;

                // this.eroorMessage = error.error;
                this.iziToast.show({
                  message: this.eroorMessage,
                  messageColor: "#800f2f",
                  titleColor: "#800f2f",
                  progressBarColor: "#c9184a",
                  icon: "bi-exclamation-diamond",
                  imageWidth: 45,
                  position: "topRight",
                  timeout: 5000,
                  backgroundColor: "#ff8fa3",
                  transitionIn: "flipInX",
                  transitionOut: "flipOutX",
                  overlay: true,
                  overlayClose: true,
                });
              }
            );
          });
        } else {
          if (this.fr == true)
            this.iziToast.show({
              message: this.translate.instant("shared_10_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          if (this.en == true)
            this.iziToast.show({
              message: this.translate.instant("shared_10_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          if (this.nl == true)
            this.iziToast.show({
              message: this.translate.instant("shared_10_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          if (this.de == true)
            this.iziToast.show({
              message: this.translate.instant("shared_10_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
        }
      } else {
        let errorMessage;
        errorMessage =
          this.translate.instant("shared_20_error") +
          " " +
          1 +
          " " +
          this.translate.instant("shared_24_error");
        if (this.fr == true)
          this.iziToast.show({
            message: errorMessage,
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.en == true)
          this.iziToast.show({
            message: errorMessage,
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.nl == true)
          this.iziToast.show({
            message: errorMessage,
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.de == true)
          this.iziToast.show({
            message: errorMessage,
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
      }
    }
    if (
      this.registerForm.value.type == "slider" ||
      this.registerForm.value.type == "articles"
    ) {
      if (this.registerForm.invalid) {
        if (this.fr == true)
          this.iziToast.show({
            message: this.translate.instant("shared_3_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.en == true)
          this.iziToast.show({
            message: this.translate.instant("shared_3_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.nl == true)
          this.iziToast.show({
            message: this.translate.instant("shared_3_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        if (this.de == true)
          this.iziToast.show({
            message: this.translate.instant("shared_3_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        return;
      }

      this.trainingService
        .createTrainingAdndSliderOrArticle(this.registerForm.value)
        .subscribe((data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.goToList();
        });
    }
  }
  goToList() {
    this.router.navigate(["/admin/formations"]);
  }
  onSubmit() {
    this.saveTraining();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  setTypeVideo() {
    this.typeVideo = true;
    this.typeSlider = false;
    this.typeArticles = false;
  }
  setTypeSlider() {
    this.typeVideo = false;
    this.typeSlider = true;
    this.typeArticles = false;
  }
  setTypeArticles() {
    this.typeVideo = false;
    this.typeSlider = false;
    this.typeArticles = true;
  }

  setLangueFrancais() {
    this.fr = true;
    this.en = false;
    this.nl = false;
    this.de = false;
  }
  setLangueAnglais() {
    this.fr = false;
    this.en = true;
    this.nl = false;
    this.de = false;
  }
  setLangueNeurlend() {
    this.fr = false;
    this.en = false;
    this.nl = true;
    this.de = false;
  }
  setLangueAllmend() {
    this.fr = false;
    this.en = false;
    this.nl = false;
    this.de = true;
  }
  changeCheckVideo1 (fileInput1 )
 
  {
   // console.log("fileInput1",fileInput1.files[0])
    if(fileInput1.files[0] != undefined)
    this.checkVideo2 =false
    else
    this.checkVideo2 =true

  }
  changeCheckVideo2(fileInput1 )
  {
    //console.log("fileInput1",fileInput1.files[0])
    if(fileInput1.files[0] != undefined)
    this.checkVideo3 =false
    else
    this.checkVideo3 =true

  }

  changeCheckVideo3(fileInput2 )
  {
    //console.log("fileInput1",fileInput2.files[0])
    if(fileInput2.files[0] != undefined)
    this.checkVideo4 =false
    else
    this.checkVideo4 =true

  }

}
