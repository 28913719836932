import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user';
import { DistributerService } from 'src/app/_services/distributer.service';
import {  TranslateService} from '@ngx-translate/core';
import { InstallerService } from 'src/app/_services/installer.service';


@Component({
  selector: 'app-distributer',
  templateUrl: './distributer.component.html',
  styleUrls: ['./distributer.component.css']
})
export class DistributerComponent implements OnInit {

  valueChange = false;
  distributers
  closeResult = '';
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  emailstring: any;
  lengthListDistributers
  firstName:string
  lastName:string
  email:string
  company :string
  recentResultsDistributers

  constructor(
    private router :Router, 
    private ditributerService :DistributerService,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    private translate : TranslateService,
    private installerService:InstallerService,
    public iziToast: Ng2IzitoastService 
    ) { }

  ngOnInit(): void {
    this.getDistributers()
  }


  getDistributers()
  {
     this.ditributerService.getDistributers().subscribe(data =>{
       this.distributers=data
       this.recentResultsDistributers=this.distributers
       this.lengthListDistributers =this.distributers.length 
       if(this.distributers && this.distributers.length>0){
        this.startAddingIdDisributerForInstallers()
       }
       for(let i of this.distributers)
       {
        this.emailstring=i.email
        i.companyEmailDistributeur=i.email
       }
       
     }
     )
  }

  

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getDistributers();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getDistributers();
  }
  detailsTraining(id)
  {
    this.router.navigate(['admin/formations/details',id])
  }
  updateDistributer(id)
  {
    this.router.navigate(['admin/distributeurs/modification',id])
  }
  create()
  {
    this.router.navigate(['admin/distributeurs/ajout'])
  }

  displayListCertificatesbyDistributeur(id)
  {
    
    this.router.navigate(['admin/distributeurs/liste-certificats',id])

  }
  search()
  {
    if(this.firstName != "" || this.lastName != "" || this.email != "")
     {
      this.distributers = this.recentResultsDistributers
    this.distributers = this.distributers.filter(res=>{
      return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
      || res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) 
      || res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase() )
      || res.name.toLocaleLowerCase().match(this.company.toLocaleLowerCase()) 
     
      
       
    })
    this.lengthListDistributers =this.distributers.length 
    this.page=1
     }
     else if(this.firstName == "" && this.lastName == "" && this.email == ""  && this.company == "")
     {
      this.distributers = this.recentResultsDistributers
      this.lengthListDistributers =this.distributers.length 
     }
  }
  startAddingIdDisributerForInstallers(){
    this.installerService
      .editIdDistriubutorForInstaller(this.distributers)
      .subscribe((data) => {
      });
   }
}
