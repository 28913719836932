
 <header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "formation_title" | translate }}</h1>
        </div>
        <!-- Actions -->
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link active"> </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active"> </a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
</div>
  
  </header>

  <main class="py-6 bg-surface-secondary">
    <div class="container-fluid">
        <div class="card shadow border-0 mb-7">
            <div class="card-header ">
                <h5 class="mb-0">{{'formation_card_view_title' | translate }}</h5>
            </div>
           
    <div  class="container py-5">
        <p  [innerHTML]="trainings.description"></p> 
        <div >
           <div class="row">
            <div *ngFor="let k of videos" class="col-6 ">
             <div class="card">
              <div style="margin-top: 7%" class="card-body">
                
                <video controls class="col-12" on-ended="vidEnded()" >
                  
                <source [src]= "k.filename" type="video/mp4">
 
                Your browser does not support the video tag.
              </video>
              <br><br>
            <h5 class="card-title text-center">{{k.descriptionVideo}}</h5>
           
            </div>
            </div>
           
            </div>
            </div>
            <p [innerHTML]="trainings.conclusion"></p>

          </div>
         
  </div>
  </div>
  </div>
  </main>




