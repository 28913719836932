import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistributerService {

    //private _installerURL="http://localhost:3200/api/fitters" 
    private _distributerURL=`${environment.baseUrl}/distributers`
    constructor(private http: HttpClient) { }
  
  getDistributers() :Observable <any> {
    //return this.http.get(`${this._distributerURL}`);
    return this.http.post(`${this._distributerURL}/get/all/distributers`,{"bearertoken":localStorage.getItem("token_auth")}); 
        }
   
        createDistributer(user: any):Observable<Object> {
          return this.http.post(`${this._distributerURL}`,user);
        }
        
        deleteDistributer(id : string)
        {
          return this.http.delete(`${this._distributerURL}/${id}`);
        } 
   
       getDistributerById(id : string):Observable<User>
        {
          return this.http.post<User>(`${this._distributerURL}/${id}`,{"bearertoken":localStorage.getItem("token_auth")});
      
        } 
        
        EditDistributer(id:string,user:User):Observable<Object> {
          return this.http.put(`${this._distributerURL}/${id}`,user);
        }

         // statistiques pour admin
      numberDistributers():Observable <any> {
        return this.http.post(`${this._distributerURL}/admin/numberDistributrers`,{"bearertoken":localStorage.getItem("token_auth")});
            }

          
}
