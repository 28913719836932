import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/_models/user";
import { InstallerService } from "src/app/_services/installer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-installers",
  templateUrl: "./installers.component.html",
  styleUrls: ["./installers.component.css"],
})
export class InstallersComponent implements OnInit {
  valueChange = false;
  installers;
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  premnuim = false;
  allInstallers = false;
  emailstring;
  firstName:string
  lastName:string
  email:string
  type :string
  name
  lengthListInsatllers;
  recentResultsInstallers;
  constructor(
    private router: Router,
    private installerService: InstallerService,
    private toastr: ToastrService,
    private transalte: TranslateService,
    private modalService: NgbModal,
    public iziToast: Ng2IzitoastService
  ) {}

  ngOnInit(): void {
    this.getInstallers();
  }

  getInstallers() {
    this.allInstallers = true;
    this.premnuim = false;
    this.installerService.getInstallers().subscribe(data =>{
      if(data){
        this.installers=data["data"]
        this.startAddingIdDisributerForInstallers()
         this.recentResultsInstallers=this.installers
        this.lengthListInsatllers = this.installers.length;
        for(let i of this.installers)
        {
         this.emailstring=i.email
         if(i.premium){
          i.type = "Premium"
         }
         if(!i.premium){
          i.type = "Classic"
         }
         if(i.distrubter == null)
         {
           i.distrubter ={
             "name":i.name
           }
         }
        }
      }
     })
  }

  getInstallersWithPremnuim() {
    this.page = 1;
    this.allInstallers=false
    this. premnuim=true
    this.installers = this.installers.filter(x => x.premium)
    this.lengthListInsatllers = this.installers.length;
}

  deleteTraining(installer: User) {
    this.installerService.deleteInstaller(installer._id).subscribe((e) => {
      this.iziToast.show({
        message: this.transalte.instant("shared_17_success_delete"),
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      this.getInstallers();
    });
    this.modalService.dismissAll();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  updateInstaller(id) {
    this.router.navigate(["admin/installateurs/modification", id]);
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getInstallers();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getInstallers();
  }
  // detailsTraining(id)
  // {
  //   this.router.navigate(['admin/formations/details',id])
  // }
  create() {
    this.router.navigate(["admin/installateurs/ajout"]);
  }

  displayListCertificatesbyInstallater(id) {
    this.router.navigate(["admin/installateurs/liste-certificats", id]);
  }
  search()
{
  if(this.firstName != "" || this.lastName != "" || this.email != "" || this.type != "" || this.name != "")
   {
    this.installers = this.recentResultsInstallers
    this.installers = this.installers.filter(res => {
      return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) ||
        res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) ||
        res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase()) ||
        res.type.toLocaleLowerCase().match(this.type.toLocaleLowerCase()) ||
        //res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase())
        (res.distrubter &&  res.distrubter.name &&  res.distrubter.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase())) 
    });
    
  this.lengthListInsatllers = this.installers.length;
  this.page=1
  
   }
   else if(this.firstName == "" && this.lastName == "" && this.email == "" && this.type == "" && this.name == "" )
   {
    this.installers = this.recentResultsInstallers
    this.lengthListInsatllers = this.installers.length;

   }
}

startAddingIdDisributerForInstallers(){
  this.installerService
    .editIdDistriubutorForInstaller(this.installers)
    .subscribe((data) => {
    });
 }

}
