import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Training } from "src/app/_models/training";
import { DistributerTrainingsService } from "src/app/_services/distributer-trainings.service";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TrainingService } from "src/app/_services/training.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-trainings-distribiteur",
  templateUrl: "./trainings-distribiteur.component.html",
  styleUrls: ["./trainings-distribiteur.component.css"],
})
export class TrainingsDistribiteurComponent implements OnInit {
  valueChange = false;
  trainings;
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  title = "";
  date = "";
  dateExipred = "";
  dateConvertit: string;
  statut = null;
  testExpiration;
  dateTestExipration: any;
  recentResultsFiltred: any;
  lengthTraining
  lenghtRecentResultsFiltred
  constructor(
    private router: Router,
    private trainingService: TrainingService,
    private distributerTrainingsService: DistributerTrainingsService
  ) {}

  ngOnInit(): void {
    this.getTrainings();
  }

  getTrainings() {
    this.trainingService.getTrainingsByDistrubter().subscribe((data) => {
      this.trainings = data;
      this.lengthTraining=this.trainings.length
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getTrainings();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTrainings();
  }
  detailsTraining(id) {
    this.router.navigate(["distributeur/formations/details", id]);
  }

  shareTraining(id) {
    this.router.navigate(["distributeur/formations/partage", id]);
  }

  // filter
  search() {
    if (this.title != "") {
      this.trainings = this.recentResultsFiltred;
      this.trainings = this.trainings.filter((res) => {
        return res.training.title
          .toLocaleLowerCase()
          .match(this.title.toLocaleLowerCase());
      });
      this.page = 1;
      this.lenghtRecentResultsFiltred=this.trainings.length
    } else if (this.title == "") {
      this.recentResultsFiltred;
      this.trainings = this.recentResultsFiltred;
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

    }
  }

  searchBydate() {
    this.distributerTrainingsService
      .searchTrainingByDate(this.date)
      .subscribe((e) => {
        if (this.date == undefined) {
          this.getTrainings();
        } else {
          this.trainings = e;
          this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

        }
      });
  }

  searchByStatut() {
    this.distributerTrainingsService
      .searchTrainingByStatut(this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }

  searchByDateExipration() {
    this.distributerTrainingsService
      .searchTrainingByDateExpiration(this.dateExipred)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }

  searchByDateStatut() {
    this.distributerTrainingsService
      .searchTrainingByDateAndStatut(this.date, this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }

  searchByDateExpirationTestAndStatut() {
    this.distributerTrainingsService
      .searchByDateExpirationTestAndStatut(this.dateExipred, this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }

  searchByDateExpirationTestAndDateExpirationTraining() {
    this.distributerTrainingsService
      .searchByDateExpirationTestAndDateExpirationTraining(
        this.dateExipred,
        this.date
      )
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }
  searchByDateAndStatutAndExpirationDate() {
    this.distributerTrainingsService
      .searchByDateAndStatutAndExpirationDate(
        this.date,
        this.statut,
        this.dateExipred
      )
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred = this.trainings;
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }

  searchAll() {
    if (this.statut == null && this.date == "" && this.dateExipred == "") {
      this.getTrainings();
      this.page = 1;
    } else if (
      this.statut != null &&
      this.date == "" &&
      this.dateExipred == ""
    ) {
      this.searchByStatut();
      this.page = 1;
    } else if (
      this.statut == null &&
      this.date != "" &&
      this.dateExipred == ""
    ) {
      this.searchBydate();
      this.page = 1;
    } else if (
      this.statut == null &&
      this.date == "" &&
      this.dateExipred != ""
    ) {
      this.searchByDateExipration();
      this.page = 1;
    } else if (
      this.statut != null &&
      this.date != "" &&
      this.dateExipred == ""
    ) {
      this.searchByDateStatut();
      this.page = 1;
    } else if (
      this.statut != null &&
      this.date == "" &&
      this.dateExipred != ""
    ) {
      this.searchByDateExpirationTestAndStatut();
      this.page = 1;
    } else if (
      this.statut == null &&
      this.date != "" &&
      this.dateExipred != ""
    ) {
      this.searchByDateExpirationTestAndDateExpirationTraining();
      this.page = 1;
    } else {
      this.searchByDateAndStatutAndExpirationDate();
      this.page = 1;
    }
  }

  cancelFilter() {
    this.title = "";
    this.date = "";
    this.dateExipred = "";
    this.statut = null;
    this.getTrainings();
  }
}
