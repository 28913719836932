<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">
            {{ "questionaire_title" | translate }}
          </h1>
          <h3 class="h3 mb-0 ls-tight" style="padding-top: 2%">
            {{ "questionaire_pass_test_card1_subtitle1" | translate }} :
            {{ titleTraining }}
          </h3>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1"></div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div>
      <h5 class="mb-0" style="margin-left: 1.1%">
        {{ "questionaire_edit_card_title" | translate }}
      </h5>
    </div>

    <div>
      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="form">
            <div class="form-row">
              <div class="form-group col-8">
                <label
                  >{{ "questionaire_edit_card_field1" | translate }} *</label
                >

                <input
                  type="text"
                  formControlName="nom"
                  [(ngModel)]="test.nom"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.nom.errors }"
                />
                <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                  <div *ngIf="f.nom.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>
                </div>
              </div>
              <!-- <div class="form-group col-4">
                <label
                  >{{ "questionaire_date_expiration_test" | translate }}*</label
                >
                <input
                  type="date"
                  formControlName="dateFin"
                  min="{{ d1 }}"
                  [(ngModel)]="date"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.dateFin.errors }"
                />
                <div
                  *ngIf="submitted && f.dateFin.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.dateFin.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>
                </div>
              </div> -->
              <div class="form-group col-4">
                <label
                  >{{ "questionaire_edit_card_field3" | translate }} *</label
                >
                <input
                  type="number"
                  min-="1"
                  formControlName="temps"
                  [(ngModel)]="test.temps"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.temps.errors }"
                />
                <div
                  *ngIf="submitted && f.temps.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.temps.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <label
                  >{{
                    "formation_card_add_subtitle_3_video3" | translate
                  }}
                  *</label
                >
                <input
                  type="text"
                  formControlName="titre"
                  [(ngModel)]="test.titre"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.titre.errors }"
                />
                <div
                  *ngIf="submitted && f.titre.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.titre.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group col-2">
                <div class="form-group">
                  <label
                    >{{ "questionaire_scrole_reussite" | translate }} *</label
                  >
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    [(ngModel)]="test.score"
                    formControlName="score"
                    [ngClass]="{ 'is-invalid': submitted && f.score.errors }"
                  >
                    <option value="50">50%</option>
                    <option value="60">60%</option>
                    <option value="70">70%</option>
                    <option value="80">80%</option>
                    <option value="90">90%</option>
                    <option value="100">100%</option>

                  </select>
                  <div
                    *ngIf="submitted && f.score.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.score.errors.required">
                      {{ "shared_2_error" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div formArrayName="questions">
                <button
                  *ngIf="voirQuestionPrecedant"
                  class="btn btn-secondary"
                  type="button"
                  style="float: right"
                  (click)="addQuestion()"
                  [disabled]="form.get('questions').invalid"
                >
                  <i class="bi bi-plus"></i>{{ "btn_4" | translate }}
                </button>
                <button
                  *ngIf="!voirQuestionPrecedant"
                  class="btn btn-secondary"
                  type="button"
                  style="float: right"
                  (click)="addQuestion2()"
                  [disabled]="form.get('questions').invalid"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Ajouter une question"
                >
                  <i class="bi bi-plus"></i
                  >{{ "questionaire_add_question" | translate }}
                </button>
                <br />
                <br />
                <ng-template
                  ngFor
                  let-pet
                  let-petIndex="index"
                  [ngForOf]="getQuestions().controls"
                >
                  <div class="card-0">
                    <div [formGroupName]="petIndex" class="pet">
                      <div>
                        <label
                          for="name_{{ pet.value.id }}"
                          class="field__label"
                          style="margin-left: 0.5%"
                        >
                          {{ "Question" | translate }}
                        </label>
                        <div>
                          <div class="row">
                            <div class="col-8 ml-2">
                              <input
                                [attr.disabled]="
                                  !pet.value.selected ? '' : null
                                "
                                type="text"
                                id="name_{{ pet.value.id }}"
                                formControlName="question"
                                required
                                autofocus
                                size="20"
                                placeholder="{{
                                  'questionaire_add_question_texte' | translate
                                }}"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && pet.get('question').invalid
                                }"
                              />

                              <div
                                *ngIf="
                                  submitted &&
                                  pet.get('question').errors?.required
                                "
                                class="invalid-feedback"
                              >
                                {{ "shared_2_error" | translate }}
                              </div>
                             <br>
                            
                              <img *ngIf="pet.value.filename && pet.value.filename.indexOf('undefined') == -1 && pet.value.filename.length > 30 " src= "{{pet.value.filename}}"  width="315" height="200">
                              <button *ngIf="pet.value.filename && pet.value.filename.indexOf('undefined') == -1 &&  pet.value.filename.length > 30 "
                              (click)="delelePhoto(pet)"
                              type="button"
                              class="btn btn-sm btn-square btn-neutral text-danger-hover"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="{{ 'btn_hover_8' | translate }}"
                              style="margin-left: 2%;"
                            >
                              <i class="bi bi-trash"></i></button>
                              <ng-template #content let-modal>
                                  <div class="modal-header">
                                    <h4
                                      class="modal-title"
                                      id="modal-basic-title"
                                    >
                                      {{
                                        "popup_confiramtion_suppression"
                                          | translate
                                      }}
                                    </h4>
                                    <button
                                      type="button"
                                      class="close"
                                      aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="form-group">
                                      <label
                                        >Êtes-vous sûr(e) de vouloir
                                        {{ "btn_hover_8" | translate }} cette
                                        question ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-dark"
                                      (click)="removeQuestion(pet)"
                                    >
                                      {{ "btn_hover_8" | translate }}
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-outline-dark1"
                                      (click)="modal.close('Save click')"
                                    >
                                      {{ "btn_2" | translate }}
                                    </button>
                                  </div>
                                </ng-template>
                            </div>
                            <div class="col-2">
                              <button
                                (click)="open(content)"
                                type="button"
                                class="btn btn-sm btn-square btn-neutral text-danger-hover"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="{{ 'btn_hover_8' | translate }}"
                                style="margin-right: 2%"
                              >
                                <i class="bi bi-trash"></i>
                                <ng-template #content let-modal>
                                  <div class="modal-header">
                                    <h4
                                      class="modal-title"
                                      id="modal-basic-title"
                                    >
                                      {{
                                        "popup_confiramtion_suppression"
                                          | translate
                                      }}
                                    </h4>
                                    <button
                                      type="button"
                                      class="close"
                                      aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="form-group">
                                      <label
                                        >Êtes-vous sûr(e) de vouloir
                                        {{ "btn_hover_8" | translate }} cette
                                        question ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-dark"
                                      (click)="removeQuestion(pet)"
                                    >
                                      {{ "btn_hover_8" | translate }}
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-outline-dark1"
                                      (click)="modal.close('Save click')"
                                    >
                                      {{ "btn_2" | translate }}
                                    </button>
                                  </div>
                                </ng-template>
                              </button>
                              <span style="margin-left: 1%;" class="btn btn-file btn-file"> <i class="bi bi-file-image"></i>
                                <input type="file" (change)="addImg($event,pet.value.id,pet)"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                multiple>
                              </span>
                            </div>
                          </div>

                          <br />

                          <div formArrayName="choix" class="nicknames">
                            <button
                              *ngIf="pet.value.selected === false"
                              type="button"
                              (click)="addChoix(pet)"
                              style="
                                float: right;
                                margin-bottom: 1%;
                                margin-left: 1%;
                              "
                              class="btn btn-primary"
                              [disabled]="pet.get('question').invalid"
                            >
                              {{ "btn_5" | translate }}
                            </button>

                            <button
                              *ngIf="pet.value.selected == true"
                              type="button"
                              (click)="addChoix2(pet)"
                              style="float: right; margin-bottom: 1%"
                              class="btn btn-primary"
                              [disabled]="pet.get('question').invalid"
                            >
                              + {{ "btn_6" | translate }}
                            </button>

                            <div class="row">
                              <ng-template
                                ngFor
                                let-nickname
                                let-nicknameIndex="index"
                                [ngForOf]="getChoix(pet).controls"
                              >
                                <div
                                  [formGroupName]="nicknameIndex"
                                  class="nicknames__item col-6"
                                  style="padding-bottom: 10px !important"
                                >
                                  <label>
                                    {{
                                      "questionaire_edit_card_question_card_field2"
                                        | translate
                                    }}
                                  </label>
                                  <input
                                    type="text"
                                    formControlName="text"
                                    required
                                    autofocus
                                    size="20"
                                    placeholder="{{
                                      'questionaire_add_choix_texte' | translate
                                    }}"
                                    class="form-control"
                                    [ngClass]="{
                                      'is-invalid':
                                        submitted &&
                                        nickname.get('text').invalid
                                    }"
                                  />

                                  <div
                                    *ngIf="
                                      submitted &&
                                      nickname.get('text').errors?.required
                                    "
                                    class="invalid-feedback"
                                  >
                                    {{ "shared_2_error" | translate }}
                                  </div>
                                </div>
                                <div class="col" style="padding-top: 9px">
                                  <br />
                                  <button
                                    (click)="removeChoix(pet, nickname)"
                                    type="button"
                                    class="btn btn-sm btn-square btn-neutral text-danger-hover"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="{{ 'btn_hover_8' | translate }}"
                                    style="margin-right: 2%"
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>

                                  <ui-switch
                                    *ngIf="nickname.value.selected == true"
                                    (change)="changeSelected(nickname)"
                                    [checked]="true"
                                    size="small"
                                  ></ui-switch>
                                  <ui-switch
                                    *ngIf="nickname.value.selected == false"
                                    (change)="changeSelected(nickname)"
                                    [checked]="false"
                                    size="small"
                                  ></ui-switch>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <main class="py-66 bg-surface-secondary">
      <div class="container-fluid">
        <div style="float: right">
          <button class="btn btn-secondary mr-1" (click)="onSubmit()">
            {{ "btn_7" | translate }}
          </button>
          <button
            routerLink="/admin/questionnaires"
            class="btn btn-primary"
            type="reset"
          >
            {{ "btn_2" | translate }}
          </button>
        </div>
      </div>
    </main>
  </div>
</main>
