import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TrainingService } from "src/app/_services/training.service";
import { InstallerService } from "src/app/_services/installer.service";
import { Ng2IzitoastService } from "ng2-izitoast";
import { DistributerTrainingsService } from "src/app/_services/distributer-trainings.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-share-training-distributer",
  templateUrl: "./share-training-distributer.component.html",
  styleUrls: ["./share-training-distributer.component.css"],
})
export class ShareTrainingDistributerComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [1, 6, 9, 12];
  id;
  training;
  installers: any;
  toutesInstallers: any;
  toutesDistributers: any;
  registerForm: FormGroup;
  emailIns: string;
  selectedAllInsatllers;
  deselectedAllInsatllers;
  distributers: any;
  toutesInstallersBydistributeur: any;
  tests = [];
  titleTraining: any;
  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private InstallerService: InstallerService,
    public iziToast: Ng2IzitoastService,
    private distributerServiceTrainings: DistributerTrainingsService
  ) {}

  ngOnInit(): void {
    let i = 0;
    this.id = this.route.snapshot.params["id"];
    this.distributerServiceTrainings
      .getTrainingFromDistributerTrainings(this.id)
      .subscribe(async (data) => {
        this.training = data;

        this.trainingService
          .getTrainingById(this.training)
          .subscribe(async (data) => {
            this.training = data;

            this.titleTraining = this.training.title;
            this.installers = this.training.installer;
            this.distributers = this.training.distributer;
            this.toutesInstallers =
              await this.InstallerService.getInstallers().toPromise();
              this.toutesInstallers = this.toutesInstallers["data"]
            this.toutesInstallersBydistributeur =
              await this.InstallerService.getInstallersByDistrubuters().toPromise();
            for (let i of this.toutesInstallers.keys()) {
              let index = this.installers.findIndex(
                (m) => Number(m) == this.toutesInstallers[i].id
              );
              this.toutesInstallers[i].selected = index != -1;
            }

            for (let i of this.toutesInstallers) {
              for (let i2 of this.toutesInstallersBydistributeur) {
                if (i.id == i2.id) {
                  this.tests.push(i);
                }
              }
            }

            for (let t of this.tests) {
              if (t.selected == true) {
                i = i + 1;
              } else i = i - 1;
            }
            if (i == this.tests.length) {
              this.selectedAllInsatllers = false;
              this.deselectedAllInsatllers = true;
            } else {
              this.selectedAllInsatllers = true;
              this.deselectedAllInsatllers = false;
            }

            this.registerForm = this.formBuilder.group(
              {
                installer: [""],
              },
              {}
            );
          });
      });
  }

  shareTraining() {
    let resultsI = [];
    this.registerForm.value.installerdata = []

    for (let m of this.toutesInstallers) {
      if (m.selected == true) {
        resultsI.push(m.id);
        this.registerForm.value.installer = resultsI;
    	this.registerForm.value.installerdata.push(m)

      }
    }
    if (this.registerForm.value.installer.length == 0) {
      this.registerForm.value.installer = ["000000000000000000000000"];
    }
    this.registerForm.value.distributer = this.distributers;
    this.trainingService
      .shareTrainingWithUsers(this.training._id, this.registerForm.value)
      .subscribe((data) => {

        this.iziToast.show({
          message: this.translate.instant("shared_4_5_succes"),
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.router.navigate(["/distributeur/formations"]);
      });
  }

  searchInListeInstallateurs() {
    if (this.emailIns != "") {
      this.toutesInstallers = this.toutesInstallers.filter((res) => {
        return res.email
          .toLocaleLowerCase()
          .match(this.emailIns.toLocaleLowerCase());
      });
    } else if (this.emailIns == "") {
      this.ngOnInit();
    }
  }

  cancel() {
    this.router.navigate(["distributeur/formations"]);
  }

  selectedAllInstallers() {
    this.selectedAllInsatllers = false;
    this.deselectedAllInsatllers = true;
    for (let t of this.tests) {
      if (t.selected == false) {
        t.selected = true;
      }
    }
  }

  deSelectedAllInstallers() {
    this.selectedAllInsatllers = true;
    this.deselectedAllInsatllers = false;
    for (let t of this.tests) {
      if (t.selected == true) {
        t.selected = false;
      }
    }
  }
}
