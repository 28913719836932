import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  


  constructor(
    ) { 
      

    }

  ngOnInit() {
    localStorage.clear();
    document.location.href = 'https://fineo.clevermint-dev.be'
    return false
  }  
}
