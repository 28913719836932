<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "formation_title" | translate }}</h1>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1">
            <a
              (click)="createTraining()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
              <span class="pe-2">
                <i class="bi bi-plus"></i>
              </span>
              <span style="color: #fff">{{ "btn_1" | translate }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a
            (click)="getTrainings()"
            [ngClass]="{ current0: trainingsNonArchivees }"
            class="nav-link font-regular"
            >{{ "formation_subtitle_1" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="getTrainingsArchivees()"
            [ngClass]="{ current0: trainingsArchivees }"
            class="nav-link font-regular"
          >
            {{ "formation_subtitle_2" | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ "formation_card_title_1" | translate }}</h5>
      </div>
      <div
        *ngIf="trainingsNonArchivees"
        style="
          padding-top: 2%;
          display: flex;
          justify-content: space-around;
          padding-left: 7%;
        "
      >
        <div class="col-xl-12 col-lg-12 col-md-12 pl-2 row">
          <div class="col-12 col-md-4 col-xl-3 col-lg-4 mb-3">
            <label>{{ "formation_filter_title" | translate }} :</label>

            <input
              id="search-input"
              type="search"
              #myInput
              class="form-control"
              [(ngModel)]="title"
              placeholder="{{'formation_filter_title' | translate}}"
              (input)="search()"
            />
          </div>
          <div class="col-12 col-md-4 col-xl-3 col-lg-4 mb-3">
            <label>{{ "formation_filter_date_creation" | translate }} :</label>

            <input
              id="search-input"
              type="date"
              class="form-control"
              [(ngModel)]="date"
            />
          </div>

          <div class="col-12 col-md-4 col-xl-3 col-lg-4 mb-3">
            <label>{{ "formation_filter_status" | translate }} :</label>

            <select
              style="padding: 0.35rem 3.75rem 0.75rem 1.25rem"
              class="form-select"
              [(ngModel)]="statut"
            >
              <option
                class="placeHolderOption"
                [ngValue]="null"
                [disabled]="true"
              >
                {{ "formation_filter_status" | translate }}
              </option>
              <option value="Active">
              {{ "formation_status_content_1" | translate }}
              </option>
              <option value="Inactive">
                {{ "formation_status_content_2" | translate }}
              </option>
            </select>
          </div>
          <div class="col-3 col-sm-2 col-md-3 col-xl-1 col-lg-2 mr-4 mt-8">
            <div class="mx-n1">
              <a
                (click)="searchStatutAndDate()"
                class="btn d-inline-flex btn-sm btn-primary mx-1"
                style="padding-top: 11px"
              >
                <span class="pe-2">
                  <i class="bi bi-search"></i>
                </span>
                <span style="color: #fff">{{ "btn_hover_9" | translate }}</span>
              </a>
            </div>
          </div>
          <div class="col-3 col-sm-2 col-xl-1 col-lg-3 col-md-3 mt-7">
            <div class="mx-n1">
              <a
                (click)="cancelFilter()"
                class="btn d-inline-flex btn-sm mx-1"
                style="padding-top: 11px; text-decoration: underline"
              >
                <span>{{ "formation_filter_btn" | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngIf="trainingsNonArchivees && lenghtRecentResultsFiltred > 0"
        class="table-responsive"
      >
        <table *ngIf="trainings" class="table table-hover table-nowrap">
          <thead
            style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
          >
            <tr>
              <th class="th" scope="col">
                {{ "table_column_12" | translate }}
              </th>

              <th class="th" scope="col">{{ "table_column_7" | translate }}</th>
              <th class="th" scope="col">{{ "table_column_8" | translate }}</th>
              <th class="th" scope="col">{{ "table_column_9" | translate }}</th>
              <th class="th" scope="col">{{ "shared_1" | translate }}</th>
              <th class="th" scope="col">
                {{ "table_column_10" | translate }}
              </th>
              <th class="th" scope="col">
                {{ "table_column_11" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let i of trainings.reverse()
                  | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };
                let k = index
              "
            >
              <td>
                <p class="trunct">{{ i.title }}</p>
              </td>
              <td>{{ i.dateStart | date : "dd/MM/yyyy HH:mm" }}</td>
              <td>{{ i.dateEnd | date : "dd/MM/yyyy 00:00" }}</td>

              <td *ngIf="i.statut == 'Active'">
                <span class="badge badge-lg badge-dot">
                  <i class="bg-success"></i
                  >{{ "formation_status_content_1" | translate }}
                </span>
              </td>

              <td *ngIf="i.statut == 'Inactive'">
                <span class="badge badge-lg badge-dot">
                  <i class="bg-danger"></i
                  >{{ "formation_status_content_2" | translate }}
                </span>
              </td>

              <td>
                <a
                  (click)="detailsTraining(i._id)"
                  class="btn btn-sm btn-neutral"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_1' | translate }}"
                  ><i class="bi bi-eye"></i
                ></a>
                <button
                  
                  (click)="shareTraining(i._id)"
                  class="btn btn-sm btn-neutral"
                >
                  <i
                    class="bi bi-arrow-right-square-fill"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="{{ 'btn_hover_2' | translate }}"
                  ></i>
                </button>

                <button
                  [disabled]="i.etat"
                  (click)="updateTraining(i._id)"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="{{ 'btn_hover_3' | translate }}"
                  ></i>
                </button>

                <button
                  [disabled]="i.etat"
                  (click)="open(content)"
                  type="button"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_4' | translate }}"
                >
                  <i class="bi bi-archive"></i>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">
                        {{ "popup_confiramtion_archivage" | translate }} :
                      </h4>
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <label
                          >{{ 'popup_confiramtion_archiver_formation' | translate  }}
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-dark"
                        (click)="deleteTraining(i)"
                      >
                        {{ 'btn_10' | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-dark1"
                        (click)="modal.close('Save click')"
                      >
                        {{ 'btn_2' | translate }}
                      </button>
                    </div>
                  </ng-template>
                </button>
              </td>
              <td>
                <button
                  *ngIf="i.createExamen == false"
                  style="margin-left: 10%"
                  (click)="updateCreateExamenInTrue(i)"
                  class="btn btn-sm btn-neutral"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{'btn_hover_5' | translate}}"
                >
                  <i class="bi bi-card-checklist"></i>
                </button>
                <button
                  *ngIf="i.createExamen == true"
                  [disabled]="i.etat ? true : null"
                  style="margin-left: 10%"
                  (click)="displayQuestionnaire(i)"
                  class="btn btn-sm btn-neutral"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_1' | translate }}"
                >
                  <i class="bi bi-eye"></i>
                </button>
                <button
                  *ngIf="i.createExamen == true"
                  [disabled]="i.etat ? true : null"
                  (click)="open(content)"
                  class="btn btn-sm btn-neutral"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_8' | translate }} {{'questionaire'| translate}}"
                >
                  <i class="bi bi-trash"></i>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">
                        {{ "popup_confiramtion_suppression" | translate }}
                      </h4>
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <label
                          >{{
                            "popup_confiramtion_suppression_formation"
                              | translate
                          }}
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-dark"
                        (click)="deleteExamenInListTrainings(i)"
                      >
                        {{ "btn_hover_8" | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-dark1"
                        (click)="modal.close('Save click')"
                      >
                        {{ "btn_2" | translate }}
                      </button>
                    </div>
                  </ng-template>
                </button>
              </td>
              <td *ngIf="i.etat">
                <ui-switch
                  [checked]="true"
                  size="small"
                  (click)="changeEtat(i)"
                >
                </ui-switch>
              </td>
              <td *ngIf="!i.etat">
                <ui-switch
                  *ngIf="i.createExamen == true"
                  [disabled]="i.createExamen == false"
                  [checked]="false"
                  size="small"
                  (click)="changeEtat(i)"
                ></ui-switch>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="lenghtRecentResultsFiltred > 10"
          class="card-footer border-0 py-5"
        >
          <div class="d-flex justify-content-center">
            <pagination-controls
              class="my-pagination"
              previousLabel="{{ 'shared_5_pagination' | translate }}"
              nextLabel="{{ 'shared_6_pagination' | translate }}"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
        </div>

        <!-- <div class="card-footer border-0 py-5">
                </div> -->
      </div>
      <div
        *ngIf="trainingsNonArchivees && lenghtRecentResultsFiltred == 0"
        class="table-responsive"
        style="text-align: center"
      >
        <p style="padding-bottom: 40px; padding-top: 15px">
          {{'formation_error_pagination' | translate}}
        </p>
      </div>

      <div
        *ngIf="!trainingsNonArchivees && lenghtTrainingsArchives > 0"
        class="table-responsive"
      >
        <table class="table table-hover table-nowrap">
          <thead
            style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
          >
            <tr>
              <th class="th" scope="col">
                {{ "table_column_12" | translate }}
              </th>

              <th class="th" scope="col">{{ "table_column_7" | translate }}</th>
              <th class="th" scope="col">{{ "table_column_8" | translate }}</th>
              <th class="th" scope="col">{{ "table_column_9" | translate }}</th>
              <th class="th" scope="col">{{ "shared_1" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let i of trainingsArchives.reverse()
                  | paginate
                    : {
                        itemsPerPage: tableSize2,
                        currentPage: page2,
                        totalItems: count2
                      };
                let k = index
              "
            >
              <td>
                <p class="trunct">{{ i.title }}</p>
              </td>
              <td>{{ i.dateStart | date : "dd/MM/yyyy HH:mm" }}</td>
              <td>{{ i.dateEnd | date : "dd/MM/yyyy 00:00" }}</td>

              <td *ngIf="i.statut == 'Expirée'">
                <span class="badge badge-lg badge-dot">
                  <i class="bg-dark"></i>{{'certficat_expire' | translate}}
                </span>
              </td>

              <td *ngIf="i.statut == 'Archivée'">
                <span class="badge badge-lg badge-dot">
                  <i class="bg-secondary"></i>{{'certficat_archivé' | translate}}
                </span>
              </td>

              <td>
                <button
                  (click)="open(content)"
                  type="button"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{ 'btn_hover_8' | translate }}"
                >
                  <i class="bi bi-trash"></i>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">
                        {{ "popup_confiramtion_suppression" | translate }}
                      </h4>
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <label
                          >{{'popup_confiramtion_supprimer_formation' | translate }}
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-dark"
                        (click)="deleteTrainingArchivage(i)"
                      >
                        {{ "btn_hover_8" | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-dark1"
                        (click)="modal.close('Save click')"
                      >
                        {{ "btn_2" | translate }}
                      </button>
                    </div>
                  </ng-template>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="lenghtTrainingsArchives > 10"
          class="card-footer border-0 py-5"
        >
          <div class="d-flex justify-content-center">
            <pagination-controls
              class="my-pagination"
              previousLabel="{{ 'shared_5_pagination' | translate }}"
              nextLabel="{{ 'shared_6_pagination' | translate }}"
              (pageChange)="onTableDataChangeArchives($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
      <div
        *ngIf="!trainingsNonArchivees && lenghtTrainingsArchives == 0"
        class="table-responsive"
        style="text-align: center"
      >
        <p style="padding-bottom: 40px; padding-top: 15px">
          {{'formation_error_pagination' | translate}}
        </p>
      </div>
    </div>
  </div>
</main>
