import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Ng2IzitoastService } from "ng2-izitoast";
import { User } from "src/app/_models/user";
import { DistributerService } from "src/app/_services/distributer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-update-distributer",
  templateUrl: "./update-distributer.component.html",
  styleUrls: ["./update-distributer.component.css"],
})
export class UpdateDistributerComponent implements OnInit {
  id: string;
  distributer: User = new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  d;

  constructor(
    private distributerService: DistributerService,
    private router: Router,
    private route: ActivatedRoute,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];

    (this.d = this.distributerService
      .getDistributerById(this.id)
      .subscribe(async (data) => {
        this.distributer = data;
      })),
      (this.registerForm = this.formBuilder.group(
        {
          firstName: ["", [Validators.required, Validators.minLength(3)]],
          lastName: ["", [Validators.required, Validators.minLength(3)]],
          email: ["", [Validators.required, Validators.email]],
          company: ["", [Validators.required, , Validators.maxLength(30)]],
        },
        {}
      ));
  }
  get f() {
    return this.registerForm.controls;
  }
  update() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.registerForm.value.password = "";
    this.distributerService
      .EditDistributer(this.id, this.registerForm.value)
      .subscribe((data) => {
        this.iziToast.show({
          message: this.translate.instant("shared_4_1_succes"),
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      });
  }
  goToList() {
    this.router.navigate(["/admin/distributeurs"]);
  }
  onSubmit() {
    this.update();
  }
}
