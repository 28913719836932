import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ToastrService } from "ngx-toastr";
import { Test } from "src/app/_models/test";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TrainingService } from "src/app/_services/training.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { PhotoQuestionService } from "src/app/_services/photo-question.service";

@Component({
  selector: "app-update-test",
  templateUrl: "./update-test.component.html",
  styleUrls: ["./update-test.component.css"],
})
export class UpdateTestComponent implements OnInit {
  private;
  checkFileInquestion
  upload = `${environment.uploadUrl}`;
  voirQuestionPrecedant = true;
  resultsNewPhotosQuestions = [];

  public form: FormGroup;
  etat = false;
  id;
  d;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  d0;
  d1: string;
  tests = [];

  dateAuj = new Date();
  date: any;
  test: Test = new Test();
  training;
  titleTraining;
  results: any;
  reponsesQuestion = [];
  dateExiperdCertif: string;
  closeResult: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private examen: ExamenServiceService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public iziToast: Ng2IzitoastService,
    private trainingService: TrainingService,
    private modalService: NgbModal,
    private photoQuestion: PhotoQuestionService,
    private cdRef:ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      nom: ["", Validators.required], // titre
      // dateFin: ["", Validators.required],
      temps: ["", Validators.required],
      titre: ["", Validators.required], // decription
      score: ["", Validators.required],
      questions: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.d = this.examen.getTestById(this.id).subscribe(async (data) => {
      this.test = data;
      this.trainingService
        .getTrainingById(this.test.training)
        .subscribe(async (data) => {
          this.training = data;
          this.titleTraining = this.training.title;
        });
      // this.date = this.test.dateFin.toString().split("T")[0];
      this.results = data.questions;
      for (let q of this.results) {
        this.reponsesQuestion.push(q.choix);
      }

      this.d0 = this.dateAuj.toISOString();
      this.d1 = this.dateAuj.toISOString().substr(0, 10);
    });
    this.getQuestions();
  }
  get f() {
    return this.form.controls;
  }

  public addChoix2(question: FormControl): void {
    if(this.checkFileInquestion !=undefined)
    {
    if(this.checkFileInquestion.question == question.value.id)
    {
      if(this.checkFileInquestion.photo == "")
      {
      question.get("filename").setValue('')
   
    }
    }
    }
    var choix = this.getChoix(question); // Critical: casts as FormArray.

    choix.push(
      this.formBuilder.group({
        id: [Date.now()],
        text: "",
        selected: false,
      })
    );
    this.cdRef.detectChanges();
  }

  public addChoix(question: FormControl): void {
    if(this.checkFileInquestion !=undefined)
    {
    if(this.checkFileInquestion.question == question.value.id)
    {
      if(this.checkFileInquestion.photo == "")
      {
      question.get("filename").setValue('')
       }
    }
    }
    var choix = this.getChoix(question); // Critical: casts as FormArray.
    for (let r of this.results) {
      if (r.question === question.value.question) {
        if (r.selected == false) {
          r.selected = true;

          for (let i of r.choix) {
            choix.push(
              this.formBuilder.group({
                id: [Date.now()],
                text: i.text,
                selected: i.selected,
              })
            );
          }
          question.get("selected").setValue(true);
          this.etat = true;
        }
      }
    }
  
  }

  public addQuestion2(): void {
    var questions = this.getQuestions();
    let reponsesJustes = [];
    let taille = this.form.value.questions.length;
    let endQuestion = undefined;

    let question = this.form.value.questions[taille - 1].question;
    for (let t of this.test.questions) {
      if (t.question === question) endQuestion = t;
    }
    if (endQuestion != undefined) {
      this.etat = true;
      questions.push(
        this.formBuilder.group({
          id: [Date.now()],
          question: ["", Validators.required],
          choix: this.formBuilder.array([]),
          selected: true,
        })
      );
    } else {
      if (this.form.value.questions[taille - 1] == undefined) {
        questions.push(
          this.formBuilder.group({
            id: [Date.now()],
            question: ["", Validators.required],
            selected: false,
            choix: this.formBuilder.array([]),
          })
        );
      }
      if (this.form.value.questions[taille - 1].choix.length >= 2) {
        for (let c of this.form.value.questions[taille - 1].choix) {
          if (c.selected == true) {
            reponsesJustes.push(c);
            questions.push(
              this.formBuilder.group({
                id: [Date.now()],
                question: ["", Validators.required],
                verif: "verif",
                choix: this.formBuilder.array([]),
                selected: true,
              })
            );
            break;
          }
        }
        if (reponsesJustes.length == 0) {
          this.iziToast.show({
            message: this.translate.instant("shared_14_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        }
      } else
        this.iziToast.show({
          message: this.translate.instant("shared_13_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
    }
    this.cdRef.detectChanges();
  }

  public addQuestion(): void {
    this.voirQuestionPrecedant = false;
    var questions = this.getQuestions();

    for (let i = 0; i <= this.results.length; i++) {
      if(this.results[i]){
      questions.push(
        this.formBuilder.group({
          id: this.results[i].id,
          question: this.results[i].question,
          selected: this.results[i].selected,
          choix: this.formBuilder.array([]),
          filename: this.upload + this.results[i].filename,
        })
      );
      }
    }
  }

  public getChoix(question: FormControl): FormArray {
    return question.get("choix") as FormArray;
  }

  public getQuestions(): FormArray {
    return this.form.get("questions") as FormArray;
  }

  public removeChoix(question: FormControl, nickname: FormControl): void {
    this.removeFromCollection(this.getChoix(question), nickname);
  }

  public removeQuestion(question: FormControl): void {
    this.removeFromCollection(this.getQuestions(), question);
    this.modalService.dismissAll();
  }

  private removeFromCollection(collection: FormArray, item: FormControl): void {
    collection.removeAt(collection.controls.indexOf(item));
  }

  changeSelected(test) {
    if (test.value.selected == false) {
      test.value.selected = true;
    } else test.value.selected = false;
  }

  update() {
    let results = [];
    this.submitted = true;
    let taille;
    let reponsesJustes = [];
    if (this.form.invalid) {
      return;
    }
    if(this.voirQuestionPrecedant == true)
    this.form.value.questions =this.test.questions

    if (this.form.value.questions.length <= 2) {
      this.iziToast.show({
        message: this.translate.instant("shared_12_error"),
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        overlay: true,
        overlayClose: true,
      });
    } else {
    if (this.form.value.questions.length <= this.results.length) {
      for (let r of this.results) {
        for (let q of this.form.value.questions) {
          if (q.question == r.question) {
            if (q.choix[0] == undefined) {
              q.choix = r.choix;
              q.answer = r.answer;
            }
          }
        }
      }
      for (let q of this.form.value.questions) {
        for (let c of q.choix) {
          if (c.selected == true) {
            results.push(c.text);
          }
        }
        q.answer = results;
        q.selected = false;
        results = [];
      }

      let taille = this.form.value.questions.length;

      if (this.form.value.questions[length - 1] == undefined) {
        //let date1 = new Date(this.form.value.dateFin);
        let date1 = Date.now();
        for (let question of this.form.value.questions) {
          question.filename = "";
        }
        if (date1>0) {
          this.examen.Edit(this.id, this.form.value).subscribe((data) => {
            this.iziToast.show({
              message: this.translate.instant("shared_4_1_succes"),
              messageColor: "#386641",
              progressBarColor: "#6a994e",
              icon: "bi-check-circle",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#dde5b6",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
            });
            this.goToList();
          });
          let questionsPhotoListFinal = [];
          let indices = [];
          let listSendedInBack = [];
          for (let questionIndice of this.form.value.questions) {
            indices.push(questionIndice.id);
          }
          for (let indice of indices) {
            let endPhotoQuestion;
            for (let r of this.resultsNewPhotosQuestions) {
              if (indice == r.question) {
                endPhotoQuestion = r;
              }
            }
            questionsPhotoListFinal.push(endPhotoQuestion);
          }

          for (let final of questionsPhotoListFinal) {
            if (final != undefined) {
              listSendedInBack.push(final);
            }
          }

          for (let r of listSendedInBack) {
            const form = new FormData();
            form.set("photo", r.photo);

            this.photoQuestion
              .editPhtoQuestion(form, this.id, r.question)
              .subscribe((data) => {});
          }
        } else
          this.iziToast.show({
            message: this.translate.instant("shared_19_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
      } else if (this.form.value.questions[taille - 1].choix.length < 2) {
        this.iziToast.show({
          message: this.translate.instant("shared_13_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      } else if (this.form.value.questions[taille - 1].choix.length >= 2) {
        for (let c of this.form.value.questions[taille - 1].choix) {
          if (c.selected == true) {
            reponsesJustes.push(c);
          }
        }
        if (reponsesJustes.length == 0) {
          this.iziToast.show({
            message: this.translate.instant("shared_14_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        } else {
          for (let q of this.form.value.questions) {
            for (let c of q.choix) {
              if (c.selected == true) {
                results.push(c.text);
              }
            }
            q.answer = results;
            q.selected = false;
            results = [];
          }

          let testChoixJustesFinales = 0;
          let questionNotsucces;
          for (let q of this.form.value.questions) {
            if (q.choix.length < 2 || q.answer.length == 0) {
              questionNotsucces = q;
              testChoixJustesFinales = testChoixJustesFinales + 1;
              break;
            }
          }
          if (testChoixJustesFinales > 0) {
            questionNotsucces.selected = true;
            this.iziToast.show({
              message: this.translate.instant("shared_14_1_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 9000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          } else {
            //let date1 = new Date(this.form.value.dateFin);
            let date1 = Date.now();
            for (let question of this.form.value.questions) {
              question.filename = "";
            }
            // if (date1.getTime() > 0) {
              if (date1 > 0) {
              this.examen.Edit(this.id, this.form.value).subscribe((data) => {
                this.iziToast.show({
                  message: this.translate.instant("shared_4_1_succes"),
                  messageColor: "#386641",
                  progressBarColor: "#6a994e",
                  icon: "bi-check-circle",
                  imageWidth: 45,
                  position: "topRight",
                  timeout: 5000,
                  backgroundColor: "#dde5b6",
                  transitionIn: "flipInX",
                  transitionOut: "flipOutX",
                });
                this.goToList();
              });
              let questionsPhotoListFinal = [];
              let indices = [];
              let listSendedInBack = [];
              for (let questionIndice of this.form.value.questions) {
                indices.push(questionIndice.id);
              }
              for (let indice of indices) {
                let endPhotoQuestion;
                for (let r of this.resultsNewPhotosQuestions) {
                  if (indice == r.question) {
                    endPhotoQuestion = r;
                  }
                }
                questionsPhotoListFinal.push(endPhotoQuestion);
              }

              for (let final of questionsPhotoListFinal) {
                if (final != undefined) {
                  listSendedInBack.push(final);
                }
              }

              for (let r of listSendedInBack) {
                const form = new FormData();
                form.set("photo", r.photo);

                this.photoQuestion
                  .editPhtoQuestion(form, this.id, r.question)
                  .subscribe((data) => {});
              }
            } else
              this.iziToast.show({
                message: this.translate.instant("shared_19_error"),
                messageColor: "#800f2f",
                titleColor: "#800f2f",
                progressBarColor: "#c9184a",
                icon: "bi-exclamation-diamond",
                imageWidth: 45,
                position: "topRight",
                timeout: 5000,
                backgroundColor: "#ff8fa3",
                transitionIn: "flipInX",
                transitionOut: "flipOutX",
                overlay: true,
                overlayClose: true,
              });
          }
        }
      }
    }}
    if (this.form.value.questions.length > this.results.length) {
      let tailleListQuestion = this.form.value.questions.length;
      taille = this.form.value.questions[tailleListQuestion - 1].choix.length;
      if (taille < 2) {
        this.iziToast.show({
          message: this.translate.instant("shared_13_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      } else if (taille >= 2) {
        for (let c of this.form.value.questions[tailleListQuestion - 1].choix) {
          if (c.selected == true) {
            reponsesJustes.push(c);
          }
        }
        if (reponsesJustes.length == 0) {
          this.iziToast.show({
            message: this.translate.instant("shared_14_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        } else {
          for (let r of this.results) {
            for (let q of this.form.value.questions) {
              if (q.question == r.question) {
                if (q.choix[0] == undefined) {
                  q.choix = r.choix;
                  q.answer = r.answer;
                }
              }
            }
          }

          for (let q of this.form.value.questions) {
            for (let c of q.choix) {
              if (c.selected == true) {
                results.push(c.text);
              }
            }
            q.answer = results;
            q.selected = false;
            results = [];
          }
          //let date1 = new Date(this.form.value.dateFin);
          let date1 = Date.now();
          for (let question of this.form.value.questions) {
            question.filename = "";
          }
          //if (date1.getTime() > 0) {
            if (date1 > 0) {
            this.examen.Edit(this.id, this.form.value).subscribe((data) => {
              this.iziToast.show({
                message: this.translate.instant("shared_4_1_succes"),
                messageColor: "#386641",
                progressBarColor: "#6a994e",
                icon: "bi-check-circle",
                imageWidth: 45,
                position: "topRight",
                timeout: 5000,
                backgroundColor: "#dde5b6",
                transitionIn: "flipInX",
                transitionOut: "flipOutX",
              });
              this.goToList();
            });
            let questionsPhotoListFinal = [];
            let indices = [];
            let listSendedInBack = [];
            for (let questionIndice of this.form.value.questions) {
              indices.push(questionIndice.id);
            }
            for (let indice of indices) {
              let endPhotoQuestion;
              for (let r of this.resultsNewPhotosQuestions) {
                if (indice == r.question) {
                  endPhotoQuestion = r;
                }
              }
              questionsPhotoListFinal.push(endPhotoQuestion);
            }

            for (let final of questionsPhotoListFinal) {
              if (final != undefined) {
                listSendedInBack.push(final);
              }
            }

            for (let r of listSendedInBack) {
              const form = new FormData();
              form.set("photo", r.photo);

              this.photoQuestion
                .editPhtoQuestion(form, this.id, r.question)
                .subscribe((data) => {});
            }
          } else
            this.iziToast.show({
              message: this.translate.instant("shared_19_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
        }
      }
    }
    //}
  }
  goToList() {
    this.router.navigate(["/admin/questionnaires"]);
  }
  onSubmit() {
    this.update();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  addImg(event, question, Quest) {
    let photosAndOneQuestion;
    if (event.target.files[0] != undefined) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        const obj = event.target.result;
        Quest.value.filename = obj;
      };
      photosAndOneQuestion = {
        question: question,
        photo: event.target.files[0],
      };
    } else {
      photosAndOneQuestion = { question: question, photo: undefined };
      Quest.value.urlPhoto = "";
    }
    if(event.target.files[0].size < 1048576 || event.target.files[0] == undefined)
    this.resultsNewPhotosQuestions.push(photosAndOneQuestion);
    else
    this.iziToast.show({
      message: this.translate.instant("eroor_size_photo"),  // "eroor_size_photo"
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }

  delelePhoto(pet) {
    let photosAndOneQuestion;
    pet.value.filename = "";
    photosAndOneQuestion = { question: pet.value.id, photo: "12346789" };
    // const form = new FormData();
    //           form.set("photo", photosAndOneQuestion.photo);

              this.photoQuestion
                .deletePhtoQuestion(this.id, pet.value.id)
                .subscribe((data) => 
                {
                });
                for( let question of this.getQuestions().value)
                {
                   if(question.id === pet.value.id)
                   {
                    question.filename =""
                  }
                   
                }

                photosAndOneQuestion = { question: pet.value.id, photo: "" };
                this.checkFileInquestion=photosAndOneQuestion
              //this.getQuestions()
  }

  updateImageInNull(pet)
  {
    if(pet.value.filename == "")
    {
      pet.value.filename = ""
    }
  }
}
