import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-auhtorise',
  templateUrl: './auhtorise.component.html',
  styleUrls: ['./auhtorise.component.css']
})
export class AuhtoriseComponent implements OnInit {

  constructor(private autenticationService:AuthenticationService) { }

  ngOnInit(): void {
    this.autenticationService.getaccessauthroisation().subscribe((result) => {
      if(result && result[0]){
        document.location.href = result[0].auth_url;
        }
  }, (err) => {
    return false;
  });
  }

}
