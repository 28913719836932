<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "formation_title" | translate }}</h1>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1"></div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 *ngIf="fr" class="mb-0">Ajouter une nouvelle formation</h5>
        <h5 *ngIf="en" class="mb-0">Add new training</h5>
        <h5 *ngIf="nl" class="mb-0">Nieuwe training toevoegen</h5>
        <h5 *ngIf="de" class="mb-0">Neue Ausbildung hinzufügen</h5>
      </div>
      <div
        style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
        class="card-header"
      >
        <h5 style="color: #fff" class="mb-0">
          {{ "formation_card_add_subtitle_1" | translate }}
        </h5>
      </div>

      <div class="card-body">
        <button
          [ngClass]="{ current0: en }"
          class="but"
          style="cursor: default"
        >
          EN
        </button>
        <button
          [ngClass]="{ current0: fr }"
          class="but"
          style="cursor: default"
        >
          FR
        </button>

        <button
          [ngClass]="{ current0: de }"
          class="but"
          style="cursor: default"
        >
          DE
        </button>
        <button
          [ngClass]="{ current0: nl }"
          class="but"
          style="cursor: default"
        >
          NL
        </button>
      </div>
      <!-- <div
        style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
        class="card-header"
      >
        <h5 style="color: #fff" class="mb-0">
          {{ "formation_card_add_subtitle_2" | translate }}
        </h5>
      </div> -->
      <!-- <div class="card-body row no-gutter">
                           
	
                                <div class="col-2">  <input (click)="setTypeVideo()"  id="cb1" type="radio" name="citizenship" checked/>
                                    <label class="radio" >{{'formation_element_liste_1' | translate}}</label>
                                    </div>
                                <div class="col-3">  <input  (click)="setTypeSlider()"  id="cb1"  type="radio" name="citizenship" /> 
                                    <label class="radio" >{{'formation_element_liste_2' | translate}}</label>
                                </div>
                                <div class="col-3">  <input  (click)="setTypeArticles()"  id="cb1" type="radio" name="citizenship" />
                                    <label class="radio" >{{'formation_element_liste_3' | translate}}</label>

                                    </div>
                             
                            </div>   -->
      <div
        style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
        class="card-header"
      >
        <h5 style="color: #fff" class="mb-0">
          {{ "formation_card_add_subtitle_3" | translate }}
        </h5>
      </div>
      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="form-group col-8">
                <label
                  >{{ "questionaire_edit_card_field1" | translate }}
                  <strong>*</strong></label
                >
                <input
                  type="text"
                  formControlName="title"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                />
                <div
                  *ngIf="submitted && f.title.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.title.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>

                  <div *ngIf="f.title.errors.maxlength && fr">
                    Ce champ peut comporter 50 caractères maximum.
                  </div>
                  <div *ngIf="f.title.errors.maxlength && en">
                    This field can contain a maximum of 50 characters.
                  </div>
                  <div *ngIf="f.title.errors.maxlength && nl">
                    Dit veld kan maximaal 50 tekens bevatten.
                  </div>
                  <div *ngIf="f.title.errors.maxlength && de">
                    Dieses Feld kann maximal 50 Zeichen enthalten.
                  </div>
                </div>
              </div>
              <div class="form-group col-4">
                <label
                  >{{ "formation_card_add_subtitle_3_video2" | translate }}
                  <strong></strong
                ></label>
                <input
                  type="date"
                  formControlName="dateEnd"
                  min="{{ d1 }}"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <label>
                {{ "formation_card_add_subtitle_3_video3" | translate }}
                <strong>*</strong></label
              >

              <angular-editor
                formControlName="description"
                [config]="editorConfig"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
              ></angular-editor>

              <div
                *ngIf="submitted && f.description.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.description.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label *ngIf="typeVideo"
                >{{ "formation_card_add_subtitle_3_video4" | translate }}
                <strong>*</strong></label
              >
              <label *ngIf="typeSlider">{{
                "formation_card_add_subtitle_3_slider4" | translate
              }}</label>
              <label *ngIf="typeArticles">{{
                "formation_card_add_subtitle_3_article4" | translate
              }}</label>

              <div *ngIf="typeSlider" class="form-group">
                <table style="width: 100%" formArrayName="choix">
                  <tr>
                    <!-- <th >Choix</th> -->
                    <th class="th">
                      <button
                        style="color: #fff; background-color: #3c4d5d"
                        type="button"
                        (click)="addQuantity()"
                      >
                        <i class="bi bi-plus"></i>
                      </button>
                    </th>
                  </tr>
                  <div
                    *ngFor="let quantity of choix().controls; let i = index"
                    [formGroupName]="i"
                  >
                    <tr>
                      <label
                        >{{
                          "formation_card_add_subtitle_3_slider5" | translate
                        }}
                      </label>
                      <button
                        type="button"
                        (click)="removeQuantity(i)"
                        class="btn btn-default"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </tr>

                    <tr>
                      <td>
                        <label>{{
                          "formation_card_add_subtitle_3_slider6" | translate
                        }}</label>
                        <input
                          type="text"
                          formControlName="text"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>
                        <!-- <input type="text" placeholder="Entrez la réponse "  formControlName="description" class="form-control"  > -->
                        <label>{{
                          "formation_card_add_subtitle_3_slider7" | translate
                        }}</label>

                        <angular-editor
                          formControlName="description"
                          [config]="editorConfig"
                        ></angular-editor>
                      </td>
                    </tr>
                  </div>
                </table>
              </div>

              <div *ngIf="typeArticles" class="form-group">
                <table style="width: 100% !important" formArrayName="choix">
                  <tr>
                    <!-- <th >Choix</th> -->
                    <th class="th">
                      <button
                        style="
                          color: #fff;
                          background: linear-gradient(
                            to bottom,
                            #3c4d5d,
                            #29435c
                          );
                        "
                        type="button"
                        (click)="addQuantity()"
                      >
                        <i class="bi bi-plus"></i>
                      </button>
                    </th>
                  </tr>
                  <div
                    *ngFor="let quantity of choix().controls; let i = index"
                    [formGroupName]="i"
                  >
                    <tr>
                      <label
                        >{{
                          "formation_card_add_subtitle_3_article5" | translate
                        }}
                      </label>

                      <button
                        type="button"
                        (click)="removeQuantity(i)"
                        class="btn btn-default"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </tr>

                    <tr>
                      <td>
                        <label>{{
                          "formation_card_add_subtitle_3_article6" | translate
                        }}</label>

                        <input
                          type="text"
                          formControlName="text"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>
                        <!-- <input type="text" placeholder="Entrez la réponse "  formControlName="description" class="form-control"  > -->
                        <label>{{
                          "formation_card_add_subtitle_3_article7" | translate
                        }}</label>

                        <angular-editor
                          formControlName="description"
                          [config]="editorConfig"
                        ></angular-editor>
                      </td>
                    </tr>
                  </div>
                </table>
              </div>
              <br />
              <label *ngIf="typeVideo "
                >{{ "formation_element_liste_1" | translate }} 1</label
              >
              <div *ngIf="typeVideo">
                <input
                  #fileInput
                  (change)="changeCheckVideo1($event.target)"
                  type="file"
                  formControlName="video"
                  class="form-control"
                  accept="video/mp4,video/*"
                  placeholder="exmple"
                  [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
                />

                <div
                  *ngIf="submitted && f.video.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.video.errors.required">
                    {{ "shared_2_error" | translate }}
                  </div>
                  <div *ngIf="f.video.errors.pattern">
                    Ce champ accepte seulement des vidéos.
                  </div>
                </div>
              </div>
              <br />
              <label *ngIf="typeVideo"
                >{{ "formation_card_add_subtitle_3_video3" | translate }}
              </label>
              <input formControlName="descriptionVideo1" class="form-control" />
              <br />
              <br />
              <label *ngIf="typeVideo "
                >{{ "formation_element_liste_1" | translate }} 2</label
              >
              <div *ngIf="typeVideo">
                <input
                  #fileInput1
                  (change)="changeCheckVideo2($event.target)"
                  [attr.disabled]="checkVideo2 == true ? true : null"
                  type="file"
                  formControlName="video"
                  class="form-control"
                  accept="video/mp4,video/*"
                  [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
                />

                <div
                  *ngIf="submitted && f.video.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.video.errors.pattern">
                    Ce champ accepte seulement des vidéos.
                  </div>
                </div>
              </div>
              <br />
              <label *ngIf="typeVideo "
                >{{ "formation_card_add_subtitle_3_video3" | translate }}
              </label>
              <input formControlName="descriptionVideo2" class="form-control"   [attr.disabled]="checkVideo2 == true ? true : null" />

              <br />
              <label *ngIf="typeVideo "
                >{{ "formation_element_liste_1" | translate }} 3</label
              >
              <div *ngIf="typeVideo">
                <input
                  #fileInput2
                  (change)="changeCheckVideo3($event.target)"
                  [attr.disabled]="checkVideo3 == true ? true : null"
                  type="file"
                  type="file"
                  formControlName="video"
                  class="form-control"
                  accept="video/mp4,video/*"
                  [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
                />

                <div
                  *ngIf="submitted && f.video.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.video.errors.pattern">
                    Ce champ accepte seulement des vidéos.
                  </div>
                </div>
              </div>
              <br />
              <label *ngIf="typeVideo "
                >{{ "formation_card_add_subtitle_3_video3" | translate }}
              </label>
              <input formControlName="descriptionVideo3" class="form-control"   [attr.disabled]="checkVideo3 == true ? true : null" />

              <br />
              <label *ngIf="typeVideo"
                >{{ "formation_element_liste_1" | translate }} 4</label
              >
              <div *ngIf="typeVideo">
                <input
                  #fileInput3
                  [attr.disabled]="checkVideo4 == true ? true : null"
                  type="file"
                  formControlName="video"
                  class="form-control"
                  accept="video/mp4,video/*"
                  [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
                />

                <div
                  *ngIf="submitted && f.video.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.video.errors.pattern">
                    Ce champ accepte seulement des vidéos.
                  </div>
                </div>
              </div>
              <br />
              <label *ngIf="typeVideo"
                >{{ "formation_card_add_subtitle_3_video3" | translate }}
              </label>
              <input formControlName="descriptionVideo4" class="form-control"   [attr.disabled]="checkVideo4 == true ? true : null" />

              <br />
            </div>
            <!-- <div>
                            
                                <input type="file" name="file" class="custom-file-input">
                            </div> -->
                            <div class="form-group">
                              <label>{{ "formation_conlusion" | translate}}<strong>*</strong></label>
                              <angular-editor
                                formControlName="conclusion"
                                [config]="editorConfig"
                                [ngClass]="{ 'is-invalid': submitted && f.conclusion.errors }"
                              ></angular-editor>
                
                              <div
                                *ngIf="submitted && f.conclusion.errors"
                                class="invalid-feedback"
                              >
                                <div *ngIf="f.conclusion.errors.required">
                                  {{ "shared_2_error" | translate }}
                                </div>
                              </div>
                            </div>
            <div class="text-center">
              <button [disabled]="isLoading" class="btn btn-primary mr-1">
                {{ "btn_1" | translate }}
              </button>

              <button
                routerLink="/admin/formations"
                [disabled]="isLoading"
                class="btn btn-secondary"
                type="reset"
              >
                {{ "btn_2" | translate }}
              </button>
            </div>
            <div class="text-center">
              <img
                *ngIf="isLoading"
                style="width: 13%"
                src="assets\images\spinner.gif"
                alt="..."
              />
            </div>
            <!-- <div *ngIf="isLoading == true" class="text-center">
                                <img  style="width: 10%;" src="assets\images\spinner.gif" alt="...">
                            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
