import { HttpInterceptor } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector :Injector) { }

  intercept(req ,next ) {
  let authenticationService = this.injector.get(AuthenticationService)
  let tokenzedReq =req.clone({
    setHeaders: {
      Authorization : `Bearer ${authenticationService.getToken()}`
    }
  })
  return next.handle(tokenzedReq)
  }
 
}
 