import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ToastrService } from "ngx-toastr";
import { Training } from "src/app/_models/training";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TrainingService } from "src/app/_services/training.service";
import { TrainingsArchivesService } from "src/app/_services/trainings-archives.service";
import {  TranslateService} from '@ngx-translate/core';


@Component({
  selector: "app-trainings-admin",
  templateUrl: "./trainings-admin.component.html",
  styleUrls: ["./trainings-admin.component.css"],
})
export class TrainingsAdminComponent implements OnInit {
  TestExiste;
  selected: string = "";
  valueChange = false;
  trainings;
  trainingsArchives
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  numberPageInlistTraining=this.tableSize
  tableSizes: any = [3, 6, 9, 12];
  page2: number = 1;
  count2: number = 0;
  tableSize2: number = 10;
  tableSizes2: any = [3, 6, 9, 12];
  title;
  date = "";
  dateConvertit: string;
  statut = null;
  trainingsNonArchivees =false
  trainingsArchivees =false
  test
  recentResultsFiltred
  searchText
  lengthTraining
  lenghtTrainingsArchives
  lenghtRecentResultsFiltred
  constructor(
    private router: Router,
    private trainingService: TrainingService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private examenService: ExamenServiceService,
    private translate: TranslateService,
    public iziToast: Ng2IzitoastService,
    private trainingArchivesService: TrainingsArchivesService,
  ) {}

  ngOnInit(): void {
    this.getTrainings();
  }

  getTrainings() {
    this.trainingsNonArchivees =true
    this.trainingsArchivees =false
    this.trainingService.getTrainings().subscribe((data) => {
      this.trainings = data;
      this.lengthTraining=this.trainings.length
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length
    });
  }

  getTrainingsArchivees() {
    this.trainingsNonArchivees =false
    this.trainingsArchivees =true
    this.trainingArchivesService.getTrainingsArchives().subscribe((data) => {
      this.trainingsArchives = data;
      this.lenghtTrainingsArchives=this.trainingsArchives.length
    });
  }

 // archivage 
  deleteTraining(training: Training) {
    this.trainingService.deleteTraining(training._id).subscribe((e) => {
      this.iziToast.show({
        message:this.translate.instant('shared_4_2_succes'),
        messageColor:'#343A40',
        progressBarColor:'#212529',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#CED4DA',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
      this.getTrainings();
    });
    this.modalService.dismissAll();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  createTraining() {
    this.router.navigate(["admin/formations/ajout"]);
  }
  updateTraining(id) {
    this.router.navigate(["admin/formations/modification", id]);
  }

  shareTraining(id) {
    this.router.navigate(["admin/formations/partage", id]);
  }
  onTableDataChange(event: any) {
    this.page = event;
    //this.getTrainings();
  }
  onTableDataChangeArchives(event: any) {
    this.page2 = event;
    this.getTrainingsArchivees();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTrainings();
  }
  detailsTraining(id) {
    this.router.navigate(["admin/formations/details", id]);
  }

  changeEtat(training: Training) {
    this.trainingService.changeEtat(training._id).subscribe((e) => {
      if(training.statut == "Inactive")
      {
        training.etat=true
        training.statut = "Active"
      this.iziToast.show({
        message: this.translate.instant('shared_4_3_succes'),
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
        }
      else if(training.statut == "Active")
      {
        training.etat=false
        training.statut = "Inactive"
      this.iziToast.show({
        message:this.translate.instant('shared_4_4_succes'),
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
        }
      // setTimeout(function(){
      // window.location.reload();
      // },3000)
    });
  }

  updateCreateExamenInTrue(training: Training) {
    this.router.navigate(["admin/questionnaires/ajout", training._id]);
  }

  displayQuestionnaire(training: Training) {
    this.examenService.getTestByTraining(training._id).subscribe((e) => {
      this.router.navigate(["admin/questionnaires/modification", e._id]);
    });
  }

  search() {
    if (this.title != "") {
      this.trainings = this.recentResultsFiltred
      this.trainings = this.trainings.filter((res) => {
        return res.title
          .toLocaleLowerCase()
          .match(this.title.toLocaleLowerCase());
      });
      this.page=1
      this.lenghtRecentResultsFiltred=this.trainings.length

    } else if (this.title == "") {
      this.recentResultsFiltred
      this.trainings = this.recentResultsFiltred
     this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      
     }
   
  }


  searchBydate() {
    this.trainingService.searchTrainingByDate(this.date).subscribe((e) => {
      if (this.date == undefined) {
        this.getTrainings();
      } else {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length
      }
    });
  }

  searchByStatut() {
    this.trainingService.searchTrainingByStatut(this.statut).subscribe((e) => {
      this.trainings = e;
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length
    });
  }

  searchByDateStatut() {
    this.trainingService
      .searchTrainingByDateAndStatut(this.date, this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length      
      });
  }

  searchStatutAndDate() {
    if (this.statut == null && this.date == "" ) {
      this.getTrainings();
      this.page=1;

    }
    else if (this.statut != null && this.date == ""){
      this.searchByStatut();
      this.page=1;

    }
    else if (this.statut == null && this.date !="")
    {
      this.searchBydate();
      this.page=1;

    }
    else {
      this.searchByDateStatut();
      this.page=1;
    }
  
  }

  addQuestion(id) {
    this.router.navigate(["admin/questionnaires/ajout", id]);
  }
  cancelFilter()
  {
    this.title = ""
    this.date=""
    this.statut = null;
    this.tableSize=this.numberPageInlistTraining
    this.getTrainings()
  }

  // deleteFoemation 
  deleteTrainingArchivage(training: Training) {
    this.trainingArchivesService.deleteTrainingArchive(training._id).subscribe((e) => {
      this.iziToast.show({
        message:this.translate.instant('shared_17_success_delete'),
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
      this.getTrainingsArchivees();
    });
    this.modalService.dismissAll();
  }
  deleteExamenInListTrainings(training :Training)
  {
    this.examenService.getTestByTraining(training._id).subscribe(e=>
      {
        this.test=e._id
        this.deleteTest(this.test)
        this.iziToast.show({
          message:this.translate.instant('shared_17_success_delete'),
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
       
        this.getTrainings()
      })
      this.modalService.dismissAll();
  }

  deleteTest(test)
  {
  this.examenService.deleteTest(test).subscribe(e=>
   {
     console.log("e")
     
   })
}

}
