<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">{{ "formation_title" | translate }}</h1>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1"></div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">Modification d'une formation</h5>
      </div>

      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="registerForm" *ngIf="registerForm">
            <div class="row">
              <div class="form-group col-8">
                <label>{{ "formation_filter_title" | translate }}</label>
                <input
                  type="text"
                  formControlName="title"
                  class="form-control"
                  [(ngModel)]="training.title"
                  [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                />
                <div
                  *ngIf="submitted && f.title.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.title.errors.required">
                    {{ "shared_2_error" | translate }}.
                  </div>
                  <div *ngIf="f.title.errors.maxlength">
                    {{ "error_confirmation_length_30" | translate }}.
                  </div>
                </div>
              </div>
              <div *ngIf="dateFinExiste == true" class="form-group col-4">
                <label
                  >{{ "formation_card_add_subtitle_3_video2" | translate }}
                  <strong></strong
                ></label>
                <input
                  type="date"
                  formControlName="dateEnd"
                  [(ngModel)]="date"
                  min="{{ d1 }}"
                  class="form-control"
                />
              </div>
              <div *ngIf="dateFinExiste == false" class="form-group col-4">
                <label
                  >{{ "formation_card_add_subtitle_3_video2" | translate }}
                  <strong></strong
                ></label>
                <input
                  type="date"
                  formControlName="dateEnd"
                  min="{{ d1 }}"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <angular-editor
                formControlName="description"
                [config]="editorConfig"
                [(ngModel)]="training.description"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
              ></angular-editor>

              <div
                *ngIf="submitted && f.description.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.description.errors.required">
                  {{ "shared_2_error" | translate }} .
                </div>
              </div>
            </div>

            <label>{{ "formation_element_liste_1" | translate }} 1</label>
            <div *ngIf="filenameVideo1 != null">
              <video controls class="col-lg-4">
                <source [src]="filenameVideo1" type="video/mp4" />
              </video>
            </div>
            <div *ngIf="filenameVideo1 == null">no video</div>

            <input
              #fileInput
              type="file"
              formControlName="video"
              class="form-control"
              accept="video/mp4,video/*"
              [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
            />

            <br />
            <div>
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
                type="text"
                formControlName="descriptionVideo1"
                class="form-control"
                [(ngModel)]="trainings[0].descriptionVideo"
              />
              <br />
            </div>

            <br />

            <label>{{ "formation_element_liste_1" | translate }} 2</label>
            <button
              *ngIf="listVideosLength == 2"
              (click)="delete(1)"
              type="button"
              class="btn btn-sm btn-square btn-neutral text-danger-hover"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{ ' btn_hover_11' | translate }}"
              style="margin-right: 2%; float: right"
            >
              <i class="bi bi-trash"></i>
            </button>

            <div *ngIf="filenameVideo2 != null">
              <video controls class="col-lg-4">
                <source [src]="filenameVideo2" type="video/mp4" />
              </video>
            </div>
            <div *ngIf="filenameVideo2 == null"></div>

            <input
              #fileInput1
              (change)="changeCheckVideo2($event.target)"
              type="file"
              formControlName="video"
              class="form-control"
              accept="video/mp4,video/*"
              [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
            />

            <br />
            <div *ngIf="trainings.length >= 2">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
                              
                type="text"
                formControlName="descriptionVideo2"
                class="form-control"
                [(ngModel)]="trainings[1].descriptionVideo"
              />
              <br />
            </div>

            <div *ngIf="trainings.length < 2">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
                type="text"
                formControlName="descriptionVideo2"
                class="form-control"
              />
              <br />
            </div>

            <br />

            <label>{{ "formation_element_liste_1" | translate }} 3</label>
            <button
              *ngIf="listVideosLength == 3"
              (click)="delete(2)"
              type="button"
              class="btn btn-sm btn-square btn-neutral text-danger-hover"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{ ' btn_hover_11' | translate }}"
              style="margin-right: 2%; float: right"
            >
              <i class="bi bi-trash"></i>
            </button>
            <div *ngIf="filenameVideo3 != null">
              <video controls class="col-lg-4">
                <source [src]="filenameVideo3" type="video/mp4" />
              </video>
            </div>
            <div *ngIf="filenameVideo3 == null"></div>

            <input 
              #fileInput2
              (change)="changeCheckVideo3($event.target)"
              [attr.disabled]="checkVideo2 == true ? true : null"
              type="file"
              formControlName="video"
              class="form-control"
              accept="video/mp4,video/*"
              [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
            />

            <br />
            <div *ngIf="trainings.length >= 3">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
               [attr.disabled]="checkVideo2 == true ? true : null"
                type="text"
                formControlName="descriptionVideo3"
                class="form-control"
                [(ngModel)]="trainings[2].descriptionVideo"
              />
              <br />
            </div>
            <div *ngIf="trainings.length < 3">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
              [attr.disabled]="checkVideo2 == true ? true : null"
                type="text"
                formControlName="descriptionVideo3"
                class="form-control"
              />
              <br />
            </div>
            <br />

            <label>{{ "formation_element_liste_1" | translate }} 4</label>
            <button
              *ngIf="listVideosLength == 4"
              (click)="delete(3)"
              type="button"
              class="btn btn-sm btn-square btn-neutral text-danger-hover"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{ ' btn_hover_11' | translate }}"
              style="margin-right: 2%; float: right"
            >
              <i class="bi bi-trash"></i>
            </button>
            <div *ngIf="filenameVideo4 != null">
              <video controls class="col-lg-4">
                <source [src]="filenameVideo4" type="video/mp4" />
              </video>
            </div>
            <div *ngIf="filenameVideo4 == null"></div>

            <input
              #fileInput3
              [attr.disabled]="checkVideo3 == true ? true : null"
              type="file"
              formControlName="video"
              class="form-control"
              accept="video/mp4,video/*"
              [ngClass]="{ 'is-invalid': submitted && f.video.errors }"
            />

            <br />
            <div *ngIf="trainings.length >= 4">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
                [attr.disabled]="checkVideo3 == true ? true : null"
                type="text"
                formControlName="descriptionVideo4"
                class="form-control"
                [(ngModel)]="trainings[3].descriptionVideo"
              />
              <br />
            </div>
            <div *ngIf="trainings.length < 4">
              <label>{{
                "formation_card_add_subtitle_3_video3" | translate
              }}</label>
              <input
              [attr.disabled]="checkVideo3 == true ? true : null"
                type="text"
                formControlName="descriptionVideo4"
                class="form-control"
              />
              <br />
            </div>
            <div class="form-group">
              <label>{{ "formation_conlusion" | translate}}<strong>*</strong></label>
              <angular-editor
                formControlName="conclusion"
                [config]="editorConfig"
                [(ngModel)]="training.conclusion"
                [ngClass]="{ 'is-invalid': submitted && f.conclusion.errors }"
              ></angular-editor>

              <div
                *ngIf="submitted && f.conclusion.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.conclusion.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <button (click)="onSubmit()" class="btn btn-primary mr-1">
                {{ "btn_7" | translate }}
              </button>
              <button
                routerLink="/admin/formations"
                class="btn btn-secondary"
                type="reset"
                (click)="onReset()"
              >
                {{ "btn_2" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
