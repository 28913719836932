<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">
            {{ "certificate_card_title" | translate }}
          </h1>
        </div>
        <!-- Actions -->
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a
            (click)="allCertficates()"
            [ngClass]="{ current0: listAllCertificates }"
            class="nav-link font-regular"
            >{{ "certificate_subtitle_1" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="certficatsExipreds()"
            [ngClass]="{ current0: listExipredCertificates }"
            class="nav-link font-regular"
            >{{ "certificate_subtitle_2" | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ "certificate_card_title" | translate }}</h5>
        <h5 style="padding-top: 1%" class="mb-0">
          {{ "instalatteur_title_one" | translate }} : {{ firstName }}
          {{ lastName }}
        </h5>
      </div>

      <div
        *ngIf="listeCertificates"
        class="py-5"
        style="background-color: white"
      >
        <div class="container">
          <p style="text-align: center" *ngIf="sizeListToutesCertficates == 0">
            {{ "certificate_not_found" | translate }}
          </p>
          <div class="row hidden-md-up">
            <div
              *ngFor="let i of listeCertificates.reverse()"
              class="col-md-4"
              style="padding-bottom: 1.25rem"
            >
              <div
                *ngIf="listAllCertificates"
                class="card"
                style="
                  border-radius: 25px;
                  background-color: #eff2f3;
                  border: none;
                "
              >
                <div class="card-block">
                  <div class="logo">
                    <img class="center" src="assets/images/11.png" />
                  </div>
                  <h1 class="titleCertificate">{{ i.test.nom }}</h1>
                  <div class="cardBody">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        justify-items: center;
                      "
                    >
                      <h2>{{ "certificate_date_obtention" | translate }}</h2>
                      <h3>{{ i.firstDate | date : "dd/MM/yyyy" }}</h3>
                    </div>
                    <div class="d-flex">
                      <div
                        class="vr"
                        style="color: white; border: 1px solid"
                      ></div>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        justify-items: center;
                      "
                    >
                      <h2>
                        {{ "formation_card_add_subtitle_3_video2" | translate }}
                      </h2>
                      <h3>{{ i.dateEndExipred | date : "dd/MM/yyyy" }}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="listExipredCertificates"
                class="card"
                style="
                  border-radius: 25px;
                  background-color: #eff2f3;
                  border: none;
                "
              >
                <div class="card-block">
                  <div class="logo">
                    <img class="center" src="assets/images/11.png" />
                  </div>
                  <h1 class="titleCertificate">{{ i.test.nom }}</h1>
                  <div class="cardBody2">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        justify-items: center;
                      "
                    >
                      <h2
                        style="
                          padding-top: 0.2rem;
                          padding-bottom: 0.9rem;
                          font-size: 1.25rem;
                        "
                      >
                        {{ "certficat_expire" | translate }}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
