import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Ng2IzitoastService } from "ng2-izitoast";
import { Training } from "src/app/_models/training";
import { TrainingService } from "src/app/_services/training.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-update-training",
  templateUrl: "./update-training.component.html",
  styleUrls: ["./update-training.component.css"],
})
export class UpdateTrainingComponent implements OnInit {
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef;
  @ViewChild("fileInput2", { static: false }) fileInput2: ElementRef;
  @ViewChild("fileInput3", { static: false }) fileInput3: ElementRef;
  files: any;
  listVideosLength;

  filenameVideo1;
  filenameVideo2;
  filenameVideo3;
  filenameVideo4;
  checkVideo2 : boolean =true
  checkVideo3 : boolean =true
  upload = `${environment.uploadUrl}`;
  installers;
  distrubters;
  taille;
  videos: any[] = [];
  verif = false;
  trainings = [];
  videoFormation;
  test;
  public ss;
  id: string;
  training: Training = new Training();
  registerForm: FormGroup;
  submitted = false;
  toutesInstallers;
  toutesDistributers;
  date = "";
  d1: string;
  dateAuj = new Date();
  dateFinExiste: boolean;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  results: [{ text: string; description: string; selected: boolean }];
  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sanitization: DomSanitizer,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.trainingService.getTrainingById(this.id).subscribe(async (data) => {
      this.training = data;

      if (this.training.etat == true)
        this.router.navigate(["admin/formations"]);

      this.trainings = this.training.video;
      if (this.training.dateEnd != undefined) {
        this.dateFinExiste = true;
        this.date = this.training.dateEnd.toString().split("T")[0];
      } else this.dateFinExiste = false;

      this.dateAuj.setDate(this.dateAuj.getDate() + 1);
      this.d1 = this.dateAuj.toISOString().substr(0, 10);

      this.trainings = this.trainings.map((p) => {
        p.filename = this.sanitization.bypassSecurityTrustResourceUrl(
          this.upload + p.filename
        );

        return p;
      });
      if (this.trainings[0] != undefined) {
        this.filenameVideo1 = this.trainings[0].filename;
      } else {
        this.filenameVideo1 = null;
      }

      if (this.trainings[1] != undefined) {
        this.filenameVideo2 = this.trainings[1].filename;
        this.checkVideo2=false
      } else {
        this.filenameVideo2 = null;
      }

      if (this.trainings[2] != undefined) {
        this.filenameVideo3 = this.trainings[2].filename;
        this.checkVideo3=false
      } else {
        this.filenameVideo3 = null;
      }

      if (this.trainings[3] != undefined) {
        this.filenameVideo4 = this.trainings[3].filename;
      } else {
        this.filenameVideo4 = null;
      }

      this.trainings = this.training.video;
      this.listVideosLength = this.trainings.length;

      this.registerForm = this.formBuilder.group({
        title: ["", [Validators.required, Validators.maxLength(50)]],
        video: ["", [Validators.pattern("[^\\s]+(.*?)\\.(mp4|MP4 )$")]],
        description: ["", Validators.required],
        dateEnd: [""],
        descriptionVideos: [],
        updateVideoNumbers: [],
        descriptionVideo1: ["", []],
        descriptionVideo2: ["", []],
        descriptionVideo3: ["", []],
        descriptionVideo4: ["", []],
        conclusion: [
          "",
          [
            Validators.required,
          ],
        ],
  
        newDescriptionVideo1: ["", []],
      });
    });
  }

  get f() {
    return this.registerForm.controls;
  }
  UpdateTraining() {
    const imagBlob = this.fileInput.nativeElement.files[0];
    const imagBlob1 = this.fileInput1.nativeElement.files[0];
    const imagBlob2 = this.fileInput2.nativeElement.files[0];
    const imagBlob3 = this.fileInput3.nativeElement.files[0];
    let videos: any[] = [];
    videos.push(imagBlob);
    videos.push(imagBlob1);
    videos.push(imagBlob2);
    videos.push(imagBlob3);

    const form = new FormData();
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    if (
      imagBlob == undefined &&
      imagBlob1 == undefined &&
      imagBlob2 == undefined &&
      imagBlob3 == undefined
    ) {
      let results = [];

      let nbVerif = 0;
      let descriptionsNotEmpty = [];
      for (let training of this.trainings) {
        if (training.filename != null) results.push(training);
      }

      this.trainings = results;
      let deccriptions = [];
      deccriptions.push(this.registerForm.value.descriptionVideo1);
      deccriptions.push(this.registerForm.value.descriptionVideo2);
      deccriptions.push(this.registerForm.value.descriptionVideo3);
      deccriptions.push(this.registerForm.value.descriptionVideo4);
      for (let desc of deccriptions) {
        if (desc != "") {
          descriptionsNotEmpty.push(desc);
        }
      }
      for (let i = 0; i < this.trainings.length; i++) {
        if (
          this.trainings[i].descriptionVideo == "" ||
          this.trainings[i].descriptionVideo.length > 50
        ) {
          nbVerif = i;
          break;
        } else {
          nbVerif += 1;
        }
      }
      if (
        nbVerif == this.trainings.length &&
        this.trainings.length == descriptionsNotEmpty.length
      ) {
        this.registerForm.value.video = this.training.video;

        this.trainingService
          .EditTraining(this.id, this.registerForm.value)
          .subscribe((data) => {
            this.iziToast.show({
              message: this.translate.instant("shared_4_1_succes"),
              messageColor: "#386641",
              progressBarColor: "#6a994e",
              icon: "bi-check-circle",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#dde5b6",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
            });
            this.goToList();
          });
      } else {
        nbVerif = nbVerif + 1;
        let errorMessage;
        errorMessage =
          this.translate.instant("shared_20_error") +
          " " +
          nbVerif +
          " " +
          this.translate.instant("shared_24_error");
        this.iziToast.show({
          message: errorMessage,
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      }
    } else {
      let deccriptions = [];
      let results =[]
      deccriptions.push(this.registerForm.value.descriptionVideo1);
      deccriptions.push("*separator*");
      deccriptions.push(this.registerForm.value.descriptionVideo2);
      deccriptions.push("*separator*");
      deccriptions.push(this.registerForm.value.descriptionVideo3);
      deccriptions.push("*separator*");
      deccriptions.push(this.registerForm.value.descriptionVideo4);

      this.registerForm.value.descriptionVideos = deccriptions;
      console.log("trainings",this.trainings)
      // for (let training of this.trainings) {
      //   if (training.filename != null) results.push(training);
      // }
      // this.trainings=results
      // console.log("trainings after",this.trainings)
      let tableVideosUpdates = [];
      for (let i = 0; i < videos.length; i++) {
        if (videos[i] != undefined) tableVideosUpdates.push(i);
      }
       console.log("tableVideosUpdates",tableVideosUpdates)
      this.registerForm.value.updateVideoNumbers = tableVideosUpdates;
      for (let i = 0; i < videos.length; i++) {
        form.append("video", videos[i]);
      }
      let NewVideos = [];
      let descriptionsNotEmpty = [];
      let indiceCounter = 0;
      let flags = [];
      let firstVideoFlag = 1000;
      let i = 1;
      let descriptionflag = 1000;

      for (let video of videos) {
        if (video != undefined) {
          NewVideos.push(video);
        }
      }
      for (let desc of deccriptions) {
        if (desc != "" && desc != "*separator*") {
          descriptionsNotEmpty.push(desc);
        }
      }
       console.log("trainings after",this.trainings)

      for (let i = 0; i <= 3; i++) {
        if (videos[i] != undefined) {
          indiceCounter = indiceCounter + 1;
        } else if (this.trainings.length >= i + 1) {
          indiceCounter = indiceCounter + 1;
        } else {
          flags.push(i + 1);
        }
      }

      if (flags.length != 0) {
        firstVideoFlag = Math.min.apply(Math, flags);
      }

      let descriptionFromForm = [];
      for (let desc of deccriptions) {
        if (desc != "*separator*") {
          descriptionFromForm.push(desc);
        }
      }

      for (let deccription of descriptionFromForm) {
        if (deccription == "") {
          descriptionflag = i;
          break;
        }
        i++;
      }
       console.log
      let errorFlag = Math.min(descriptionflag, firstVideoFlag);
      if (descriptionsNotEmpty.length != indiceCounter) {
        let errorMessage;
        errorMessage =
          this.translate.instant("shared_20_error") +
          " " +
          errorFlag +
          " " +
          this.translate.instant("shared_24_error");
        this.iziToast.show({
          message: errorMessage,
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      } else {
        form.set("title", this.registerForm.value.title);
        form.set("description", this.registerForm.value.description);
        form.set(
          "descriptionVideos",
          this.registerForm.value.descriptionVideos
        );
        form.set(
          "updateVideoNumbers",
          this.registerForm.value.updateVideoNumbers
        );

        this.trainingService.EditTraining(this.id, form).subscribe((data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_1_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.goToList();
        });
      }
    }
  }
  goToList() {
    this.router.navigate(["/admin/formations"]);
  }
  onSubmit() {
    this.UpdateTraining();
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
  newVideo() {
    this.test = true;
  }

  delete(i) {
    this.trainings[i].filename = null;
    this.trainings[i].descriptionVideo = "";

    this.listVideosLength--;

    if (i == 0) {
      this.filenameVideo1 = null;
    }

    if (i == 1) {
      this.filenameVideo2 = null;
    }

    if (i == 2) {
      this.filenameVideo3 = null;
      this.checkVideo2 =true
    }

    if (i == 3) {
      this.filenameVideo4 = null;
      this.checkVideo3 =true
    }

  }

  changeCheckVideo2(fileInput1 )
  {
    console.log("fileInput1",fileInput1.files[0])
    if(fileInput1.files[0] != undefined)
    this.checkVideo2 =false
    else
    this.checkVideo2 =true

  }

  changeCheckVideo3(fileInput2 )
  {
    console.log("fileInput1",fileInput2.files[0])
    if(fileInput2.files[0] != undefined)
    this.checkVideo3 =false
    else
    this.checkVideo3 =true

  }
}
