import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'app-profil-distributer',
  templateUrl: './profil-distributer.component.html',
  styleUrls: ['./profil-distributer.component.css']
})
export class ProfilDistributerComponent implements OnInit {
  firstName;
  lastName;
  role;
  email;
  user :User=new User();
  constructor(private autenticationService :AuthenticationService
    ) { 
   
  }

  ngOnInit(): void {
    this.autenticationService.apimeFunction(localStorage.getItem("token_auth")).subscribe((result) => {
      if(result && result["data"]){
        this.firstName = result["data"].firstName 
         this.lastName = result["data"].lastName
     this.role = result["data"].role_slug
         this.email = result["data"].email
        }
  }, (err) => {
    //console.log(err)
    return false;
  });
  

  }


  
}
