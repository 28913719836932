import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Training } from '../_models/training';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  private _trainingURL=`${environment.baseUrl}/trainings`
  constructor(private http: HttpClient) { }

getTrainings() :Observable <any> {
  return this.http.get(`${this._trainingURL}`);
      }

      getTrainingsByEtatPublie() :Observable <any> {
        return this.http.get(`${this._trainingURL}/publiee`);
            }
 
      createTraining(training: any):Observable<Object> {
        return this.http.post(`${this._trainingURL}/multiple`,training);
      }
      
      deleteTraining(id : string)
      {
        return this.http.delete(`${this._trainingURL}/${id}`);
      } 
 
     getTrainingById(id : string):Observable<Training>
      {
        return this.http.get<Training>(`${this._trainingURL}/${id}`);
    
      } 
      
      EditTraining(id:string,training):Observable<Object> {
        return this.http.put(`${this._trainingURL}/${id}`,training);
      }
      
      changeEtat(id : string):Observable<Training>{
        return this.http.get<Training>(`${this._trainingURL}/etat/${id}`);
      } 
      getTrainingsByInstaller():Observable<any>
      {
        return this.http.get(`${this._trainingURL}/installer`);
    
      } 

      getTrainingsByDistrubter():Observable<any>
      {
        return this.http.get(`${this._trainingURL}/distributer`);
    
      } 

      searchTrainingByDate(date : string):Observable<Training>{
        return this.http.get<Training>(`${this._trainingURL}/searchByDate/${date}`);
      } 

      searchTrainingByStatut(statut : string):Observable<Training>{
        return this.http.get<Training>(`${this._trainingURL}/searchByStatut/${statut}`);
      } 

      createTrainingAdndSliderOrArticle(training: any):Observable<Object> {
        return this.http.post(`${this._trainingURL}/sliderOrArtcile`,training);
      }

      searchTrainingByDateAndStatut(date : string ,statut : string):Observable<Training>{
        return this.http.get<Training>(`${this._trainingURL}/searchByDateAndStatut/${date}/${statut}`);
      } 

      shareTrainingWithUsers(id : string,training)
      {
        return this.http.put(`${this._trainingURL}/shareWithDistributeurAndInstallateur/${id}`,training);
      }

      updateTrainingwithSliderOrArticle(id:string,training) {
        return this.http.put(`${this._trainingURL}/UpdateSliderOrArtcle/${id}`,training);
      }

      updateEtatCreateExamen(id : string):Observable<Training>{
        return this.http.get<Training>(`${this._trainingURL}/createTest/${id}`);
      } 

      // statistiques pour admin
      numberTrainings():Observable <any> {
        return this.http.get(`${this._trainingURL}/admin/numberTrainings`);
            }

            numberTrainingsArchives():Observable <any> {
              return this.http.get(`${this._trainingURL}/admin/numberTrainingsArchives`);
                  }
  
      }


