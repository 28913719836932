<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">
            {{ "distributeur_title" | translate }}
          </h1>
        </div>
        <!-- Actions -->
        <div class="col-sm-6 col-12 text-sm-end">
          <div class="mx-n1"></div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ "distributeur_card_add_title_2" | translate }}</h5>
      </div>

      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="registerForm">
            <div class="form-group">
              <label>{{ "profil_card_1" | translate }}</label>
              <input
                type="text"
                formControlName="firstName"
                class="form-control"
                [(ngModel)]="distributer.firstName"
                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
              />
              <div
                *ngIf="submitted && f.firstName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.firstName.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
                <div *ngIf="f.firstName.errors.minlength">
                  {{ "error_confirmation_length" | translate }}.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ "profil_card_2" | translate }}</label>
              <input
                type="text"
                formControlName="lastName"
                class="form-control"
                [(ngModel)]="distributer.lastName"
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
              />
              <div
                *ngIf="submitted && f.lastName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.lastName.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
                <div *ngIf="f.lastName.errors.minlength">
                  {{ "error_confirmation_length" | translate }}.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ "profil_card_3" | translate }}</label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [(ngModel)]="distributer.email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
                <div *ngIf="f.email.errors.email">
                  {{ "error_confirmation_email" | translate }}.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ "distributeur_card_add_content_4" | translate }}</label>
              <input
                type="text"
                formControlName="company"
                class="form-control"
                [(ngModel)]="distributer.company"
                [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
              />
              <div
                *ngIf="submitted && f.company.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.company.errors.required">
                  {{ "shared_2_error" | translate }}
                </div>
                <div *ngIf="f.company.errors.maxlength">
                  {{ "error_confirmation_length_30" | translate }}.
                </div>
              </div>
            </div>

            <div class="text-center">
              <button (click)="onSubmit()" class="btn btn-primary mr-1">
                {{ "btn_7" | translate }}
              </button>
              <button
                routerLink="/admin/distributeurs"
                class="btn btn-secondary"
                type="reset"
              >
                {{ "btn_2" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
