
    <!-- Header -->
    <header class="bg-surface-primary border-bottom pt-6">
        <div class="container-fluid">
            <div class="mb-npx">
                <div class="row align-items-center">
                    <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                        <!-- Title -->
                        <h1 class="h2 mb-0 ls-tight">{{'distributeur_title' | translate}}</h1>
                    </div>
                    <!-- Actions -->
                   
                </div>
                <!-- Nav -->
                <ul class="nav nav-tabs mt-4 overflow-x border-0">
                    <li class="nav-item ">
                        <a href="#" class="nav-link active"></a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link font-regular"></a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link font-regular"></a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    <!-- Main -->
    <main class="py-6 bg-surface-secondary">
        <div class="container-fluid">
            <div class="card shadow border-0 mb-7">
                <div class="card-header">
                    <h5 style="padding-bottom: 20px;" class="mb-0">{{'distributeur_card_title_1' | translate}}</h5>
                    <input
                    id="search-input"
                    type="search"
                    #myInput
                    class="form-control"
                    [(ngModel)] ="firstName" [(ngModel)] ="lastName" [(ngModel)] ="email" [(ngModel)] ="company" 
                    placeholder="{{'search_bar_distributor' | translate}}"
                    (input)="search()"
                  />

                </div>
                <div *ngIf="lengthListDistributers > 0" class="table-responsive">
                   
                    <table *ngIf="distributers" class="table table-hover table-nowrap">
                        <thead  style="background: linear-gradient(to bottom,#3C4D5D,#29435C);">
                            <tr>
                                <th  class="th" scope="col">{{'table_column_1' | translate}}</th>
				<th class="th" scope="col"> {{'table_column_2' | translate}}</th>
                                <th  class="th" scope="col">{{'table_column_3' | translate}}</th>
                                <th  class="th" scope="col">{{'table_column_4' | translate}}</th>
                                <th  class="th" scope="col">{{'shared_1' | translate}}</th>
                                <!-- <th  class="th" scope="col">Etat</th> -->
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of distributers.reverse() 
                            | paginate
                            : {
                                itemsPerPage: tableSize,
                                currentPage: page,
                                totalItems: count
                              };
                        let k = index
                            ">
                                <td style="text-transform: capitalize;">
                                  
                                    <img alt="..." src="https://www.w3schools.com/w3css/img_avatar3.png" class="avatar avatar-sm rounded-circle me-2">
                                    
                                        {{i.lastName}}
                                    
                                </td>
                              	<td style="text-transform: capitalize;">
                                  
                                  
                                      {{i.firstName}}
                                  
                              </td>

                                <td>
                                   
                                    <p style="margin-top: 0.4rem;"> <a href="mailto:{{i.email}}" class="text-heading font-semibold">
                                        {{i.email}}
                                    </a>
                                  </p>
                                   
                                </td>

                                <td>
                                   
                                    <p style="margin-top: 0.4rem;">
                                        {{i.name}}
                                   
                                  </p>
                                   
                                </td>
                               
                                <td>
                                   
                                   
                                   
                                    <a (click)="displayListCertificatesbyDistributeur(i.id)" class="btn btn-sm btn-square btn-neutral text-danger-hover" data-toggle="tooltip" data-placement="bottom" title="{{'certificate_card_title' | translate}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard" viewBox="0 0 16 16">
                                            <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z"/>
                                            <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z"/>
                                          </svg>
                                    </a>

                                   
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                    <div  *ngIf="lengthListDistributers > 10"  class="card-footer border-0 py-5">
                        <div class="d-flex justify-content-center">
                          <pagination-controls
                            class="my-pagination"
                            previousLabel="{{'shared_5_pagination' | translate}}"
                            nextLabel="{{'shared_6_pagination' | translate}}"
                            (pageChange)="onTableDataChange($event)"
                          >
                          </pagination-controls>
                        </div>
                      </div>
                </div>
                <div  *ngIf="lengthListDistributers == 0" class="table-responsive" style="text-align: center;">
                    <p style="padding-bottom: 45px; padding-top: 35px ;">{{'distributeur_card_table' | translate }}</p>
                </div>                
            </div>
        </div>
    </main>

