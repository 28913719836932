<!-- Banner -->

<a  class="btn w-full btn-primary text-truncate rounded-0 py-2 border-0 position-relative" style="z-index: 1000;">
    <strong style="color: #fff !important;">FINEO E-learning  </strong> 


          <a  style="float: right;">
            <li class="dropdown">
                <a  data-toggle="dropdown" aria-expanded="false" (click)="openlist()" disable>
                    <i *ngIf="selectedLang == 'en'"  style="padding-right: 10px; " >
                        <img class="flag" style="height: 2rem; border-bottom: 3px solid green;"  src="../../../assets/images/flags/uk.svg">
                    </i>
                    <i *ngIf="selectedLang != 'en'" style="padding-right: 10px;" (click)="lang('en')" >
                        <img class="flag" style="height: 2rem;"  src="../../../assets/images/flags/uk.svg">
                        <span ></span>
                    </i>
                    <i *ngIf="selectedLang != 'fr'" style="padding-right: 10px;" (click)="lang('fr')">
                        <img class="flag" style="height: 2rem;"  src="../../../assets/images/flags/fr.svg">
                    </i>

                    <i *ngIf="selectedLang == 'fr'" style="padding-right: 10px;">
                        <img class="flag" style="height: 2rem; border-bottom: 3px solid green;"  src="../../../assets/images/flags/fr.svg">
                    </i>


                    <i *ngIf="selectedLang == 'de'" style="padding-right: 10px;" >
                        <img class="flag" style="height: 2rem;border-bottom: 3px solid green;"  src="../../../assets/images/flags/de.svg">
                    </i>

                    <i *ngIf="selectedLang != 'de'" style="padding-right: 10px;" (click)="lang('de')" >
                        <img class="flag" style="height: 2rem;"  src="../../../assets/images/flags/de.svg">
                    </i>

                    <i  *ngIf="selectedLang != 'nl'" style="padding-right: 10px;" (click)="lang('nl')">
                        <img class="flag" style="height: 2rem;"  src="../../../assets/images/flags/nl.svg">
                    </i>

                    <i *ngIf="selectedLang == 'nl'" style="padding-right: 10px;" >
                        <img class="flag" style="height: 2rem; border-bottom: 3px solid green;"  src="../../../assets/images/flags/nl.svg">
                    </i>
                 </a>
                


            </li>
        </a>



</a>
<!-- Dashboard -->
<div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    <!-- Vertical Navbar -->
    <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
        <div class="container-fluid">
            <!-- Toggler -->
            <button class="navbar-toggler ms-n2" (click)="changenavbarclass()" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Brand -->
            <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin">
                <img src="assets\images\11.png" alt="...">
            </a>
            <div class="navbar-user d-lg-none">
                <!-- Dropdown -->
                <div class="dropdown">
                    <!-- Toggle -->
                    <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="avatar-parent-child">
                            <span class="avatar-child avatar-badge bg-success"></span>
                        </div>
                    </a>
                    <!-- Menu -->
                </div>
            </div>
            <!-- Collapse -->
            <div [ngClass]="navbarclass"   id="sidebarCollapse">
                <!-- Navigation -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/admin">
                            <i class="bi bi-house"></i> {{'dashboard_title' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="profil">
                            <i class="bi bi-people"></i> {{'profil_title' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="installateurs">
                            <i class="bi bi-people"></i> {{'instalatteur_title' | translate}}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="distributeurs">
                            <i class="bi bi-people"></i> {{'distributeur_title' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="formations">
                            <i class="bi bi-bookmarks"></i> {{'formation_title' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="questionnaires">
                            <i class="bi bi-bookmarks"></i> {{'questionaire_title' | translate}}
                        </a>
                    </li>
                   
                </ul>
                <!-- Divider -->
                <hr class="navbar-divider my-5 opacity-20">
                <hr class="navbar-divider my-5 opacity-20">
                <hr class="navbar-divider my-5 opacity-20">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a style="cursor: pointer;" class="nav-link" (click)="logout()">
                            <i class="bi bi-box-arrow-left"></i> {{'shared_16_btn' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Main content -->
    
    <div class="h-screen flex-grow-1 overflow-y-lg-auto">
       
   <router-outlet></router-outlet>
   
   </div>
   
</div>

