import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TrainingService } from "src/app/_services/training.service";
import { TranslateService } from "@ngx-translate/core";
import { PhotoQuestionService } from "src/app/_services/photo-question.service";

@Component({
  selector: "app-add-test",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./add-test.component.html",
  styleUrls: ["./add-test.component.css"],
})
export class AddTestComponent {
  @ViewChild("testInput") testInput;
  @ViewChild("testInput2") testInput2;
  public form: FormGroup;
  results = [];
  submitted = false;
  reponses = [];
  d: string;
  d1: string;
  dateAuj = new Date();
  numero = 1;
  eror = false;
  trainings;
  private formBuilder: FormBuilder;
  id: any;
  training;
  titleTraining;
  titleTrainingEn :string =""
  titleTrainingDe
  titleTrainingNl
  closeResult: string;
  reseler = "";
  questioninhtml = "";
  Listreader = [];
  language
  constructor(
    private _cdr: ChangeDetectorRef,
    formBuilder: FormBuilder,
    private examen: ExamenServiceService,
    private router: Router,
    public iziToast: Ng2IzitoastService,
    private trainingService: TrainingService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: NgbModal,
    private photoQuestion: PhotoQuestionService
  ) {
    this.test()
    this.formBuilder = formBuilder;
    this.form = this.formBuilder.group({
      nom: ["", Validators.required], // titre
      // dateFin: ["", Validators.required],
      temps: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern("^([1-9][0-9]*)$"),
        ],
      ],
      titre: ["", [Validators.required]],
      training: [""],
      score: ["", Validators.required],
      questions: this.formBuilder.array([]),
    });
    this.d = this.dateAuj.toISOString();
    this.d1 = this.dateAuj.toISOString().substr(0, 10);
   
  }
  get f() {
    return this.form.controls;
  }
   async test()
   {
    this.language = localStorage.getItem("currentLang");
    this.id = this.route.snapshot.params["id"];
     await this.trainingService.getTrainingById(this.id).subscribe(async (data) => {
      this.training = data;
      this.titleTraining = this.training.title;
      this.titleTrainingEn = this.training.titleEN;
      this.titleTrainingDe = this.training.titleDE;
      this.titleTrainingNl = this.training.titleNL;
      this._cdr.detectChanges()
    });
   }

  public addChoix(question: FormControl): void {
    var choix = this.getChoix(question); // Critical: casts as FormArray.

    choix.push(
      this.formBuilder.group({
        id: [Date.now()],
        text: ["", Validators.required],
        selected: false,
      })
    );
  }

  public addQuestion(): void {
    var questions = this.getQuestions();
    let reponsesJustes = [];
    let taille = this.form.value.questions.length;
    if (this.form.value.questions[taille - 1] == undefined) {
      questions.push(
        this.formBuilder.group({
          id: [Date.now()],
          question: ["", Validators.required],
          selected: false,
          choix: this.formBuilder.array([]),
          urlPhoto: "",
        })
      );

      this.Listreader.push({ id: questions.value[0].id, reader: "" });
    }
    if (this.form.value.questions[taille - 1].choix.length >= 2) {
      for (let c of this.form.value.questions[taille - 1].choix) {
        if (c.selected == true) {
          reponsesJustes.push(c);
          questions.push(
            this.formBuilder.group({
              id: [Date.now()],
              question: ["", Validators.required],
              verif: "verif",
              choix: this.formBuilder.array([]),
              urlPhoto: "",
            })
          );
          this.Listreader.push({ id: questions.value[taille].id, reader: "" });
          break;
        }
      }
      if (reponsesJustes.length == 0) {
        this.iziToast.show({
          message: this.translate.instant("shared_14_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      }
    } else
      this.iziToast.show({
        message: this.translate.instant("shared_13_error"),
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        backgroundColor: "#ff8fa3",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        overlay: true,
        overlayClose: true,
      });
  }

  public getChoix(question: FormControl): FormArray {
    return question.get("choix") as FormArray;
  }

  public getQuestions(): FormArray {
    return this.form.get("questions") as FormArray;
  }

  public processForm(form: FormGroup): void {
    this.form.value.training = this.id;
    let results = [];
    let reponsesJustes = [];
    this.submitted = true;
    if (this.form.invalid) {
      this.eror = true;
      return;
    }
    if (this.form.value.questions.length <= 2) {
      this.iziToast.show({
        message: this.translate.instant("shared_12_error"),
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        overlay: true,
        overlayClose: true,
      });
    } else {
      if (this.form.value.questions[length - 1] == undefined) {
        this.iziToast.show({
          message: this.translate.instant("shared_14_1_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      } else if (this.form.value.questions[length].choix.length < 2) {
        this.iziToast.show({
          message: this.translate.instant("shared_13_error"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
      } else if (this.form.value.questions[length].choix.length >= 2) {
        for (let c of this.form.value.questions[length].choix) {
          if (c.selected == true) {
            reponsesJustes.push(c);
          }
        }
        if (reponsesJustes.length == 0) {
          this.iziToast.show({
            message: this.translate.instant("shared_14_error"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        } else {
          for (let q of this.form.value.questions) {
            for (let c of q.choix) {
              if (c.selected == true) {
                results.push(c.text);
              }
            }
            q.answer = results;
            q.selected = false;
            results = [];
          }
          for (let question of this.form.value.questions) {
            question.urlPhoto = "";
          }
          let testChoixJustesFinales = 0;
          let questionNotsucces;
          for (let q of this.form.value.questions) {
            if (q.choix.length < 2 || q.answer.length == 0) {
              questionNotsucces = q;
              testChoixJustesFinales = testChoixJustesFinales + 1;
              break;
            }
          }
          if (testChoixJustesFinales > 0) {
            questionNotsucces.selected = true;
            this.iziToast.show({
              message: this.translate.instant("shared_14_1_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 9000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          } else {
          let date1 = Date.now();
          if ( date1> 0) {
            this.examen.createTest(this.form.value).subscribe((data) => {
              this.iziToast.show({
                message: this.translate.instant("shared_4_succes"),
                messageColor: "#386641",
                progressBarColor: "#6a994e",
                icon: "bi-check-circle",
                imageWidth: 45,
                position: "topRight",
                timeout: 5000,
                backgroundColor: "#dde5b6",
                transitionIn: "flipInX",
                transitionOut: "flipOutX",
              });
              this.updateEtatTraining();
            });
            let questionsPhotoListFinal = [];
            let indices = [];
            let listSendedInBack = [];
            for (let questionIndice of this.form.value.questions) {
              indices.push(questionIndice.id);
            }
            for (let indice of indices) {
              let endPhotoQuestion;
              for (let r of this.results) {
                if (indice == r.question) {
                  endPhotoQuestion = r;
                }
              }
              questionsPhotoListFinal.push(endPhotoQuestion);
            }
            for (let final of questionsPhotoListFinal) {
              if (final != undefined) {
                listSendedInBack.push(final);
              }
            }

            for (let r of listSendedInBack) {
              const form = new FormData();
              form.set("photo", r.photo);

              this.photoQuestion
                .createTraining(form, this.id, r.question)
                .subscribe((data) => {});
            }
            this.goToList();
          } else {
            this.iziToast.show({
              message: this.translate.instant("shared_19_error"),
              messageColor: "#800f2f",
              titleColor: "#800f2f",
              progressBarColor: "#c9184a",
              icon: "bi-exclamation-diamond",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#ff8fa3",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
              overlay: true,
              overlayClose: true,
            });
          }
        }}
      }
    }
  }
  goToList() {
    this.router.navigate(["/admin/questionnaires"]);
  }

  public removeChoix(question: FormControl, nickname: FormControl): void {
    this.removeFromCollection(this.getChoix(question), nickname);
  }

  public removeQuestion(question: FormControl): void {
    this.removeFromCollection(this.getQuestions(), question);
    this.modalService.dismissAll();
  }

  private removeFromCollection(collection: FormArray, item: FormControl): void {
    collection.removeAt(collection.controls.indexOf(item));
  }

  changeSelected(test) {
    if (test.value.selected == false) {
      test.value.selected = true;
    } else test.value.selected = false;
  }

  updateEtatTraining() {
    this.trainingService.updateEtatCreateExamen(this.id).subscribe((e) => {});
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  addImg(event, question, Quest) {
    let photosAndOneQuestion;
    if (event.target.files[0] != undefined && event.target.files[0].size < 1048576) {
      let indice = this.Listreader.indexOf({ id: question, reader: "" });
      if (indice != -1) {
        this.Listreader[indice] = { id: question, reader: event.target.result };
      }
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        const obj = event.target.result;
        Quest.value.urlPhoto = obj;

        for (let v of this.Listreader) {
          if (v.id == question) {
            v.reader = obj;
            this.reseler = obj;
            this.testInput2.nativeElement.focus();
            this.testInput.nativeElement.focus();
          }
        }
      };
      photosAndOneQuestion = {
        question: question,
        photo: event.target.files[0],
      };
    } else {
      photosAndOneQuestion = { question: question, photo: undefined };
      Quest.value.urlPhoto = "";
    }

    if(event.target.files[0].size < 1048576 || event.target.files[0] == undefined)
    this.results.push(photosAndOneQuestion);
    else
    this.iziToast.show({
      message: this.translate.instant("eroor_size_photo"),  // "eroor_size_photo"
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }
}
