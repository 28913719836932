import { Component, OnInit } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CertificateService } from "src/app/_services/certificate.service";
import { InstallerService } from "src/app/_services/installer.service";
import { StatisticsService } from "src/app/_services/statistics.service";
import { TrainingService } from "src/app/_services/training.service";
import Chart from 'chart.js';
import { MyModalComponent } from "src/app/admin/templ-admin/dashboard/my-modal/my-modal.component";

@Component({
  selector: "app-dashboard-distributer",
  templateUrl: "./dashboard-distributer.component.html",
  styleUrls: ["./dashboard-distributer.component.css"],
})
export class DashboardDistributerComponent implements OnInit {
  
  trainings;
  nbrTrainings;
  nbrValidCertificates;
  nbrExpiredCertificates;
  nbrInstallers;
  ValidCertificates: any;
  Expiredcertficates;
  dateFin;
  date = new Date();
  installers: any;
  user :any
  numberSuceesUsers :string
  numberFailedUsers :string
  list
  idTraining
  lang
  usersNames 
  public chart: any;
  closeResult: string;
  sizeListTrainings
  constructor(
    private trainingService: TrainingService,
    private certficateService: CertificateService,
    private installerService: InstallerService,
    private translate: TranslateService,
    private statisticsService :StatisticsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.lang =localStorage.getItem('currentLang')
    this.getTrainings();
    this.getCertficates();
    this.getInstallers();
    this.statisticsService.getTrainingsDisributors().subscribe((e)=> {
      this.list=e
      this.idTraining=this.list[0]._id
      this.sizeListTrainings = this.list.length
      this.createChart();
     
    })
  }

  getTrainings() {
    this.trainingService.getTrainingsByDistrubter().subscribe((e) => {
      this.trainings = e;
      this.nbrTrainings = this.trainings.length;
    });
  }

  getCertficates() {
    this.certficateService.getCertificatesByUser().subscribe((data) => {
      this.ValidCertificates = data;
      this.nbrValidCertificates = this.ValidCertificates.length;
      let results = [];
      for (let c of this.ValidCertificates) {
        this.dateFin = c.dateEndExipred;
        if (
          this.compareDate(
            this.date.toISOString().substring(0, 10),
            this.dateFin.toString().substring(0, 10)
          ) == true
        ) {
          results.push(c);
        }
      }
      this.Expiredcertficates = results;
      this.nbrExpiredCertificates = this.Expiredcertficates.length;
    });
  }

  compareDate(d1, d2) {
    if (d1 > d2) return true;
    else return false;
  }

  getInstallers() {
    this.installerService.getInstallersByDistrubuters().subscribe((data) => {
      this.installers = data;
      this.nbrInstallers = this.installers.length;
    });
  }

  createChart(){
    let language = localStorage.getItem('currentLang')
    let title
    let diagramTitle
    let yAxesLabel
    
    this.statisticsService.statsForTrainingInDistributor(this.idTraining).subscribe((e) => {
      this.user = e
      title = this.user[3]
      switch(language) {
        case 'fr':
          diagramTitle = "Diagramme à barres verticales"
          yAxesLabel = "Nombre des utilisateurs"
          break;
        case 'en':
          diagramTitle = "Vertical bar chart"
          yAxesLabel = "Number of users"
          break;
        case 'de':
          diagramTitle = "Vertikales Balkendiagramm"
          yAxesLabel = "Anzahl der Nutzer"
          break;
        case 'nl':
          diagramTitle = "Verticaal staafdiagram"
          yAxesLabel = "Aantal gebruikers"
          break;
        default:
          diagramTitle = "Diagramme à barres verticales"
          yAxesLabel = "Nombre des utilisateurs"

      }
      const ctx = document.getElementById('myChart');
    this.chart = new Chart(ctx, {
      type: 'bar', //this denotes tha type of chart

    
      
      data: {// values on X-Axis
        labels: [ title ], 
	       datasets: [
          {
            // number users assigned in training
            label: this.translate.instant("Assigned"), 
            data: [this.user[0].length],
            backgroundColor: 'rgb(54, 162, 235, 0.5)',
            borderWidth: 1

          },
          {
            // number users succes in training
            label: this.translate.instant("Successful"), 
            data: [this.user[1].length],
            backgroundColor: 'rgb(75, 192, 192, 0.5)',
            borderWidth: 1,

          } ,

          {
             // number users failed in training
            label: this.translate.instant("Failed"),
            data: [this.user[2].length],
          
            backgroundColor: 'rgb(255, 99, 132, 0.5)',  
            borderWidth: 1

          }  
          
        ],

      },
      
      options: {
        clickable : true,
       
        onClick: (evt, activeElements) => {
          let  modalRef
              if (activeElements.length > 0) {
                const datasetIndex = this.chart.getElementAtEvent(evt)[0]._datasetIndex;
                switch(this.chart.data.datasets[datasetIndex].label) {
                  case this.translate.instant("Assigned"):
                    this.usersNames = this.user[0]
                    modalRef= this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Assigned");
                    modalRef.componentInstance.content = this.usersNames;
                    break;
                  case this.translate.instant("Successful"):
                    this.usersNames = this.user[1]
                     modalRef = this.modalService.open(MyModalComponent);
                     modalRef.componentInstance.title = this.translate.instant("Successful");
                     modalRef.componentInstance.content = this.usersNames;
                    break;
                  case this.translate.instant("Failed"):
                    this.usersNames = this.user[2]
                    modalRef = this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Failed");
                    modalRef.componentInstance.content = this.usersNames;
                    break;
                 
                  default:
                    this.usersNames = this.user[0]
                    modalRef = this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Assigned");
                    modalRef.componentInstance.content = this.usersNames;
          
                }
              }
            },
          
        aspectRatio:1.5,
        scales: {
          xAxes: [{
              barPercentage: 0.5
          }],
          yAxes: [{
            ticks: {
                beginAtZero: true,
                stepSize: 1
               
            },
        
        scaleLabel: {
          display: true,
          labelString: yAxesLabel
        }
        }]
      },
        responsive: true,
        title: {
          display: true,
          text: diagramTitle
        }
      }
     
    })
      
    
  })
  }

  onChange(l) {
    this.chart.destroy()
    this.idTraining=l._id
    localStorage.setItem("idTraining",this.idTraining)
    this.createChart()
}

open(content) {
  this.modalService
    .open(content, { ariaLabelledBy: "modal-basic-title" })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return "by pressing ESC";
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return "by clicking on a backdrop";
  } else {
    return `with: ${reason}`;
  }
}


}
