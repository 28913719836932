import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultTestService {
  private _resultlUrl=`${environment.baseUrl}/results` 
  constructor(private http: HttpClient) { }

  SaveScore(id,score):Observable<Object> {
    return this.http.get<any>(`${this._resultlUrl}/${id}/${score}`);
  }

  getscores() :Observable <any>
  {
   return this.http.get(`${this._resultlUrl}`);
  }

  getVerifTest(id :string) :Observable <any>
      {
       return this.http.get(`${this._resultlUrl}/verifTest/${id}`);
      }

      getResultTest(id :string) :Observable <any>
      {
       return this.http.get(`${this._resultlUrl}/getResultByUser/${id}`);
      }

      reVerifPassedTest(id :string) :Observable <any>
      {
       return this.http.get(`${this._resultlUrl}/repassageTest/${id}`);
      }

      getStartTest(id) :Observable <any>
      {
       return this.http.get(`${this._resultlUrl}/start/testActual/${id}`);
      }
}
