import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingService } from 'src/app/_services/training.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  videos
  trainings;
  taille
  private upload=`${environment.uploadUrl}`
  id: any;
  constructor(private route:ActivatedRoute,private trainingService :TrainingService,private sanitization: DomSanitizer,private router: Router) { }

  ngOnInit(): void {
    this.getTrainings()
  }


  getTrainings(){ 
    this.id = this.route.snapshot.params['id']
    this.trainingService.getTrainingById(this.id).subscribe(async data=>{
    this.trainings=data
    this.videos =this.trainings.video
    this.taille=this.videos.length
    this.videos=this.videos.map(p=>{
      p.filename=this.sanitization.bypassSecurityTrustResourceUrl(this.upload+p.filename);
   return p;
   })
 
  })   
  }

  detailsTraining(id,id1)
  {
    this.router.navigate(['installateur/formations/details',id,id1])
  }


  voirTraining(id,id1)
  {
    this.router.navigate(['installateur/formations/voir',id,id1])
  }
  vidEnded() {
    //this.leavePage();
}

}
