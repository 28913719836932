import { Test } from "./test";
import { User } from "./user";

export class Certificate {
    id : string;
    _id:string;
    user:  User ;
    test :  Test ;
    firstDate :Date;
    DateValidation :Date;
    share :Boolean
 
}
