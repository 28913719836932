import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Training } from 'src/app/_models/training';
import { ExamenServiceService } from 'src/app/_services/examen-service.service';
import { InstallerTrainingsService } from 'src/app/_services/installer-trainings.service';
import { InstallerService } from 'src/app/_services/installer.service';
import { TrainingService } from 'src/app/_services/training.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit {

  videos
  trainings;
  taille
  test
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  dateTestExpiration
  statut=null
  title =""
  date ="";
  dateExipred=""
  testExpiration
  dateTestExipration: any;
  recentResultsFiltred: any;
  lengthTraining
  lenghtRecentResultsFiltred
  private upload=`${environment.uploadUrl}`
 
  constructor(private trainingService :TrainingService,private sanitization: DomSanitizer,private router: Router,private examenService :ExamenServiceService,
    private installerTrainingsService :InstallerTrainingsService) { }

  ngOnInit(): void {
    this.getTrainings()
  }


  getTrainings(){ 
   this.trainingService.getTrainingsByInstaller().subscribe(e=>{
    this.trainings=e
    this.lengthTraining=this.trainings.length
    this.recentResultsFiltred=this.trainings
    this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length
        
  })
}

  detailsTraining(id)
  {
    this.router.navigate(['installateur/formations/details',id])
  }


  voirTraining(id,id1)
  {
    this.router.navigate(['installateur/formations/voir',id,id1])
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getTrainings();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTrainings();
  }
  
  getTestbyTraining(tarining :Training)
  {
    this.examenService.getTestByTraining(tarining._id).subscribe(e=>{
      this.dateTestExpiration=e
    })

  }
  
  // filter
  search() {
    if (this.title != "") {
      this.trainings = this.recentResultsFiltred
      this.trainings = this.trainings.filter((res) => {
        return res.training.title
          .toLocaleLowerCase()
          .match(this.title.toLocaleLowerCase());
      });
      this.page=1
      this.lenghtRecentResultsFiltred=this.trainings.length

      
    } else if (this.title == "") {
      this.recentResultsFiltred
      this.trainings = this.recentResultsFiltred
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      
     }
   
  }


  searchBydate() {
    this.installerTrainingsService.searchTrainingByDate(this.date).subscribe((e) => {
      if (this.date == undefined) {
        this.getTrainings();
      } else {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

     
      }
    });
  }

  searchByStatut() {
    this.installerTrainingsService.searchTrainingByStatut(this.statut).subscribe((e) => {
      this.trainings = e;
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

    });
  }

  searchByDateExipration() {
    this.installerTrainingsService.searchTrainingByDateExpiration(this.dateExipred).subscribe((e) => {
      this.trainings = e;
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

    });
  }
  
  searchByDateStatut() {
    this.installerTrainingsService
      .searchTrainingByDateAndStatut(this.date, this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length


      });
  }

  searchByDateExpirationTestAndStatut()
  {
    this.installerTrainingsService.searchByDateExpirationTestAndStatut
      (this.dateExipred, this.statut)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length


      });
  }

  searchByDateExpirationTestAndDateExpirationTraining()
  {
    this.installerTrainingsService.searchByDateExpirationTestAndDateExpirationTraining
    (this.dateExipred, this.date)
    .subscribe((e) => {
      this.trainings = e;
      this.recentResultsFiltred=this.trainings
      this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length


    });
  }
  searchByDateAndStatutAndExpirationDate() {
    this.installerTrainingsService
      .searchByDateAndStatutAndExpirationDate(this.date, this.statut ,this.dateExipred)
      .subscribe((e) => {
        this.trainings = e;
        this.recentResultsFiltred=this.trainings
        this.lenghtRecentResultsFiltred=this.recentResultsFiltred.length

      });
  }
  
  searchAll() {
    if (this.statut == null && this.date == "" &&  this.dateExipred == "") {
      this.getTrainings();
      this.page=1;

    }
    else if (this.statut != null && this.date == ""  &&  this.dateExipred == ""){
      this.searchByStatut()
      this.page=1;

    }
    else if (this.statut == null && this.date !=""  &&  this.dateExipred == "")
    {
      this.searchBydate();
      this.page=1;

    }

    else if (this.statut == null && this.date ==""  &&  this.dateExipred != "")
    {
      this.searchByDateExipration();
      this.page=1;

    }
    else if (this.statut != null && this.date !=""  &&  this.dateExipred == "") {
      this.searchByDateStatut();
      this.page=1;
    }
    else if (this.statut != null && this.date ==""  &&  this.dateExipred != "") {
      this.searchByDateExpirationTestAndStatut();
      this.page=1;
    }

    else if (this.statut == null && this.date !=""  &&  this.dateExipred != "") {

      this.searchByDateExpirationTestAndDateExpirationTraining();
      this.page=1;
    }
    else

    {
      this.searchByDateAndStatutAndExpirationDate()
      this.page = 1
    }
  }

  cancelFilter()
  {
    this.title = ""
    this.date=""
    this.dateExipred=""
    this.statut = null;
    this.getTrainings()
  }
}
