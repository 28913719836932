import { Component, OnInit } from "@angular/core";
import {  Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { Ng2IzitoastService } from "ng2-izitoast";

@Component({
  selector: "app-templ-installer",
  templateUrl: "./templ-installer.component.html",
  styleUrls: ["./templ-installer.component.css"],
})
export class TemplInstallerComponent implements OnInit {
  selectedLang: any = "fr";
  clickTest;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService
  ) {
    if (localStorage && localStorage.getItem("currentLang")) {
      this.selectedLang = localStorage.getItem("currentLang");
    }
  }

  ngOnInit(): void {
    this.clickTest = localStorage.setItem("clickTest", "0");
    localStorage.setItem("result", "noResult");
    this.clickTest=localStorage.getItem("clickTest")
   console.log("clicked",this.clickTest)
  }
  logout() {
    this.clickTest = localStorage.getItem("clickTest");
    if(this.clickTest == '0')
    this.authService.logout();
    else
    this.iziToast.show({
      message: this.translate.instant("test_change_language_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }
  lang(l) {
    let url =this.router.url.split('/')
    console.log("url",url)
    this.clickTest = localStorage.getItem("clickTest");
    let result;
    result = localStorage.getItem("result");
    console.log("this.clickTest", this.clickTest);
    if (this.clickTest == "0") {
      if (result == "fail") {
        this.selectedLang = l;
        localStorage.setItem("currentLang", l);
        this.router.navigate(["installateur/formations"]);
        setTimeout(function () {
          window.location.reload();
        }, 300);
      } else if (result == "success") {
        this.selectedLang = l;
        localStorage.setItem("currentLang", l);
        this.router.navigate(["installateur/certificats"]);
        setTimeout(function () {
          window.location.reload();
        }, 300);
      } else {
        this.selectedLang = l;
        localStorage.setItem("currentLang", l);
        window.location.reload();
      }
    } else {
      this.iziToast.show({
        message: this.translate.instant("test_change_language_error"),
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        overlay: true,
        overlayClose: true,
      });
      if(url[2] != "test" )
      {
        localStorage.setItem("clickTest", "0");
        localStorage.setItem("currentLang", l);
        window.location.reload();
      }
    }
  }
  goToDashbord()
  {
    this.clickTest = localStorage.getItem("clickTest");
    if(this.clickTest == '0')
    this.router.navigate(["installateur"])
    else
    this.iziToast.show({
      message: this.translate.instant("test_change_language_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }

  goToProfil()
  {
    this.clickTest = localStorage.getItem("clickTest");
    if(this.clickTest == '0')
    this.router.navigate(["installateur/profil"])
    else
    this.iziToast.show({
      message: this.translate.instant("test_change_language_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }

  goToTrainings()
  {
    this.clickTest = localStorage.getItem("clickTest");
    if(this.clickTest == '0')
    this.router.navigate(["installateur/formations"])
    else
    this.iziToast.show({
      message: this.translate.instant("test_change_language_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }

  goToCetificates()
  {
    this.clickTest = localStorage.getItem("clickTest");
    if(this.clickTest == '0')
    this.router.navigate(["installateur/certificats"])
    else
    this.iziToast.show({
      message: this.translate.instant("test_change_language_error"),
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      overlay: true,
      overlayClose: true,
    });
  }
}
