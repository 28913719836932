import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoQuestionService {

  private _photoQuestionURL=`${environment.baseUrl}/photoQuestion`
  constructor(private http: HttpClient) { }

getPhotosQuestions() :Observable <any> {
  return this.http.get(`${this._photoQuestionURL}`);
      }

     
 
      createTraining(phtoQuestion: any,idExamen,idQuestion):Observable<Object> {
        return this.http.post(`${this._photoQuestionURL}/${idExamen}/${idQuestion}`,phtoQuestion);
      }

      editPhtoQuestion(phtoQuestion: any,idExamen,idQuestion):Observable<Object> {
        return this.http.put(`${this._photoQuestionURL}/${idExamen}/${idQuestion}`,phtoQuestion);
      }
      deletePhtoQuestion(idExamen,idQuestion):Observable<Object> {
        return this.http.delete(`${this._photoQuestionURL}/${idExamen}/${idQuestion}`);
      }
 
}
