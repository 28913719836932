import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { TemplAdminComponent } from './admin/templ-admin/templ-admin.component';
import { AuthGuard } from './_guards/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TemplInstallerComponent } from './installer/templ-installer/templ-installer.component';
import { TrainingsComponent } from './installer/templ-installer/trainings/trainings.component';
import { ProfilComponent } from './installer/templ-installer/profil/profil.component';
import { DashboardComponent } from './admin/templ-admin/dashboard/dashboard.component';
import { InstallersComponent } from './admin/templ-admin/installers/installers.component';
import { TrainingsAdminComponent } from './admin/templ-admin/trainings-admin/trainings-admin.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddTrainingComponent } from './admin/templ-admin/trainings-admin/add-training/add-training.component';
import { UpdateTrainingComponent } from './admin/templ-admin/trainings-admin/update-training/update-training.component';
import { ProfilAdminComponent } from './admin/templ-admin/profil-admin/profil-admin.component';
import { DetailsTrainingComponent } from './installer/templ-installer/trainings/details-training/details-training.component';
import { DetailsComponent } from './admin/templ-admin/trainings-admin/details/details.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DistributerComponent } from './admin/templ-admin/distributer/distributer.component';
import { TemplDistributerComponent } from './distributer/templ-distributer/templ-distributer.component';
import { ProfilDistributerComponent } from './distributer/templ-distributer/profil-distributer/profil-distributer.component';
import { TrainingsDistribiteurComponent } from './distributer/templ-distributer/trainings-distribiteur/trainings-distribiteur.component';
import { AddInstallerComponent } from './admin/templ-admin/installers/add-installer/add-installer.component';
import { UpdateInstallerComponent } from './admin/templ-admin/installers/update-installer/update-installer.component';
import { AddDistributerComponent } from './admin/templ-admin/distributer/add-distributer/add-distributer.component';
import { UpdateDistributerComponent } from './admin/templ-admin/distributer/update-distributer/update-distributer.component';
import { TestsComponent } from './admin/templ-admin/tests/tests.component';
import { AddTestComponent } from './admin/templ-admin/tests/add-test/add-test.component';
import { UpdateTestComponent } from './admin/templ-admin/tests/update-test/update-test.component';
import { DetailsTestComponent } from './admin/templ-admin/tests/details-test/details-test.component';
import { TestsInstallerComponent } from './installer/templ-installer/tests-installer/tests-installer.component';
import { DetailsCertificateComponent } from './components/details-certificate/details-certificate.component';
import { CertificatesInstallerComponent } from './installer/templ-installer/certificates-installer/certificates-installer.component';
import { InstallersByDistrubuterComponent } from './distributer/templ-distributer/installers-by-distrubuter/installers-by-distrubuter.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ShareTrainingComponent } from './admin/templ-admin/trainings-admin/share-training/share-training.component';
import { DetailsTrainingDistributerComponent } from './distributer/templ-distributer/trainings-distribiteur/details-training-distributer/details-training-distributer.component';
import { ShareTrainingDistributerComponent } from './distributer/templ-distributer/trainings-distribiteur/share-training-distributer/share-training-distributer.component';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { ProgressAnnotationService, ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { ListCertificatesComponent } from './admin/templ-admin/installers/list-certificates/list-certificates.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TestsDistributerComponent } from './distributer/templ-distributer/tests-distributer/tests-distributer.component';
import { CertificatesDistributerComponent } from './distributer/templ-distributer/certificates-distributer/certificates-distributer.component';
import { ListCeritficatesInsatllersByDistributerComponent } from './distributer/templ-distributer/installers-by-distrubuter/list-ceritficates-insatllers-by-distributer/list-ceritficates-insatllers-by-distributer.component';
import { SearchPipe } from './_helpers/search.pipe';
import { ListCertificatesDistributeursComponent } from './admin/templ-admin/distributer/list-certificates-distributeurs/list-certificates-distributeurs.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { DashbordInstallateurComponent } from './installer/templ-installer/dashbord-installateur/dashbord-installateur.component';
import { DashboardDistributerComponent } from './distributer/templ-distributer/dashboard-distributer/dashboard-distributer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuhtoriseComponent } from './componets/auhtorise/auhtorise.component';
import { CallbackComponent } from './componets/callback/callback.component';
import { MyModalComponent } from './admin/templ-admin/dashboard/my-modal/my-modal.component';

import { ListCertificatesExipresComponent } from './admin/templ-admin/installers/list-certificates-exipres/list-certificates-exipres.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TemplAdminComponent,
    TemplInstallerComponent,
    TrainingsComponent,
    ProfilComponent,
    DashboardComponent,
    InstallersComponent,
    TrainingsAdminComponent,
    AddTrainingComponent,
    UpdateTrainingComponent,
    ProfilAdminComponent,
    DetailsTrainingComponent,
    DetailsComponent,
    DistributerComponent,
    TemplDistributerComponent,
    ProfilDistributerComponent,
    TrainingsDistribiteurComponent,
    AddInstallerComponent,
    UpdateInstallerComponent,
    AddDistributerComponent,
    UpdateDistributerComponent,
    TestsComponent,
    AddTestComponent,
    UpdateTestComponent,
    DetailsTestComponent,
    TestsInstallerComponent,
    DetailsCertificateComponent,
    CertificatesInstallerComponent,
    InstallersByDistrubuterComponent,
    ShareTrainingComponent,
    DetailsTrainingDistributerComponent,
    ShareTrainingDistributerComponent,
    ListCertificatesComponent,
    TestsDistributerComponent,
    CertificatesDistributerComponent,
    ListCeritficatesInsatllersByDistributerComponent,
    SearchPipe,
    ListCertificatesDistributeursComponent,
    DashbordInstallateurComponent,
    DashboardDistributerComponent,
    AuhtoriseComponent,
    CallbackComponent,
    MyModalComponent,
    ListCertificatesExipresComponent
      
    
    
   
    
   
 
    
  ],
  imports: [
    NgbCarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularEditorModule ,
    FormsModule,
    NgSelectModule ,
    RecaptchaV3Module,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2IziToastModule,
    NgxPaginationModule,
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    ToastrModule.forRoot(), // ToastrModule added
    BrowserModule, ProgressBarModule,
    UiSwitchModule.forRoot({
      color: '#e73458',
      
     
    }),
  ],
  providers: [AuthGuard,{
    provide :HTTP_INTERCEPTORS,
    useClass :TokenInterceptorService,
    multi:true
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },
  ProgressAnnotationService
],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

