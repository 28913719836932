import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgSelectConfig } from "@ng-select/ng-select";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/_models/user";
import { DistributerService } from "src/app/_services/distributer.service";
import { InstallerService } from "src/app/_services/installer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-update-installer",
  templateUrl: "./update-installer.component.html",
  styleUrls: ["./update-installer.component.css"],
})
export class UpdateInstallerComponent implements OnInit {
  types = ["Classic", "Premium"];

  id: string;
  installer: User = new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  d;
  distrubters;
  dis;

  constructor(
    private installerService: InstallerService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,

    private distrubterService: DistributerService,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = this.translate.instant(
      "distributeur_card_table"
    );
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];

    (this.d = this.installerService
      .getInstallerById(this.id)
      .subscribe(async (data) => {
        this.installer = data;
        this.dis = this.installer.distrubter;
      })),
      (this.registerForm = this.formBuilder.group(
        {
          firstName: ["", [Validators.required, Validators.minLength(3)]],
          lastName: ["", [Validators.required, Validators.minLength(3)]],
          email: ["", [Validators.required, Validators.email]],
          type: ["", Validators.required],
          distrubter: [null, Validators.required],
        },
        {}
      ));
    this.getDistrubters();
  }
  get f() {
    return this.registerForm.controls;
  }

  getDistrubters() {
    this.distrubterService.getDistributers().subscribe((e) => {
      this.distrubters = e;
    });
  }

  update() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.registerForm.value.password = "";
    this.installerService
      .EditInstaller(this.id, this.registerForm.value)
      .subscribe((data) => {
        this.iziToast.show({
          message: this.translate.instant("shared_4_1_succes"),
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      });
  }
  goToList() {
    this.router.navigate(["/admin/installateurs"]);
  }
  onSubmit() {
    this.update();
  }
}
