import { User } from "./user";

export class Training {

    id : string;
    _id:string;
    title :string;
    description :string;
    conclusion :string;
    date :Date;
    video : [{
    _id :string;    
    originalname:string ;
    filename:string ;
    descriptionVideo:String ;

    }]
    installer:  User ;
    distributer :  User ;
    dateConvertit : string;
    statut :string;
    type
    choix :[{text:string,description:string,selected : boolean  }];
    etat
    idInTrainingList : string ;
    dateEnd :Date;

    dateFinTest;
  
}
