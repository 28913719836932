import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Ng2IzitoastService } from "ng2-izitoast";
import { ToastrService } from "ngx-toastr";
import { DistributerService } from "src/app/_services/distributer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-distributer",
  templateUrl: "./add-distributer.component.html",
  styleUrls: ["./add-distributer.component.css"],
})
export class AddDistributerComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  eroorMessage: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private distributerService: DistributerService,
    private translate: TranslateService,
    public iziToast: Ng2IzitoastService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, Validators.minLength(3)]],
        lastName: ["", [Validators.required, Validators.minLength(3)]],
        email: ["", [Validators.required, Validators.email]],
        company: ["", [Validators.required, , Validators.maxLength(30)]],
      },
      {}
    );
  }
  get f() {
    return this.registerForm.controls;
  }

  save() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    this.distributerService
      .createDistributer(this.registerForm.value)
      .subscribe(
        (data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.goToList();
        },
        (error) => {
          this.eroorMessage = error.error;
          if (this.eroorMessage == "email address is already registered") {
            this.eroorMessage = this.translate.instant("error_existing_email");
          }
          this.iziToast.show({
            message: this.eroorMessage,
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
        }
      );
  }
  goToList() {
    this.router.navigate(["/admin/distributeurs"]);
  }
  onSubmit() {
    this.save();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.router.navigate(["/admin/distributeurs"]);
  }
}
