import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  firstName;
  lastName;
  role;
  email;

  user :User=new User();


  constructor(private autenticationService :AuthenticationService
    ) { 
   
  }

  ngOnInit(): void {
    this.autenticationService.apimeFunction(localStorage.getItem("token_auth")).subscribe((result) => {
      if(result && result["data"]){
        this.firstName = result["data"].firstName 
         this.lastName = result["data"].lastName
     this.role = result["data"].role_slug
         this.email = result["data"].email
        }
  }, (err) => {
    //console.log(err)
    return false;
  });

  }


  
}
