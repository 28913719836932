import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DistributerService } from 'src/app/_services/distributer.service';
import { InstallerService } from 'src/app/_services/installer.service';
import { TrainingService } from 'src/app/_services/training.service';
import Chart from 'chart.js';
import { StatisticsService } from 'src/app/_services/statistics.service';
import { TranslateService } from "@ngx-translate/core";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyModalComponent } from './my-modal/my-modal.component';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('myChart') chartRef: ElementRef;
  sizeListTrainings
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 2;
  tableSizes: any = [3, 6, 9, 12];
  numberTotalTrainings
  numberTotalTrainingsArchives
  numberTotalInstallers
  numberTotalDistributers
  user :string
  numberSuceesUsers :string
  numberFailedUsers :string
  list
  idTraining
  lang
  usersNames 
  public chart: any;
  constructor(private trainingService :TrainingService,
    private installerService :InstallerService,
    private DistributerService :DistributerService,
    private translate: TranslateService,
    private statisticsService :StatisticsService,
    private modalService: NgbModal,
    ) { 
      
    }

  ngOnInit(): void {   
   this.lang =localStorage.getItem('currentLang')
    this.numberTrainings()
    this.numberInstallers()
    this.numberDistributers()
    this.numberTrainingsArchives()

    this.statisticsService.getTrainings().subscribe((e)=> {
      this.list=e
      this.idTraining=this.list[0]._id
      this.sizeListTrainings = this.list.length
      this.createChart();
     
    }

    )
   
    
  }
  listTrainings()
  {
    this.statisticsService.getTrainings().subscribe((e)=> {
      this.list=e
    }

    )
  }
  numberTrainings()
  {
    this.trainingService.numberTrainings().subscribe(
      (e) => (this.numberTotalTrainings = e)
    )
  }

  numberTrainingsArchives()
  {
    this.trainingService.numberTrainingsArchives().subscribe(
      (e) => (this.numberTotalTrainingsArchives = e)
    )
  }

  numberInstallers()
  {
    this.installerService.numberInstallers().subscribe(
      (e) => (this.numberTotalInstallers = e)
    )
  }

  numberDistributers()
  {
    this.DistributerService.numberDistributers().subscribe(
      (e) => (this.numberTotalDistributers = e)
    )
  }
  createChart(){
    let language = localStorage.getItem('currentLang')
    let title
    let diagramTitle
    let yAxesLabel
    
    this.statisticsService.statsForTraining(this.idTraining).subscribe((e) => {
      //this.tests = e;
      this.user = e
      title = this.user[3]
      switch(language) {
        case 'fr':
          diagramTitle = "Diagramme à barres verticales"
          yAxesLabel = "Nombre des utilisateurs"
          break;
        case 'en':
          diagramTitle = "Vertical bar chart"
          yAxesLabel = "Number of users"
          break;
        case 'de':
          diagramTitle = "Vertikales Balkendiagramm"
          yAxesLabel = "Anzahl der Nutzer"
          break;
        case 'nl':
          diagramTitle = "Verticaal staafdiagram"
          yAxesLabel = "Aantal gebruikers"
          break;
        default:
          diagramTitle = "Diagramme à barres verticales"
          yAxesLabel = "Nombre des utilisateurs"

      }
      const ctx = document.getElementById('myChart');
    this.chart = new Chart(ctx, {
      type: 'bar', //this denotes tha type of chart

    
      
      data: {// values on X-Axis
        labels: [ title ], 
	       datasets: [
          {
            // number users assigned in training
            label: this.translate.instant("Assigned"), 
            data: [this.user[0].length],
            backgroundColor: 'rgb(54, 162, 235, 0.5)',
            borderWidth: 1

          },
          {
            // number users succes in training
            label: this.translate.instant("Successful"), 
            data: [this.user[1].length],
            backgroundColor: 'rgb(75, 192, 192, 0.5)',
            borderWidth: 1,

          } ,

          {
             // number users failed in training
            label: this.translate.instant("Failed"),
            data: [this.user[2].length],
          
            backgroundColor: 'rgb(255, 99, 132, 0.5)',  
            borderWidth: 1

          }  
          
        ],

      },
      
      options: {
        clickable : true,
       
        onClick: (evt, activeElements) => {
          let  modalRef
              if (activeElements.length > 0) {
                const datasetIndex = this.chart.getElementAtEvent(evt)[0]._datasetIndex;
                switch(this.chart.data.datasets[datasetIndex].label) {
                  case this.translate.instant("Assigned"):
                    this.usersNames = this.user[0]
                    modalRef= this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Assigned");
                    modalRef.componentInstance.content = this.usersNames;
                    break;
                  case this.translate.instant("Successful"):
                    this.usersNames = this.user[1]
                     modalRef = this.modalService.open(MyModalComponent);
                     modalRef.componentInstance.title = this.translate.instant("Successful");
                     modalRef.componentInstance.content = this.usersNames;
                    break;
                  case this.translate.instant("Failed"):
                    this.usersNames = this.user[2]
                    modalRef = this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Failed");
                    modalRef.componentInstance.content = this.usersNames;
                    break;
                 
                  default:
                    this.usersNames = this.user[0]
                    modalRef = this.modalService.open(MyModalComponent);
                    modalRef.componentInstance.title = this.translate.instant("Assigned");
                    modalRef.componentInstance.content = this.usersNames;
          
                }
              }
            },
          
        aspectRatio:1.5,
        scales: {
          xAxes: [{
              barPercentage: 0.5
          }],
          yAxes: [{
            ticks: {
                beginAtZero: true,
                stepSize: 1
               
            },
        
        scaleLabel: {
          display: true,
          labelString: yAxesLabel
        }
        }]
      },
        responsive: true,
        title: {
          display: true,
          text: diagramTitle
        }
      }
     
    })
      
    
  })
  }

  onChange(l) {
    this.chart.destroy()
    this.idTraining=l._id
    localStorage.setItem("idTraining",this.idTraining)
    this.createChart()
}

open(content) {
  this.modalService
    .open(content, { ariaLabelledBy: "modal-basic-title" })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return "by pressing ESC";
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return "by clicking on a backdrop";
  } else {
    return `with: ${reason}`;
  }
}
}
