import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InstallerService } from "src/app/_services/installer.service";

@Component({
  selector: "app-installers-by-distrubuter",
  templateUrl: "./installers-by-distrubuter.component.html",
  styleUrls: ["./installers-by-distrubuter.component.css"],
})
export class InstallersByDistrubuterComponent implements OnInit {
  valueChange = false;
  installers;
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  lenghtListInstallers
  premnuim = false;
  allInstallers = false;
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  recentResultsInstallers: any;

  constructor(
    private installerService: InstallerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getInstallers();
  }

  getInstallers() {
    this.allInstallers = true;
    this.premnuim = false;
    this.allInstallers=true
    this. premnuim=false
     this.installerService.getInstallersByDistrubutersSecond().subscribe(data =>{

       this.installers=data
       this.recentResultsInstallers=this.installers
       this.lenghtListInstallers=this.installers.length
        for(let i of this.installers)
       {
        if(i.premium){
          i.type = "Premium"
         }
         if(!i.premium){
          i.type = "Classic"
         }
        }
     }
     )
  }

  getInstallerswithTypePremnuim() {
    this.page = 1;
    this.allInstallers=false
    this. premnuim=true
    this.installers=this.installers.filter(x => x.premium)
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getInstallers();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getInstallers();
  }

  displayListCertificatesbyInstallater(id) {
    this.router.navigate(["distributeur/installateurs/liste-certificats", id]);
  }
search()
  {
    if(this.firstName != "" || this.lastName != "" || this.email != "" || this.type != "")
     {
      this.installers = this.recentResultsInstallers
      
      this.installers = this.installers.filter(res => {
        return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) ||
          res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) ||
          res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase()) ||
          res.type.toLocaleLowerCase().match(this.type.toLocaleLowerCase()) 
      });
      
    this.lenghtListInstallers = this.installers.length;
    this.page=1
    
     }
     else if(this.firstName == "" && this.lastName == "" && this.email == "" && this.type == ""  )
     {
      this.installers = this.recentResultsInstallers
      this.lenghtListInstallers = this.installers.length;
  
     }
  }

}
