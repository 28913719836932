<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">
            {{ "questionaire_title" | translate }}
          </h1>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a href="#" class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <h5 class="mb-0">{{ "questionaire_card_title" | translate }}</h5>
      </div>
      <div *ngIf="lengthListTests > 0" class="table-responsive">
        <table class="table table-hover table-nowrap">
          <thead
            style="background: linear-gradient(to bottom, #3c4d5d, #29435c)"
          >
            <tr>
              <th class="th" scope="col">
                {{ "table_column_13" | translate }}
              </th>
              <th class="th" scope="col">
                {{ "table_column_15" | translate }}
              </th>
              <th class="th" scope="col">{{ "table_column_7" | translate }}</th>
              <!--<th class="th" scope="col">{{ "table_column_8" | translate }}</th>-->
              <th class="th" scope="col">
                {{ "table_column_16" | translate }}
              </th>
              <th class="th" scope="col">{{ "shared_1" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let i of tests.reverse()
                  | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };
                let k = index
              "
            >
              <td>
                <a class="text-heading font-semibold">
                  {{ i.nom }}
                </a>
              </td>
              <td>{{ i.training.title }}</td>

              <td>{{ i.dateDebut | date : "dd/MM/yyyy HH:mm" }}</td>
              <!--<td>{{ i.dateFin | date : "dd/MM/yyyy" }} 23:59</td>-->
              <td>{{ i.temps }} minutes</td>

              <td>
                <button
                  [disabled]="i.training.etat ? true : null"
                  (click)="update(i._id)"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="{{ 'btn_hover_3' | translate }}"
                  ></i>
                </button>
                <button
                  [disabled]="i.training.etat ? true : null"
                  (click)="open(content)"
                  type="button"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                >
                  <i
                    class="bi bi-trash"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="{{ 'btn_hover_8' | translate }}"
                  ></i>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">
                        {{ "popup_confiramtion_suppression" | translate }}
                      </h4>
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <label
                          >{{ "questionaire_delete_alert" | translate }}
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-dark"
                        (click)="deleteTest(i)"
                      >
                        {{ "btn_hover_8" | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-dark1"
                        (click)="modal.close('Save click')"
                      >
                        {{ "btn_2" | translate }}
                      </button>
                    </div>
                  </ng-template>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer border-0 py-5">
          <div class="d-flex justify-content-center">
            <pagination-controls
              *ngIf="lengthListTests > 10"
              class="my-pagination"
              previousLabel="{{ 'shared_5_pagination' | translate }}"
              nextLabel="{{ 'shared_6_pagination' | translate }}"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
      <div *ngIf="lengthListTests == 0" class="table-responsive">
        <p style="padding-bottom: 40px; padding-top: 15px">
          {{ "questionaire_error_pagination" | translate }}
        </p>
      </div>
    </div>
  </div>
</main>
