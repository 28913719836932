import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { Test } from "src/app/_models/test";
import { ExamenServiceService } from "src/app/_services/examen-service.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-tests",
  templateUrl: "./tests.component.html",
  styleUrls: ["./tests.component.css"],
})
export class TestsComponent implements OnInit {
  closeResult = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  tests: Test[] = [];
  trainings;
  lengthListTests;
  constructor(
    private examenService: ExamenServiceService,
    private modalService: NgbModal,
    private router: Router,
    private translate: TranslateService,
    public iziToast: Ng2IzitoastService
  ) {}

  onTableDataChange(event: any) {
    this.page = event;
    this.getTests();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getTests();
  }
  ngOnInit(): void {
    this.getTests();
  }
  getTests() {
    this.examenService.getTests().subscribe((e) => {
      this.tests = e;
      this.lengthListTests = this.tests.length;
    });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  deleteTest(test: Test) {
    this.examenService.deleteTest(test._id).subscribe((e) => {
      this.iziToast.show({
        message: this.translate.instant("shared_17_success_delete"),
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      this.getTests();
    });
    this.modalService.dismissAll();
  }

  update(id) {
    this.router.navigate(["admin/questionnaires/modification", id]);
  }
}
