import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Training } from '../_models/training';

@Injectable({
  providedIn: 'root'
})
export class InstallerTrainingsService {

  //private _trainingURL="http://localhost:3200/api/trainings" 
  private _installertrainingsURL=`${environment.baseUrl}/installerTrainings`
  constructor(private http: HttpClient) { }

getTrainings() :Observable <any> {
  return this.http.get(`${this._installertrainingsURL}`);
      }

      getTrainingById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._installertrainingsURL}/${id}`);
    
      }   
      
      changeEtatVideoInTrue(id : string,id2:any):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/updateVideo/${id}/${id2}`);
      } 

      passExamen(id : string):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/passExamen/${id}`);
      } 

      getTrainingFromInsatllerTrainings(id : string):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/recuperateTraining/${id}`);
      }
      
      getTraining(id):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/all/${id}`);
      }

      // changement video a une autre 
      verifStatutVideoInCarousel(id : string,id2:any):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/verifStatutVideo/${id}/${id2}`);
      } 

      searchTrainingByStatut(statut : string):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByStatut/${statut}`);
      } 
      searchTrainingByDate(date : string):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDate/${date}`);
      } 

      searchTrainingByDateExpiration(dateExpiration : string):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDateExpiration/${dateExpiration}`);
      }
      
      searchTrainingByDateAndStatut(date : string ,statut : string):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDateAndStatut/${date}/${statut}`);
      } 

      searchByDateAndStatutAndExpirationDate(date : string ,statut : string ,expirationDate : string ):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDateAndStatutAndExpirationDate/${date}/${statut}/${expirationDate}`);
      } 

      searchByDateExpirationTestAndStatut(date : string ,statut : string  ):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDateExpirationTestAndStatut/${date}/${statut}`);
      } 

      searchByDateExpirationTestAndDateExpirationTraining(dateExpirationTest : string ,dateExpirationTraining : string  ):Observable<Training>{
        return this.http.get<Training>(`${this._installertrainingsURL}/searchByDateExpirationTestAndDateExpirationTraining/${dateExpirationTest}/${dateExpirationTraining}`);
      } 
      setGetCertificate(id):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/setGetCertificate/${id}`);
      }

      setChekTest(id):Observable<any>{
        return this.http.get<any>(`${this._installertrainingsURL}/setCheckTest/${id}`);
      }

}
