


<br>
<br>
<div style="float: left; padding-left: 3.3%">
    <button (click)="makePDF()"  class="btn btn-primary">
      <i class="bi bi-arrow-bar-left"></i> Télécharger



      
    </button>
  </div>
  <br>
  <br>
    <div #content  style="    padding-top: 5%;
    justify-content: center;
    margin-left: 15%;
    margin-right: 15%;
    padding-right: 5%;">
        <div class="cadre">
            <div class="certif">
                <!-- <img class="img" src="assets\images\logo-fineo.svg">  -->
                <span class="title">Certification given to</span>
                <br><br>
                <span style="font-size:1.8rem; font-family: Nunito;font-style: italic;"><i>{{firstName}} {{lastName}}</i></span>
                <br><br>
                <span style="font-size:1.8rem ;font-family: Nunito;font-weight:bold;color: #343a40;"><b>By Fineo Learning Platform for completion of:</b></span><br/><br/>
                <span style="font-size:1.8rem ;font-family: Nunito;font-style: italic;color: #E61946;"><i>{{title}}</i></span> <br/><br/>
                <span style="font-size:1.2rem ;font-family: Nunito;font-weight:bold;color: #343a40;">Completion date:{{datePris | date: 'dd/MM/yyyy'}}</span> <br/><br/>
                <span style="font-size:1.2rem ;font-family: Nunito;font-weight:bold;color: #343a40;">Expiration Date: {{exipredDate | date: 'dd/MM/yyyy'}}</span> <br/><br/> 

            </div>
        </div>
    </div>

