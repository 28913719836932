import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrainingsArchivesService {

  private _trainingArchivesURL=`${environment.baseUrl}/trainingsArchives`
  constructor(private http: HttpClient) { }

getTrainingsArchives() :Observable <any> {
  return this.http.get(`${this._trainingArchivesURL}`);
      }

      deleteTrainingArchive(id : string)
      {
        return this.http.delete(`${this._trainingArchivesURL}/${id}`);
      } 

}
