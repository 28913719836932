import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DistributerService } from "src/app/_services/distributer.service";
import { TrainingService } from "src/app/_services/training.service";
import { InstallerService } from "src/app/_services/installer.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Ng2IzitoastService } from "ng2-izitoast";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-share-training",
  templateUrl: "./share-training.component.html",
  styleUrls: ["./share-training.component.css"],
})
export class ShareTrainingComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [1, 6, 9, 12];
  id;
  training;
  installers: any;
  distrubters: any;
  toutesInstallers: any[] = [];
  toutesDistributers: any[] = [];
  registerForm: FormGroup;
  emailIns: string;
  emailDis: string;
  selectedAllInsatllers;
  deselectedAllInsatllers;
  selectedAllDistrubuters;
  deselectedAllDistrubutes;
  email = "";
  aa: boolean = false;
  titleTraining: any;

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private InstallerService: InstallerService,
    private distrubterService: DistributerService,
    public iziToast: Ng2IzitoastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let i = 0;
    let j = 0;
    this.id = this.route.snapshot.params["id"];
    this.trainingService.getTrainingById(this.id).subscribe(async (data) => {
      this.training = data;
      this.installers = this.training.installer;
      this.distrubters = this.training.distributer;
      this.titleTraining = this.training.title;
      this.toutesInstallers =
        await this.InstallerService.getInstallers().toPromise();
        this.toutesInstallers = this.toutesInstallers["data"]
      for (let i of this.toutesInstallers.keys()) {
        let index = this.installers.findIndex(
          (m) => Number(m) == this.toutesInstallers[i].id
        );
        this.toutesInstallers[i].selected = index != -1;
      }

      for (let t of this.toutesInstallers) {
        if (t.selected == true) {
          i = i + 1;
        } else i = i - 1;
      }
      if (i == this.toutesInstallers.length) {
        this.selectedAllInsatllers = false;
        this.deselectedAllInsatllers = true;
      } else {
        this.selectedAllInsatllers = true;
        this.deselectedAllInsatllers = false;
      }

      this.toutesDistributers = await this.distrubterService
        .getDistributers()
        .toPromise();
      for (let i of this.toutesDistributers.keys()) {
        let index = this.distrubters.findIndex(
          (m) => Number(m) == this.toutesDistributers[i].id
        );
        this.toutesDistributers[i].selected = index != -1;
      }

      for (let t2 of this.toutesDistributers) {
        if (t2.selected == true) {
          j = j + 1;
        } else {
          j = j - 1;
        }
      }

      if (j == this.toutesDistributers.length) {
        this.selectedAllDistrubuters = false;
        this.deselectedAllDistrubutes = true;
      } else {
        this.selectedAllDistrubuters = true;
        this.deselectedAllDistrubutes = false;
      }
      this.registerForm = this.formBuilder.group(
        {
          installer: [""],
          distributer: [""],
        },
        {}
      );
    });
  }

  shareTraining() {
    let resultsI = [];
    let resultsD = [];
    this.registerForm.value.installerdata = []
    this.registerForm.value.distributerdata = []
    for (let m of this.toutesInstallers) {
      if (m.selected == true) {
        resultsI.push(m.id);
        this.registerForm.value.installer = resultsI;
        this.registerForm.value.installerdata.push(m)
      }
    }
    for (let m of this.toutesDistributers) {
      if (m.selected == true) {
        resultsD.push(m.id);
        this.registerForm.value.distributer = resultsD;
        this.registerForm.value.distributerdata.push(m)
      }
    }
    if (resultsI.length == 0 && resultsD.length == 0)
      this.iziToast.show({
        message: this.translate.instant("shared_18_error"),
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        overlay: true,
        overlayClose: true,
      });
    else if (resultsI.length == 0 && resultsD.length > 0) {
      this.registerForm.value.installer = ["000000000000000000000000"];
      this.trainingService
        .shareTrainingWithUsers(this.id, this.registerForm.value)
        .subscribe((data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_5_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.router.navigate(["/admin/formations"]);
        });
    } else if (resultsI.length > 0 && resultsD.length == 0) {
      this.registerForm.value.distributer = ["000000000000000000000000"];
      this.trainingService
        .shareTrainingWithUsers(this.id, this.registerForm.value)
        .subscribe((data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_5_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.router.navigate(["/admin/formations"]);
        });
    } else
      this.trainingService
        .shareTrainingWithUsers(this.id, this.registerForm.value)
        .subscribe((data) => {
          this.iziToast.show({
            message: this.translate.instant("shared_4_5_succes"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.router.navigate(["/admin/formations"]);
        });
  }

  cancel() {
    this.router.navigate(["admin/formations"]);
  }

  selectedAllInstallers() {
    this.selectedAllInsatllers = false;
    this.deselectedAllInsatllers = true;
    for (let t of this.toutesInstallers) {
      if (t.selected == false) {
        t.selected = true;
      }
    }
  }

  deSelectedAllInstallers() {
    this.selectedAllInsatllers = true;
    this.deselectedAllInsatllers = false;
    for (let t of this.toutesInstallers) {
      if (t.selected == true) {
        t.selected = false;
      }
    }
  }

  selectedAllDistrubutes() {
    this.selectedAllDistrubuters = false;
    this.deselectedAllDistrubutes = true;
    for (let t of this.toutesDistributers) {
      if (t.selected == false) {
        t.selected = true;
      }
    }
  }

  deSelectedAllDistrubutes() {
    this.selectedAllDistrubuters = true;
    this.deselectedAllDistrubutes = false;
    for (let t of this.toutesDistributers) {
      if (t.selected == true) {
        t.selected = false;
      }
    }
  }
}
