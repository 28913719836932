import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-templ-admin',
  templateUrl: './templ-admin.component.html',
  styleUrls: ['./templ-admin.component.css']
})
export class TemplAdminComponent implements OnInit {
  currentUser
  idp :string[];
  ids: string;
  navbarclass:any = "collapse navbar-collapse";
  langclass: any = "dropdown-menu";
  selectedLang:any="fr";  
  
  constructor(private authentificationService :AuthenticationService, private translate: TranslateService,
    private token :TokenStorageService,private router: Router ) { 
    if(localStorage && localStorage.getItem('currentLang')){
      this.selectedLang = localStorage.getItem('currentLang');
      // console.log(" this.selectedLang", this.selectedLang)
    }
    this.authentificationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    )
  }

  ngOnInit(): void {
    this.currentUser=localStorage.getItem('user')
   
  }
  logout()
  {
    this.authentificationService.logout()
  }

  changenavbarclass(){
    if(this.navbarclass.indexOf("show") == -1 ){
      this.navbarclass = "collapse navbar-collapse show";
    }else{
      this.navbarclass = "collapse navbar-collapse";
    }
  }
  
  lang(l){
    this.selectedLang=l;
    localStorage.setItem('currentLang',l);
    window.location.reload();
  }
  openlist(){
    if(this.langclass.indexOf("show") == -1){
      this.langclass = "dropdown-menu show";
    }else{
      this.langclass = "dropdown-menu";
    }
  }

 
}
