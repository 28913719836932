import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userURL=`${environment.baseUrl}/users`
  constructor(private http: HttpClient) { }


 
     getUserrById(id : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/${id}`);
    
      } 
      
      EditUser(id:string,user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/${id}`,user);
      }

      EditPasswordUser(user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/updatePassowrd`,user);
      }

      EditInfo(user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/updateData`,user);
      }
}
