<!-- Header -->
<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight">
            {{ "formation_partager_title" | translate }}
          </h1>
          <h3 class="h3 mb-0 ls-tight" style="padding-top: 2%">
            {{ "questionaire_pass_test_card1_subtitle1" | translate }} :
            {{ titleTraining }}
          </h3>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link active"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-regular"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>
<!-- Main -->
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid row">
    <!-- Card stats -->

    <div class="card shadow col-6" style="width: 42%; margin-left: 4%">
      <div class="card-header">
        <h5 class="mb-0">{{ "instalatteur_card_title_1" | translate }}</h5>
        <br />
        <input
          id="search-input"
          type="search"
          #myInput
          class="form-control"
          placeholder="{{ 'btn_hover_6' | translate }}"
          [(ngModel)]="emailIns"
        />
      </div>

      <div class="table-responsive">
        <table class="table table-hover table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">
                {{ "formation_card_share_part1_table_1" | translate }}
              </th>
              <th scope="col">{{ "table_column_6" | translate }}</th>
              <th scope="col">
                {{ "formation_card_share_part1_table_2" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                toutesInstallers.length > 0 &&
                (toutesInstallers | customerEmailFilter : emailIns).length > 0
              "
            >
              <td></td>
              <td></td>

              <td *ngIf="selectedAllInsatllers == true">
                <a
                  (click)="selectedAllInstallers()"
                  style="
                    cursor: pointer !important;
                    text-decoration: underline #e73458;
                  "
                >
                  {{ "formation_card_share_part1_table_3" | translate }}
                </a>
              </td>
              <td *ngIf="deselectedAllInsatllers == true">
                <a
                  (click)="deSelectedAllInstallers()"
                  style="
                    cursor: pointer !important;
                    text-decoration: underline #e73458;
                  "
                >
                  {{ "formation_card_share_part1_table_3" | translate }}
                </a>
              </td>
            </tr>
            <tr
              *ngIf="
                toutesInstallers.length == 0 ||
                (toutesInstallers | customerEmailFilter : emailIns).length == 0
              "
            >
              <td colspan="3" style="text-align: center">
                {{ "instalateur_not_found" | translate }}
              </td>
            </tr>
          </tbody>
          <tbody
            *ngFor="
              let i of toutesInstallers | customerEmailFilter : emailIns;
              let elem = index
            "
          >
            <tr>
              <td>
                {{ i.email }}
              </td>
              <td *ngIf="!i.premium" [ngStyle]="{ color: 'red' }">
                <span class="badge badge-pill badge-dark">{{
                  "instalateur_card_add_content_4_2" | translate
                }}</span>
              </td>

              <td *ngIf="i.premium" [ngStyle]="{ color: 'yellow' }">
                <span class="badge badge-pill badge-info">{{
                  "instalateur_card_add_content_4_3" | translate
                }}</span>
              </td>
              <td>
                <input
                  id="cb1"
                  type="checkbox"
                  (change)="i.selected = !i.selected"
                  [checked]="i.selected"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer border-0 py-5"></div>
      </div>
    </div>

    <div class="card shadow col-6" style="width: 44%; margin-left: 7%">
      <div class="card-header">
        <h5 class="mb-0">{{ "distributeur_card_title_1" | translate }}</h5>
        <br />
        <input
          id="search-input"
          type="search"
          #myInput
          class="form-control"
          placeholder="{{ 'btn_hover_6' | translate }}"
          [(ngModel)]="emailDis"
        />
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">
                {{ "formation_card_share_part2_table_1" | translate }}
              </th>
              <th scope="col">
                {{ "formation_card_share_part1_table_2" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                toutesDistributers.length > 0 &&
                (toutesDistributers | customerEmailFilter : emailDis).length > 0
              "
            >
              <td></td>
              <td *ngIf="selectedAllDistrubuters == true">
                <a
                  (click)="selectedAllDistrubutes()"
                  style="
                    cursor: pointer !important;
                    text-decoration: underline #e73458;
                  "
                >
                  {{ "formation_card_share_part1_table_3" | translate }}
                </a>
              </td>
              <td *ngIf="deselectedAllDistrubutes == true">
                <a
                  (click)="deSelectedAllDistrubutes()"
                  style="
                    cursor: pointer !important;
                    text-decoration: underline #e73458;
                  "
                >
                  {{ "formation_card_share_part1_table_3" | translate }}
                </a>
              </td>
            </tr>
            <tr
              *ngIf="
                toutesDistributers.length == 0 ||
                (toutesDistributers | customerEmailFilter : emailDis).length ==
                  0
              "
            >
              <td colspan="2" style="text-align: center">
                {{ "distributeur_card_table" | translate }}
              </td>
            </tr>
          </tbody>
          <tbody
            *ngFor="
              let i of toutesDistributers | customerEmailFilter : emailDis
            "
          >
            <tr>
              <td>
                {{ i.email }}
              </td>
              <td>
                <input
                  id="cb1"
                  type="checkbox"
                  (change)="i.selected = !i.selected"
                  [checked]="i.selected"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="card-footer border-0 py-5"></div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="text-center card-header">
        <button class="btn btn-primary mr-1" (click)="shareTraining()">
          {{ "btn_3" | translate }}
        </button>
        <button
          routerLink="/admin/formations"
          class="btn btn-secondary"
          type="reset"
        >
          {{ "btn_2" | translate }}
        </button>
      </div>
    </div>
  </div>
</main>
