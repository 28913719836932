import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Test } from '../_models/test';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})
export class ExamenServiceService {
  private _testUrl=`${environment.baseUrl}/examens` 
  constructor(private http: HttpClient) { }
  public getTests() :Observable <any> {
    

    return this.http.get<any[]>(this._testUrl, { 'headers': headers }).pipe(response =>
      response)
      }

      createTest(test: any):Observable<Object> {
        return this.http.post(`${this._testUrl}`,test);
      }

      deleteTest(id : string)
      {
        return this.http.delete(`${this._testUrl}/${id}`);
    
      } 

      changeEtat(id : string):Observable<any>{
        return this.http.get<any>(`${this._testUrl}/etat/${id}`);
      } 
      getTestById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._testUrl}/${id}`);
    
      } 
      Edit(id:string,examen:Test):Observable<Object> {
        return this.http.put(`${this._testUrl}/${id}`,examen);
      }

      getTestByTraining(id):Observable<any>
      {
        return this.http.get(`${this._testUrl}/byTraining/${id}`);
      }
}
