import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  private _statisticsUrl=`${environment.baseUrl}/statistics` 
  constructor(private http: HttpClient) { }

 
 /*In espace Admin */
  getTrainings() :Observable <any>
  {
   return this.http.get(`${this._statisticsUrl}/trainings`);
  }

  statsForTraining(id) :Observable <any>
  {
   return this.http.get(`${this._statisticsUrl}/statsForTraining/${id}`);
  }

   /*In espace Distrubtor */

   getTrainingsDisributors() :Observable <any>
   {
    return this.http.get(`${this._statisticsUrl}/statsForTrainingInDistributor`);
   }
 
   statsForTrainingInDistributor(id) :Observable <any>
   {
    return this.http.get(`${this._statisticsUrl}/statsForTrainingInDistributor/${id}`);
   }
 
}
