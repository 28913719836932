
 <header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
      <div class="mb-npx">
          <div class="row align-items-center">
              <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                  <!-- Title -->
                  <h1 class="h2 mb-0 ls-tight">{{'profil_title' | translate}}</h1>
              </div>
              <!-- Actions -->
             
          </div>
      </div>
  </div>
</header>

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
      <div class="card shadow border-0 mb-7">
          <div class="card-header">
              <h5 class="mb-0">{{'profil_card_title' | translate}}</h5>
          </div>
        
           <br>
           <br>
           <br>

          <div class="container">
  <div class="main-body">
<div class="row gutters-sm">
  <div class="col-md-4 mb-3">
    <div class="card">
      <div class="card-body">
        <div   class="d-flex flex-column align-items-center text-center">
           <div>
             
          <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="Admin" class="rounded-circle" width="150">
          </div> 
          <div class="mt-3">
            <h4 style="text-transform: capitalize;" *ngIf="firstName && lastName" >{{firstName}} {{lastName}}</h4>
            <!-- <h4 style="text-transform: capitalize;" *ngIf="!firstName && !lastName" >utilisateur utilisateur </h4> -->

            <br>
            <p style="text-transform: capitalize;" class="text-secondary mb-1"> {{'instalatteur_title_one' | translate}}  </p>
            <p class="text-muted font-size-sm">{{email}}</p>
            <br>
            
           
           
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <div class="col-md-8">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">{{'profil_card_1' | translate}}</h6>
          </div>
          <!-- <div style="text-transform: capitalize;" *ngIf="!firstName" class="col-sm-9 text">
            utilisateur 
           </div> -->
          <div style="text-transform: capitalize;" *ngIf="firstName" class="col-sm-9 text-secondary">
           {{firstName}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">{{'profil_card_2' | translate}}</h6>
          </div>
          <!-- <div style="text-transform: capitalize;" *ngIf="!lastName" class="col-sm-9 text">
            utilisateur
           </div> -->
          <div style="text-transform: capitalize;" class="col-sm-9 text-secondary">
            {{lastName}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">{{'profil_card_3' | translate}}</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            {{email}}
          </div>
        </div>
        <hr>
       
        

        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">{{'profil_card_4' | translate}}</h6>
          </div>
          <div class="col-sm-9 text-secondary">
             **********
          </div>
        </div>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>




   </div>
   </div>
  </div>
  <div class="card-footer border-0 py-5">
   
</div>
</div>

         
      </div>
  </div>
</main>
