import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  currentUser
  d
  user
  role
  constructor(private authenticationService: AuthenticationService , private router :Router,private userService : UserService) { }

  canActivate() :boolean {
   if(!localStorage.getItem('token_auth') || !localStorage.getItem('token') ){
    document.location.href = 'https://fineo.clevermint-dev.be';
     return false
    }  

   if(localStorage.getItem('token_auth') && localStorage.getItem('token') )
   {
    
     this.authenticationService.apimeFunction(localStorage.getItem('token_auth')).subscribe((result) => {
          if(!result ||  (result && !result["data"]) ||  (result && result["data"] && result["data"].role_slug != "admin")||  (result && result["data"] && !result["data"].has_access_to_elearning)){
              document.location.href = 'https://fineo.clevermint-dev.be';
              return false
            }
      }, (err) => {
        localStorage.clear();
        document.location.href = 'https://fineo.clevermint-dev.be';
              return false
      }); 
  
  return true ;
}
}

}
